export const accessLevels = {
  2: {
    EN: "Admin",
    SE: "Admin",
  },
  5: {
    EN: "Write",
    SE: "Skriva",
  },
  6: {
    EN: "Upload",
    SE: "Ladda upp",
  },
  8: {
    EN: "Viewer",
    SE: "Läsa",
  },
};

export const accessLevelsHidden = {
  ...accessLevels,
  1: {
    EN: "Owner",
    SE: "Ägare",
  },
  3: {
    EN: "Supervisor",
    SE: "Handledare",
  },
  4: {
    EN: "Annotator",
    SE: "Annoterare",
  },
  7: {
    EN: "API",
    SE: "API",
  },
};
