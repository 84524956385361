import { setExtra } from "@sentry/react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "state/reducers";
import { authorizedPost } from "utils/request";

function languageSelector(state: RootState) {
  return state.user.language;
}

function useLanguage() {
  const language = useSelector(languageSelector);
  const dispatch = useDispatch();

  async function setLanguage(language: "EN" | "SE") {
    const body = {
      language,
    };
    try {
      await authorizedPost("/user/settings", body);
      setExtra("language", language);
      dispatch({
        type: "SET_LANGUAGE",
        payload: language,
      });
    } catch (e) {
      console.error(e);
    }
  }

  return { language, setLanguage };
}

export default useLanguage;
