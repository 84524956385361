import { connect } from "react-redux";
import { RootState } from "state/store";
import Component from "./SaveButton";
import { ObjectType } from "interfaces";

interface IStateProps {
  objectTypes: ObjectType[];
}

interface IDispatchProps {}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    objectTypes: state.objects.objectTypes,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
