import { connect } from "react-redux";
import ImageObjectDownload from "./ImageObjectDownload";
import { RootState } from "state/store";
import {
  setHumanOrNotLabel,
  getCustomerName,
  downloadObjectFile,
  downloadFlightObjects,
} from "state/actions";

interface IStateProps {
  flightsData: Array<any>;
  totalCount: number;
  humanOrNotLabel: string;
  customerName: string;
}

interface IDispatchProps {
  setHumanOrNotLabel: (value) => void;
  getCustomerName: () => void;
  downloadObjectFile: () => void;
  downloadFlightObjects: (flightsData, flight_id, filename: string) => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    flightsData: state.imageObjectDownload.flightsData,
    totalCount: state.imageObjectDownload.totalCount,
    humanOrNotLabel: state.imageObjectDownload.humanOrNotLabel,
    customerName: state.imageObjectDownload.customerName,
  };
};

const mapDispatchToProps = {
  setHumanOrNotLabel,
  getCustomerName,
  downloadObjectFile,
  downloadFlightObjects,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ImageObjectDownload);
