interface ILanguages {
  EN: string;
  SE: string;
}

export function Translated({ EN, SE }: ILanguages) {
  return {
    EN: EN,
    SE: SE,
  };
}
