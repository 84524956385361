import React, { useContext } from "react";
import { MenuItem, Typography, ListItemIcon } from "@mui/material";
import { Done, Clear } from "@mui/icons-material";
import { authorizedPost } from "utils/request";
import { toast } from "react-toastify";
import { updateGridStatistics, updatePowerlineColor } from "state/actions";
import store from "state/store";
import { featureIsInSelection, featureIsPowerline } from "utils/functions";
import { context as WorkSessionContext } from "providers/WorkSessionProvider";

interface IProps {
  gmap: google.maps.Map;
  selection: google.maps.Rectangle;
}

export default function InspectionItem({ gmap, selection }: IProps) {
  const workSessionContext = useContext(WorkSessionContext);
  function onClick(value) {
    const selectionBounds = selection.getBounds().toJSON();

    // Construct database post request
    const body = {
      inspected: value,
      ...selectionBounds,
    };

    //Send data to databse
    authorizedPost(`/powerline/inspection`, body)
      .then(() => {
        workSessionContext.update();
        let inspectionFeatures = [];
        if (!!!gmap) return;
        gmap.data.forEach((feature) => {
          if (
            featureIsPowerline(feature) &&
            featureIsInSelection(feature, selection)
          ) {
            inspectionFeatures.push(feature);
          }
        });
        // Use store.dispatch to avoid circular dependencies
        store.dispatch(
          // @ts-ignore
          updateGridStatistics()
        );
        store.dispatch(
          // @ts-ignore
          updatePowerlineColor(inspectionFeatures, "inspected", value, gmap)
        );
      })
      .catch(() => {
        toast.error("Failed to update powerlines");
      });
  }
  return (
    <MenuItem>
      <ListItemIcon
        onClick={() => {
          onClick(true);
        }}
        style={{ minWidth: "auto", marginRight: "10px" }}
      >
        <Done />
      </ListItemIcon>
      <ListItemIcon
        onClick={() => {
          onClick(false);
        }}
        style={{ minWidth: "auto", marginRight: "10px" }}
      >
        <Clear />
      </ListItemIcon>
      <Typography>Supervisor analysis</Typography>
    </MenuItem>
  );
}
