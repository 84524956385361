import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { useContext, useState } from "react";
import {
  ClearanceAxisFilter,
  ClearanceRuleFilter,
  VegetationDetectionFilter,
  VegetationDefectFilter,
} from "components/Filter/FilterComponents";
import { context as FilterContext } from "providers/FilterProvider";
import LidarMarkerFilter from "components/Filter/FilterComponents/LidarMarkerFilter";
import { DefectSelector } from "./ElectricFilters";
import { RoleWrapper } from "components";
import translations from "translations";
import { useLanguage } from "hooks";

function Explanation() {
  const [open, setOpen] = useState(false);
  const { language } = useLanguage();
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        sx={{ mx: 2, mt: 2 }}
      >
        {translations.Filter.WhatIs3D[language]}
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {translations.Filter.WhatIs3D.Title[language]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {translations.Filter.WhatIs3D.Section1[language]}
          </DialogContentText>
          <DialogContentText sx={{ mb: 2 }}>
            {translations.Filter.WhatIs3D.Section2[language]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            {translations.Filter.Close[language]}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function VegetationFilters() {
  const { defects } = useContext(FilterContext);

  return (
    <Stack pb={2}>
      <DefectSelector />
      {defects ? <VegetationDefectFilter /> : <VegetationDetectionFilter />}

      <Divider sx={{ my: 2 }}>3D - LiDAR</Divider>
      <RoleWrapper keyName="advancedFilter" fallback={<LidarMarkerFilter />}>
        <ClearanceAxisFilter />
        <ClearanceRuleFilter />
        <LidarMarkerFilter />
      </RoleWrapper>
      <Explanation />
    </Stack>
  );
}
