import isEqual from "lodash/isEqual";
import store from "state/store";
import { asMutable } from "seamless-immutable";
import translations from "translations";

let propertiesToCheck = [
  "h",
  "categories",
  "types",
  "severities",
  "w",
  "x",
  "y",
  "rotation",
  "is_defect",
  "workflow_status",
  "skyqraft_hidden",
  "isLocked",
  "properties",
  "objectHasNoDefect",
  "type_comment",
  "item_id",
  "type_id",
  "processed",
];

export const checkRegionUpdate = (region, regions, objectTypes) => {
  let oldRegion = regions.find((r) => r.id === region.id);
  if (!!!oldRegion) {
    // The region does not exist
    return {
      new: true,
      saved: false,
      canSave: canSaveObject(region, objectTypes)?.canSave,
    };
  }

  let identical = propertiesToCheck.every((property) =>
    isEqual(region[property], oldRegion[property])
  );
  if (identical) {
    return {
      new: false,
      saved: true,
      canSave: canSaveObject(region, objectTypes)?.canSave,
    };
  } else {
    return {
      new: false,
      saved: false,
      canSave: canSaveObject(region, objectTypes)?.canSave,
    };
  }
};

export const regionsAreEqual = (regionsA, regionsB) => {
  let extractedRegionsA, extractedRegionsB;

  if (!!regionsA.asMutable) {
    extractedRegionsA = asMutable(regionsA);
  } else {
    extractedRegionsA = regionsA;
  }
  if (!!regionsB.asMutable) {
    extractedRegionsB = asMutable(regionsB);
  } else {
    extractedRegionsB = regionsB;
  }

  extractedRegionsA = extractedRegionsA.map((r) => {
    let extractedRegion = {};
    propertiesToCheck.forEach(
      (property) => (extractedRegion[property] = r[property])
    );
    return extractedRegion;
  });
  extractedRegionsB = extractedRegionsB.map((r) => {
    let extractedRegion = {};
    propertiesToCheck.forEach(
      (property) => (extractedRegion[property] = r[property])
    );
    return extractedRegion;
  });
  const req = isEqual(
    extractedRegionsA.sort((a, b) => (a.id < b.id ? 1 : -1)),
    extractedRegionsB.sort((a, b) => (a.id < b.id ? 1 : -1))
  );

  return req;
};

export const canSaveObject = (region, objectTypes) => {
  let language = store.getState().user.language;
  let canSave = true;
  let message = "";
  const detectionTypes = objectTypes.filter((o) => !o.issue).map((o) => o.id);

  // We cannot allow objects without definition
  if (region.types.length === 0 || region.severities.length === 0) {
    return {
      canSave: false,
      message: translations.AnnotationTool.NoDefinitionsSpecified[language],
    };
  }

  // Definition array length must be equal
  if (region.types.length !== region.severities.length) {
    return {
      canSave: false,
      message: translations.AnnotationTool.DefinitionError[language],
    };
  }

  // Type cannot be null
  if (region.types.includes(null)) {
    return {
      canSave: false,
      message: translations.AnnotationTool.TypeMissing[language],
    };
  }

  // Verify each post
  for (let i = 0; i < region.types.length; i++) {
    if (detectionTypes.includes(region.types[i])) {
      canSave = canSave && Boolean(region.types[i]);
      message = !canSave && translations.AnnotationTool.TypeError[language];
    } else if (
      (region.workflow_status[i] === 2 || region.workflow_status[i] === 3) &&
      region.severities[i] > 4
    ) {
      canSave = false;
      message =
        !canSave && translations.AnnotationTool.SetASevrityLevel[language];
    } else {
      canSave = canSave && Boolean(region.severities[i]);
      message =
        !canSave && translations.AnnotationTool.SeverityMissing[language];
    }
  }

  return { canSave: canSave, message: message };
};
