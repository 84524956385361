import {
  bboxPolygon,
  booleanOverlap,
  booleanWithin,
  buffer,
  lineString,
} from "@turf/turf";
import { useRef, useEffect, useCallback, useLayoutEffect } from "react";

export const round = (number: number, decimals: number = 0) =>
  Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);

const useEventCallbackBase = (useEffectHook, fn, deps = []) => {
  const ref = useRef(fn);

  useEffectHook(() => {
    ref.current = fn;
  }, [fn, ...deps]);

  return useCallback(
    (...args) => {
      const callback = ref.current;
      callback(...args);
    },
    [ref]
  );
};

export const useEventCallback = useEventCallbackBase.bind(
  null,
  useLayoutEffect
);

export const useEventCallbackWithUseEffect = useEventCallbackBase.bind(
  null,
  useEffect
);

export function featureIsPowerline(feature: google.maps.Data.Feature) {
  return !!feature.getProperty("powerline");
}

export function featureIsInSelection(
  feature: google.maps.Data.Feature,
  selection: google.maps.Rectangle
) {
  let currentLine = [];
  const selectionBounds = selection.getBounds();
  const selectionJson = selectionBounds.toJSON();

  feature.getGeometry().forEachLatLng((point) => {
    currentLine.push([point.lng(), point.lat()]);
  });
  var line = buffer(lineString(currentLine), 1, { units: "meters" });
  const selected_polygon = bboxPolygon([
    selectionJson.west,
    selectionJson.south,
    selectionJson.east,
    selectionJson.north,
  ]);
  return (
    booleanOverlap(line, selected_polygon) ||
    booleanWithin(line, selected_polygon)
  );
}
