import React, { ReactElement, useEffect } from "react";
import { Button, Divider } from "@mui/material";
import { toast } from "react-toastify";
import translations from "translations";
import { useLanguage } from "hooks";

interface IProps {
  flightsData: any;
  totalCount: number;
  humanOrNotLabel: string;
  customerName: string;
  setHumanOrNotLabel: (value) => void;
  getCustomerName: () => void;
  downloadObjectFile: () => void;
  downloadFlightObjects: (flightsData, flight_id, filename: string) => void;
}

export default function ObjectDownload({
  flightsData,
  totalCount,
  humanOrNotLabel,
  customerName,
  setHumanOrNotLabel,
  getCustomerName,
  downloadObjectFile,
  downloadFlightObjects,
}: IProps): ReactElement {
  const { language } = useLanguage();
  useEffect(() => {
    getCustomerName();
  }, [getCustomerName]);

  const downloadHumanObjectFile = (e) => {
    toast.info(
      translations.Menu.Tools.ImageObjects.Download.PreparingHuman[language]
    );
    setHumanOrNotLabel("human");
    e.preventDefault();
    downloadObjectFile();
  };

  const downloadMLObjectFile = (e) => {
    setHumanOrNotLabel("ML");
    toast.info(
      translations.Menu.Tools.ImageObjects.Download.PreparingML[language]
    );
    e.preventDefault();
    downloadObjectFile();
  };

  return (
    <div data-testid="ImageObjectDownload">
      <h2 data-testid="ImageObjectDownloadTitle">
        {translations.Menu.Tools.ImageObjects.Download.Title[language]}
      </h2>
      <p data-testid="ImageObjectDownloadDescription">
        {translations.Menu.Tools.ImageObjects.Download.Description[language]}
      </p>
      <br />
      <hr />
      <Button
        data-testid="ImageObjectDownloadHuman"
        onClick={downloadHumanObjectFile}
        variant="contained"
        className="primaryButton"
        style={{ marginLeft: 5, textTransform: "none" }}
      >
        {translations.Menu.Tools.ImageObjects.Download.Human[language]}
      </Button>
      <Button
        data-testid="ImageObjectDownloadML"
        onClick={downloadMLObjectFile}
        variant="contained"
        className="secondaryButton"
        style={{ marginLeft: 5, textTransform: "none" }}
      >
        {translations.Menu.Tools.ImageObjects.Download.ML[language]}
      </Button>
      {Object.keys(flightsData).length !== 0 && (
        <>
          <Divider style={{ margin: 10 }} />

          {Object.keys(flightsData).map((flight_id) => {
            return (
              <Button
                data-testid={`${flight_id}-objectDownload`}
                key={flight_id}
                onClick={() =>
                  downloadFlightObjects(
                    flightsData,
                    [flight_id],
                    `${flight_id}_${customerName}_${humanOrNotLabel}.json`
                  )
                }
              >
                {translations.Menu.Tools.ImageObjects.Download.Flight[language]}{" "}
                {flight_id} ({flightsData[flight_id].length})
              </Button>
            );
          })}
          <Button
            data-testid="allFlightObjectDownload"
            key="all_flights"
            onClick={() =>
              downloadFlightObjects(
                flightsData,
                Object.keys(flightsData),
                `All_Flights_${customerName}_${humanOrNotLabel}.json`
              )
            }
          >
            {translations.Menu.Tools.ImageObjects.Download.AllFlights[language]}{" "}
            ({totalCount})
          </Button>
        </>
      )}
    </div>
  );
}
