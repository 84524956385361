import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { getLidarURL } from "authentication/apiDetails";
import store, { RootState } from "state/store";
import { setLidarWindow } from "state/actions";
import translations from "translations";
import { authorizedPost, authorizedPut } from "utils/request";
import { toast } from "react-toastify";
import { useLanguage } from "hooks";

type Props = {
  severity: number;
  scene: string;
  project: number;
  lat: number;
  lng: number;
  markerID: number;
  updateMarkers: Function;
  severities: any[];
  clearanceAxes: number[];
  clickedLidarMarker: number | null;
  setClickedLidarMarker: (lidarMarkerId: number) => void;
};

const colorMap = {
  1: "#FF0000",
  2: "#FF0000",
  3: "#FF5634",
  4: "#FF5634",
};

export default function LidarMarker({
  severity,
  lat,
  lng,
  scene,
  project,
  markerID,
  updateMarkers,
  severities,
  clearanceAxes,
  clickedLidarMarker,
  setClickedLidarMarker,
}: Props) {
  const { language } = useLanguage();
  let lidarURL = getLidarURL();
  let link = `${lidarURL}/${project}`;
  link += `?scene=${scene}`;
  link += `&lat=${lat}`;
  link += `&lng=${lng}`;
  link += `&clearanceAxes=${clearanceAxes.join(",")}`;
  link += `&project=${project}`;
  link += "&filter=0";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const onMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function onContextMenu(e) {
    onMenuOpen(e);
  }

  function verifyMarker(severityLevel) {
    authorizedPut(`/marker/lidar/${markerID}`, {
      severity: severityLevel,
    })
      .then((res) => {
        updateMarkers();
        handleClose();
      })
      .catch((err) => {
        toast.error("Failed to verify marker");
      });
  }

  function resetMarker() {
    authorizedPost(`/marker/lidar/${markerID}/reset`)
      .then(() => {
        updateMarkers();
        handleClose();
      })
      .catch(() => {
        handleClose();
      });
  }

  let severityOptions =
    severities
      .filter((s) => s?.id < 5)
      .map((s) => ({
        value: s.id,
        label: s[`${language.toLowerCase()}_name`],
      })) || [];

  function onClick(e) {
    e.stopPropagation();
    e.preventDefault();
    const state: RootState = store.getState();
    const lidarWindow = state.imageViewer.lidarWindow;
    const demoMode = state.ui.demoMode;
    const LIDAR_URI = getLidarURL();
    setClickedLidarMarker(markerID);
    if (lidarWindow === null || lidarWindow?.closed) {
      // No lidar window exists
      const URL = `${link}${demoMode ? "&demo=true" : ""}`;
      let lidarWindow = window.open(URL, "sq-lidar");
      store.dispatch(setLidarWindow(lidarWindow));
    } else {
      lidarWindow.postMessage(
        {
          type: "OPEN-BOX",
          mission: project,
          project: scene,
          lat: lat,
          lng: lng,
          demoMode,
          clearanceAxes,
          filter: 0,
        },
        LIDAR_URI
      );
      lidarWindow.focus();
    }
  }

  return (
    <>
      <div
        className={`lidarMarker${
          clickedLidarMarker === markerID ? " active" : ""
        }`}
        style={{ borderColor: colorMap[severity] }}
        onClick={onClick}
        onContextMenu={onContextMenu}
      >
        <div
          className="lidarDot"
          style={{ backgroundColor: colorMap[severity] }}
        />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {severityOptions.map((option, i) => (
          <MenuItem key={i} onClick={() => verifyMarker(option.value)}>
            {option.label}
          </MenuItem>
        ))}
        <MenuItem onClick={resetMarker}>
          {translations.Markers.LidarMarkers.Reset[language]}
        </MenuItem>
      </Menu>
    </>
  );
}
