import { Accordion as MUIAccordion, AccordionDetails } from "@mui/material";
import Summary from "./Summary";

type Props = {
  open?: boolean;
  title: string;
  description: string;
  value?: number;
  children: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
};

export default function Accordion({
  open,
  title,
  description,
  value,
  children,
  onClose,
  onOpen,
}: Props) {
  return (
    <MUIAccordion
      defaultExpanded={!!open}
      onChange={(e, expanded) => {
        if (expanded && onOpen) {
          onOpen();
        } else if (!expanded && onClose) {
          onClose();
        }
      }}
    >
      <Summary title={title} description={description} value={value} />
      <AccordionDetails>{children}</AccordionDetails>
    </MUIAccordion>
  );
}
