import { getAPIEndpoint } from "authentication/apiDetails";

// Zoom level to show all image markers
export const OBJECT_ZOOM = 15;
export const IMAGE_ZOOM = 16;
export const ZOOM_SPEED = 0.2;
export const POLE_ZOOM = 15;

// Line widths
export const WIDTH_NOT_SELECTED = 3;
export const WIDTH_SELECTED = 3;
export const WIDTH_HOVER = 8;

// Colors on the map
export const MAIN_BLUE = "#1377FA";
export const THIN_BLUE = "#6377FA";
export const SELECTED_GREEN = "#00FF33";
export const NOT_FLOWN_COLOR = "#BEBEBE";
export const FAILED_INSPECTION = "#FF5555";
export const ANNOTATOR_INSPECTION = "#FFACAC";
export const SUPERVISOR_INSPECTION = "#A600FF";
export const SUPERUSER_INSPECTION = "#55FF55";
export const CRITICAL_INSPECTION = "#FFEF00";
export const NEEDS_REFLIGHT = "#D1E3FF";
export const HOVER_COLOR = "#FFFFFF";
export const BURIED_COLOR = "#5E2C04";

// Extra loading percentage of screen when moving around in the mao
export const EXTRA_LOADING_PERCENTAGE = 0.4;
export const MAX_VISIBLE_OBJECT_DETECTION_MARKERS = 500;

export const GOOGLE_API_KEY =
  process.env.REACT_APP_GOOGLE_API_KEY ??
  "AIzaSyCHbQMRmiLf9OrVxUJbxF2rp1wQvUC9DXA";

const BASE_URI = getAPIEndpoint();
export { BASE_URI };
