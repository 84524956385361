import { useRef, useEffect, useState, ReactElement } from "react";
import SettingsList from "components/Settings";
import ToolsList from "views/Sidebar/Tools";
import MissionList from "components/MissionList";
import { Filters } from "views/Sidebar/Filters";
import RoleWrapper from "components/RoleHOC/wrapper";
import { SidebarButton } from "components/SidebarButton";
import {
  Flight,
  Settings,
  Dashboard,
  ShowChart,
  Person,
  Logout as LogoutIcon,
  Inbox,
  QuestionMark,
  ShieldTwoTone,
  MoreHoriz,
  Search,
} from "@mui/icons-material";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Box, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useLanguage, useSelector, useSidebar } from "hooks";
import { Sidebar as SidebarEnum } from "providers/MenuProvider";
import { resetUser } from "state/actions/user";
import { FilterCountProvider } from "providers/FilterCountProvider";
import { axiosInstance } from "utils/request";
import { toast } from "react-toastify";
import { captureException, EventHint } from "@sentry/browser";
import ActiveDot from "icons/ActiveDot";
import { useFilterPlay } from "hooks";

function useOutsideAlerter(ref, onClickAway) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickAway();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, onClickAway]);
}

function ClickAwayListener({ onClickAway, children }) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClickAway);

  return <div ref={wrapperRef}>{children}</div>;
}

export default function Sidebar(): ReactElement {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const isSmall = useMediaQuery("sm");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    sidebar,
    openFilters,
    openProjects,
    openSettings,
    openTools,
    closeSidebar,
  } = useSidebar();
  const { language } = useLanguage();
  const [bottomMenuToggle, setBottomMenuToggle] = useState(false);
  const params = useParams();
  const isSmallScreen = useMediaQuery("(max-width:576px)");

  const { filterActive } = useFilterPlay();

  async function handleLogout() {
    const confirmed = window.confirm(
      language === "EN"
        ? "Are you sure you want to log out"
        : "Är du säker att du vill logga ut?"
    );
    if (confirmed) {
      try {
        await axiosInstance.get("/auth/logout");
        dispatch(resetUser(() => navigate("/")));
      } catch (error) {
        const exceptionHint: EventHint = {
          event_id: "sidebar.logout",
          originalException: error,
        };
        captureException(error, exceptionHint);
        toast.error("Failed to logout. Error reported to Arkion");
      }
    }
  }

  return (
    <ClickAwayListener
      onClickAway={(a, b) => {
        setBottomMenuToggle(false);
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        height="100%"
        id="sidebar"
        sx={{ background: "white", transition: "all 300ms" }}
        direction={{
          xs: "row",
          md: "column",
        }}
      >
        <Stack
          id="topMenu"
          direction={{
            xs: "row",
            md: "column",
          }}
          sx={{
            transition: "all 300ms",
          }}
        >
          <Link
            to="/"
            className="imageWrapper"
            onClick={() => closeSidebar()}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidebarButton
              icon={
                <img
                  src={"/logo_50px_blue.png"}
                  alt="logo"
                  width={32}
                  height={32}
                />
              }
            />
          </Link>

          <SidebarButton
            highlighted={sidebar === SidebarEnum.Projects}
            onClick={
              sidebar === SidebarEnum.Projects ? closeSidebar : openProjects
            }
            disabled={!loggedIn}
            icon={<Flight />}
          />
          <SidebarButton
            highlighted={sidebar === SidebarEnum.Filters}
            onClick={
              sidebar === SidebarEnum.Filters ? closeSidebar : openFilters
            }
            disabled={!loggedIn || !params.mission}
            icon={
              <Stack position="relative">
                <Search />
                <ActiveDot active={filterActive} />
              </Stack>
            }
          />
          <Box
            display={{
              xs: "none",
              sm: "block",
            }}
          >
            <SidebarButton
              highlighted={sidebar === SidebarEnum.Tools}
              onClick={sidebar === SidebarEnum.Tools ? closeSidebar : openTools}
              disabled={!loggedIn}
              icon={<Dashboard />}
            />
          </Box>
          <Link
            onClick={closeSidebar}
            to={
              !loggedIn || !params.mission
                ? "#"
                : `/${params.mission}/statistics`
            }
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidebarButton
              highlighted={sidebar === SidebarEnum.Statistics}
              disabled={!loggedIn || !params.mission}
              icon={<ShowChart />}
            />
          </Link>
          <SidebarButton
            icon={<Settings />}
            onClick={
              sidebar === SidebarEnum.Settings ? closeSidebar : openSettings
            }
            disabled={!loggedIn}
            highlighted={sidebar === SidebarEnum.Settings}
          />
          {isSmallScreen && (
            <SidebarButton
              icon={<MoreHoriz />}
              onClick={() => setBottomMenuToggle(!bottomMenuToggle)}
              disabled={!loggedIn}
              highlighted={bottomMenuToggle}
            />
          )}
        </Stack>
        <Stack
          id="bottomMenu"
          direction={{
            xs: "row",
            md: "column",
          }}
          sx={{
            transition: "all 300ms",
          }}
        >
          <Box
            display={{
              xs: "none",
              sm: "block",
            }}
          >
            <Link
              onClick={() => closeSidebar()}
              to="/faq"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <SidebarButton icon={<QuestionMark />} />
            </Link>
          </Box>
          <Link
            to="/inbox"
            onClick={() => closeSidebar()}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidebarButton
              icon={<Inbox />}
              active={
                window.location.pathname.startsWith("/inbox") ||
                window.location.pathname.startsWith(`/${params.mission}/inbox`)
              }
              disabled={!loggedIn}
            />
          </Link>
          <Box
            display={{
              xs: "none",
              sm: "block",
            }}
          >
            <RoleWrapper keyName="userAdmin">
              <SidebarButton
                icon={
                  <Tooltip
                    placement="right"
                    title={
                      <Stack>
                        <RoleWrapper keyName="userAdmin">
                          <Link
                            onClick={() => closeSidebar()}
                            to="/users"
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <Stack direction="row" spacing={1} padding={1}>
                              <Person />
                              <Typography>User Administration</Typography>
                            </Stack>
                          </Link>
                        </RoleWrapper>
                        <RoleWrapper keyName="projectAdmin">
                          <Link
                            to="/group"
                            onClick={() => closeSidebar()}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <Stack direction="row" spacing={1} padding={1}>
                              <Flight />

                              <Typography>Project Administration</Typography>
                            </Stack>
                          </Link>
                        </RoleWrapper>
                      </Stack>
                    }
                  >
                    <ShieldTwoTone />
                  </Tooltip>
                }
              />
            </RoleWrapper>
          </Box>
          <Tooltip title="Log out" placement="right" arrow disableInteractive>
            <SidebarButton
              icon={<LogoutIcon />}
              onClick={handleLogout}
              disabled={!loggedIn}
            />
          </Tooltip>
        </Stack>
      </Stack>
      {sidebar && (
        <Stack
          direction={{
            xs: "column-reverse",
            md: "row",
          }}
          left={{
            xs: 0,
            md: 50,
          }}
          bottom={{
            xs: 50,
            md: undefined,
          }}
          top={{
            xs: undefined,
            md: 50,
          }}
          width={{
            xs: "100%",
            md: "calc( 100% - 50px )",
          }}
          height={{
            xs: "calc( 100% - 50px )",
            md: "calc( 100% - 50px )",
          }}
          sx={{
            position: "absolute",
            zIndex: 1000,
            transition: "all 300ms",
          }}
        >
          <Box
            height={{
              xs: "50%",
              md: "100%",
            }}
            width={{
              xs: "100%",
              md: sidebar === SidebarEnum.Filters ? 500 : 400,
            }}
            sx={{
              background: "rgba(255,255,255,0.95)",
              transition: "all 300ms",
            }}
          >
            {sidebar === SidebarEnum.Projects && <MissionList />}
            {sidebar === SidebarEnum.Filters && (
              <FilterCountProvider>
                <Filters />
              </FilterCountProvider>
            )}
            {sidebar === SidebarEnum.Tools && <ToolsList />}
            {sidebar === SidebarEnum.Settings && <SettingsList />}
          </Box>

          <Box
            sx={{ flex: 1 }}
            onClick={() => {
              if (sidebar) {
                closeSidebar();
              }
            }}
          />
        </Stack>
      )}
    </ClickAwayListener>
  );
}
