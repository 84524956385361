import { Translated } from "./utils";

export const Clear = Translated({ EN: "Clear", SE: "Rensa" });
export const Close = Translated({ EN: "Close", SE: "Stäng" });
export const Save = Translated({ EN: "Save", SE: "Spara" });
export const SetDate = Translated({ EN: "Set Date", SE: "Sätt Datum" });
export const ClickOnATabAbove = Translated({
  EN: "Click on a tab above",
  SE: "Klicka på en flik ovan",
});
export const ShowMore = Translated({ EN: "Show More", SE: "Visa Mer" });
export const ShowLess = Translated({ EN: "Show Less", SE: "Visa Mindre" });
