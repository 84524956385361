import RoleWrapper from "components/RoleHOC/wrapper";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { ThumbsUpDown, Clear } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { useLanguage } from "hooks";

export default function MachineReview() {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useLanguage();
  const isMLReview = location.pathname.includes("/machine-review");
  return (
    <RoleWrapper keyName="machineReview">
      <MenuItem
        id="image-review-ml"
        tooltip={translations.ImageViewer.MLReview[language]}
        onClick={() => {
          if (isMLReview) {
            navigate(`.${location.search}`);
          } else {
            navigate(`machine-review${location.search}`);
          }
        }}
      >
        {isMLReview ? <Clear htmlColor="red" /> : <ThumbsUpDown />}
      </MenuItem>
    </RoleWrapper>
  );
}
