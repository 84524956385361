import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FilterKey,
  FilterType,
} from "hooks/filter/search_parameters/constants";
import { BayFeedURLKey } from "components/Filter/FeedBay";

export const filterKeys = Object.keys(FilterKey).map((k) => FilterKey[k]);

export function getFilterActive(searchParams) {
  return searchParams.get(FilterKey.FILTER_ACTIVE) === "true";
}

export function useFilterPlay() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterPopulated, setFilterPopulated] = useState(false);

  const filterActive = getFilterActive(searchParams);

  function setFilterActive(value: FilterType["FILTER_ACTIVE"]) {
    if (value) {
      searchParams.set(FilterKey.FILTER_ACTIVE, "true");
    } else {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  }

  useEffect(() => {
    let populated = false;
    for (const key of filterKeys) {
      if (searchParams.has(key)) populated = true;
    }
    if (searchParams.has(BayFeedURLKey)) populated = true;
    setFilterPopulated(populated);
    if (!populated && filterActive) {
      setFilterActive(false);
    }
  }, [searchParams, filterActive]);

  function clearFilter() {
    for (const key of filterKeys) {
      if (searchParams.has(key)) searchParams.delete(key);
    }
    if (searchParams.has(BayFeedURLKey)) {
      searchParams.delete(BayFeedURLKey);
    }
    setSearchParams(searchParams, { replace: true });
  }

  function pauseFilter() {
    setFilterActive(false);
  }

  function playFilter() {
    setFilterActive(true);
  }

  return {
    filterPopulated,
    filterActive,
    clearFilter,
    pauseFilter,
    playFilter,
  };
}
