import React from "react";
import { MenuItem, Typography, ListItemIcon } from "@mui/material";
import { Done, Clear } from "@mui/icons-material";
import { authorizedPost } from "utils/request";
import { featureIsInSelection, featureIsPowerline } from "utils/functions";
import { updatePowerlineColor } from "state/actions";
import store from "state/store";
import { toast } from "react-toastify";

interface IProps {
  selection: google.maps.Rectangle;
  gmap: google.maps.Map;
}

export default function BuriedItem({ selection, gmap }: IProps) {
  function onClick(value) {
    const selectionBounds = selection.getBounds().toJSON();

    // Construct database post request
    const body = {
      buried: value,
      ...selectionBounds,
    };

    //Send data to databse
    authorizedPost(`/powerline/buried`, body)
      .then(() => {
        let inspectionFeatures = [];
        if (!!!gmap) return;
        gmap.data.forEach((feature) => {
          if (
            featureIsPowerline(feature) &&
            featureIsInSelection(feature, selection)
          ) {
            inspectionFeatures.push(feature);
          }
        });
        // Use store.dispatch to avoid circular dependencies
        store.dispatch(
          //@ts-ignore
          updatePowerlineColor(inspectionFeatures, "buried", value, gmap)
        );
      })
      .catch(() => {
        toast.error("Failed to update powerlines");
      });
  }
  return (
    <MenuItem>
      <ListItemIcon
        onClick={() => {
          onClick(true);
        }}
        style={{ minWidth: "auto", marginRight: "10px" }}
      >
        <Done />
      </ListItemIcon>
      <ListItemIcon
        onClick={() => {
          onClick(false);
        }}
        style={{ minWidth: "auto", marginRight: "10px" }}
      >
        <Clear />
      </ListItemIcon>
      <Typography>Powerline is buried</Typography>
    </MenuItem>
  );
}
