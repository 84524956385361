import { useSearchParams } from "react-router-dom";
import { useSelector } from "hooks";
import { FilterKey } from "hooks/filter/search_parameters/constants";

const IMAGE_FILTER_KEYS = [
  FilterKey.SEVERITY,
  FilterKey.WORKFLOW,
  FilterKey.LIST,
  FilterKey.FLAGGED,
  FilterKey.FLIGHT,
  FilterKey.DSO_TSO,
  FilterKey.NEW,
  FilterKey.AREA,
  FilterKey.DETECTION,
  FilterKey.SKYQRAFT_HIDDEN,
  FilterKey.DATE_IMAGE_CAPTURE,
  FilterKey.DATE_IMAGE_UPLOADED,
  FilterKey.DATE_DEFECT_ADDED,
  FilterKey.DATE_DEFECT_PROCESSED,
  FilterKey.DATE_DEFECT_FIXED,
  FilterKey.DATE_DEFECT_REPORTED,
  FilterKey.ACTORS,
];

const LIDAR_FILTER_KEYS = [
  FilterKey.SEVERITY,
  FilterKey.LIDAR_MARKERS,
  FilterKey.CLEARANCE_AXES,
  FilterKey.CLEARANCE_RULES,
  FilterKey.AREA,
];

const SEVERITY_TAB_KEYS = [FilterKey.SEVERITY, FilterKey.WORKFLOW];

const ELECTRIC_TAB_KEYS = [];

const VEGETATION_TAB_KEYS = [
  FilterKey.CLEARANCE_AXES,
  FilterKey.CLEARANCE_RULES,
  FilterKey.LIDAR_MARKERS,
];

const SPATIAL_TAB_KEYS = [
  FilterKey.FLAGGED,
  FilterKey.FLIGHT,
  FilterKey.DSO_TSO,
  FilterKey.NEW,
  FilterKey.AREA,
  FilterKey.SKYQRAFT_HIDDEN,
  FilterKey.DATE_IMAGE_CAPTURE,
  FilterKey.DATE_IMAGE_UPLOADED,
  FilterKey.DATE_DEFECT_ADDED,
  FilterKey.DATE_DEFECT_PROCESSED,
  FilterKey.DATE_DEFECT_FIXED,
  FilterKey.DATE_DEFECT_REPORTED,
  FilterKey.ACTORS,
];

const SAVED_TAB_KEY = [
  FilterKey.LIST,
  FilterKey.CLEARANCE_AXES,
  FilterKey.CLEARANCE_RULES,
];

export function isImageFilterPopulated(searchParams: URLSearchParams) {
  return IMAGE_FILTER_KEYS.some((key) => searchParams.has(key));
}

export function isLidarFilterPopulated(searchParams: URLSearchParams) {
  return LIDAR_FILTER_KEYS.some((key) => searchParams.has(key));
}

export function useFilterPopulated() {
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const defectCategory = useSelector((state) => state.objects.issueCategories);
  const detectionCategory = useSelector(
    (state) => state.objects.detectedCategories
  );

  const [searchParams] = useSearchParams();

  const defectVegetationCategoryIDs = defectCategory
    .filter((c) => c.en_name.toLowerCase() === "vegetation")
    .map((c) => c.id);
  const detectionVegetationCategoryIDs = detectionCategory
    .filter((c) => c.en_name.toLowerCase() === "vegetation")
    .map((c) => c.id);

  const electricalDefectIDs = objectTypes
    .filter((o) => !defectVegetationCategoryIDs.includes(o.category))
    .map((o) => o.id);
  const vegetationDefectIDs = objectTypes
    .filter((o) => defectVegetationCategoryIDs.includes(o.category))
    .map((o) => o.id);
  const vegetationDetectionIDs = objectTypes
    .filter((o) => detectionVegetationCategoryIDs.includes(o.category))
    .map((o) => o.id);

  const severityTabActive = SEVERITY_TAB_KEYS.some((key) =>
    searchParams.has(key)
  );
  let electricalTabActive = false;
  let vegetationTabActive = VEGETATION_TAB_KEYS.some((key) =>
    searchParams.has(key)
  );
  if (searchParams.has(FilterKey.DETECTION)) {
    const objectTypeIDs = searchParams
      .get(FilterKey.DETECTION)
      .split(",")
      .map((id) => parseInt(id));
    electricalTabActive = objectTypeIDs.some((id) =>
      electricalDefectIDs.includes(id)
    );
    vegetationTabActive =
      objectTypeIDs.some((id) => vegetationDefectIDs.includes(id)) ||
      objectTypeIDs.some((id) => vegetationDetectionIDs.includes(id));
  }

  const spatialTabActive = SPATIAL_TAB_KEYS.some((key) =>
    searchParams.has(key)
  );
  const savedTabActive = SAVED_TAB_KEY.some((key) => searchParams.has(key));

  return {
    severityTabActive,
    electricalTabActive,
    vegetationTabActive,
    spatialTabActive,
    savedTabActive,
  };
}
