import { Translated } from "./utils";

export const Pole = Translated({
  EN: "Pole",
  SE: "Stolpe",
});

export const TowerTemplateEditor = Translated({
  EN: "Structure Template Editor",
  SE: "Konstruktions Mall Redigerare",
});
