function addSubDomainToOrigin(subDomain: string, origin: string): string {
	const url_split = origin.split("://");
	const domain = url_split[1];
	return `https://${subDomain}.${domain}`;
}

export function getAPIEndpoint(): string {
	let apiEndpoint = "https://api.skyqraft.com";
	switch (window.location.origin) {
		case "https://map.skyqraft.com":
			apiEndpoint = "https://api.skyqraft.com";
			break;
		case "https://dev.skyqraft.com":
			apiEndpoint = "https://api.dev.skyqraft.com";
			break;
		case "https://test.skyqraft.com":
			apiEndpoint = "https://api.test.skyqraft.com";
			break;
		case "https://hawkeye.skyqraft.com":
			apiEndpoint = "https://api.hawkeye.skyqraft.com";
			break;
		case "https://atom.skyqraft.com":
			apiEndpoint = "https://api.atom.skyqraft.com";
			break;
		case "https://us.skyqraft.com":
			apiEndpoint = "https://api.us.skyqraft.com";
			break;
		default:
			if (window.location.origin.endsWith("arkion.co")) {
				apiEndpoint = addSubDomainToOrigin("api", window.location.origin);
			} else {
				apiEndpoint = "http://localhost:8080";
			}
	}
	return apiEndpoint;
}

export function getLidarURL(): string {
	let lidarEndpoint = "https://lidar.skyqraft.com";
	switch (window.location.origin) {
		case "https://map.skyqraft.com":
			lidarEndpoint = "https://lidar.skyqraft.com";
			break;
		case "https://dev.skyqraft.com":
			lidarEndpoint = "https://dev.lidar.skyqraft.com";
			break;
		case "https://test.skyqraft.com":
			lidarEndpoint = "https://lidar.test.skyqraft.com";
			break;
		case "https://hawkeye.skyqraft.com":
			lidarEndpoint = "https://lidar.hawkeye.skyqraft.com";
			break;
		case "https://atom.skyqraft.com":
			lidarEndpoint = "https://lidar.atom.skyqraft.com";
			break;
		case "https://us.skyqraft.com":
			lidarEndpoint = "https://lidar.us.skyqraft.com";
			break;
		default:
			if (window.location.origin.endsWith("arkion.co")) {
				lidarEndpoint = addSubDomainToOrigin("lidar", window.location.origin);
			} else {
				lidarEndpoint = "http://localhost:4000";
			}
	}
	return lidarEndpoint;
}

export function getCDNURL(): string {
	if (window.location.origin.endsWith("skyqraft.com")) {
		return "https://cdn.skyqraft.com";
	}
	if (window.location.origin.endsWith("arkion.co")) {
		return addSubDomainToOrigin("cdn", window.location.origin);
	}
	return "http://localhost:5000";
}
