import React from "react";
import Select from "react-select";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { useLanguage } from "hooks";
import "./style.scss";

function LanguageSelector() {
  const { language, setLanguage } = useLanguage();

  const onChange = (e) => {
    const newLanguage = e.value;
    if (newLanguage === "EN" || newLanguage === "SE") {
      setLanguage(newLanguage);
    }
  };

  const options = [
    { value: "EN", label: "English" },
    { value: "SE", label: "Swedish" },
  ];

  return (
    <MenuItem onClick={() => {}} passive id="language">
      <p>{translations.Menu.Settings.Language[language]}</p>
      <Select
        name={"Language"}
        className="pr-10 pl-10 grow"
        options={options}
        defaultValue={options.find((o) => o.value === language)}
        onChange={onChange}
      />
    </MenuItem>
  );
}
export default LanguageSelector;
