import { Checkbox, FormControlLabel } from "@mui/material";
import { useSeverityFilter, useLanguage } from "hooks";
import translations from "translations";

export default function MachineDetectedFilter() {
  const { severityFilter, setSeverityFilter } = useSeverityFilter();
  const { language } = useLanguage();

  function onChange(value: boolean) {
    if (!value) {
      setSeverityFilter(severityFilter.filter((s) => s !== 11));
    } else {
      setSeverityFilter([...severityFilter, 11]);
    }
  }

  const isChecked = severityFilter.includes(11);

  return (
    <FormControlLabel
      checked={isChecked}
      sx={{
        "&:hover": {
          backgroundColor: "#EDEDED",
        },
        p: 0,
        m: 0,
        py: 1,
        px: 1,
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(!isChecked)
      }
      control={<Checkbox />}
      label={translations.Filter.MachineDetectedDefects[language]}
    />
  );
}
