import { Stack } from "@mui/material";
import { useSelector, useDetectionCount, useLanguage } from "hooks";
import { useObjectTypeFilter } from "hooks/filter/search_parameters/useObjectTypeFilter";
import ExpandedSelect, { IOptionCategory } from "../ExpandedSelect";

export default function VegetationDetectionFilter({
  open,
}: {
  open?: boolean;
}) {
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const detectedCats = useSelector((state) => state.objects.detectedCategories);
  const { language } = useLanguage();
  const { objectTypeFilter, setObjectTypes } = useObjectTypeFilter();
  const { counts } = useDetectionCount();

  const options: IOptionCategory[] = [];
  for (const detectionCategory of detectedCats) {
    const categoryOption = {
      id: detectionCategory.id,
      name: detectionCategory[`${language.toLocaleLowerCase()}_name`],
      count: 0,
      options: [],
    };

    for (const objectType of objectTypes.filter(
      (ot) => ot.category === detectionCategory.id
    )) {
      const count = counts.find((c) => c.id === objectType.id);
      categoryOption.options.push({
        id: objectType.id,
        name: objectType[`${language.toLocaleLowerCase()}_name`],
        count: count?.count ?? 0,
      });
    }
    options.push(categoryOption);
  }

  const filteredOptions = options.filter(
    (f) => f.name.toLowerCase() === "vegetation"
  );

  return (
    <Stack mx={2}>
      <ExpandedSelect
        categories={filteredOptions}
        value={objectTypeFilter}
        onChange={setObjectTypes}
      />
    </Stack>
  );
}
