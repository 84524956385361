import React from "react";
import { MenuItem, Typography, ListItemIcon } from "@mui/material";
import { Calculate } from "@mui/icons-material";
import { length, lineString } from "@turf/turf";
import { featureIsInSelection, featureIsPowerline } from "utils/functions";

interface IProps {
  selection: google.maps.Rectangle;
  gmap: google.maps.Map;
}

export default function CalculateLength({ selection, gmap }: IProps) {
  function calculate() {
    let totalLength = 0;
    if (!!!gmap) return;
    gmap.data.forEach((feature) => {
      if (
        featureIsPowerline(feature) &&
        featureIsInSelection(feature, selection)
      ) {
        const geometry = feature.getGeometry();
        let lineCoordinates = [];
        geometry.forEachLatLng((latlng) => {
          lineCoordinates.push([latlng.lng(), latlng.lat()]);
        });
        const line = lineString(lineCoordinates);
        totalLength += length(line, { units: "kilometers" });
      }
    });

    alert(
      `The selected grid is ${Math.round(totalLength * 100) / 100} km long`
    );
    selection.getBounds();
    selection.setMap(null);
  }

  return (
    <MenuItem onClick={calculate}>
      <ListItemIcon style={{ minWidth: "auto", marginRight: "10px" }}>
        <Calculate />
      </ListItemIcon>
      <Typography>Calculate length</Typography>
    </MenuItem>
  );
}
