import React, { useContext } from "react";
import { MenuItem, Typography, ListItemIcon } from "@mui/material";
import { Done, Clear } from "@mui/icons-material";
import { authorizedPost } from "utils/request";
import { toast } from "react-toastify";
import { updatePowerlineColor } from "state/actions";
import store from "state/store";
import { featureIsInSelection, featureIsPowerline } from "utils";
import { context as WorkSessionContext } from "providers/WorkSessionProvider";
interface IProps {
  selection: google.maps.Rectangle;
  gmap: google.maps.Map;
}

export default function AnnotatorInspectionItem({ selection, gmap }: IProps) {
  const workSessionContext = useContext(WorkSessionContext);
  function onClick(value: boolean) {
    const selectionBounds = selection.getBounds().toJSON();

    // Construct database post request
    const body = {
      annotator_inspected: value,
      ...selectionBounds,
    };

    //Send data to databse
    authorizedPost(`/powerline/annotatorinspection`, body)
      .then(() => {
        workSessionContext.update();
        let inspectionFeatures = [];
        if (!!gmap) {
          gmap.data.forEach((feature) => {
            if (
              featureIsPowerline(feature) &&
              featureIsInSelection(feature, selection)
            ) {
              inspectionFeatures.push(feature);
            }
          });
          // Use store.dispatch to avoid circular dependencies
          store.dispatch(
            // @ts-ignore
            updatePowerlineColor(
              inspectionFeatures,
              "annotator_inspected",
              value,
              gmap
            )
          );
        }
      })
      .catch(() => {
        toast.error("Failed to update powerlines");
      });
  }
  return (
    <MenuItem>
      <ListItemIcon
        onClick={() => {
          onClick(true);
        }}
        style={{ minWidth: "auto", marginRight: "10px" }}
      >
        <Done />
      </ListItemIcon>
      <ListItemIcon
        onClick={() => {
          onClick(false);
        }}
        style={{ minWidth: "auto", marginRight: "10px" }}
      >
        <Clear />
      </ListItemIcon>
      <Typography>Annotator analysis</Typography>
    </MenuItem>
  );
}
