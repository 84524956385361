import { useState, useEffect, ReactNode, useCallback } from "react";
import { IContextValue, context } from "./context";
import {
  getAnnotationsFromAPI,
  saveAnnotations,
} from "views/AnnotationTool/api";
import { useCurrentProject, useSelector } from "hooks";

interface IProviderProps {
  children: ReactNode;
  imageID: number;
}

type ISpecialAnnotation = Awaited<
  ReturnType<typeof getAnnotationsFromAPI>
>[number];

function AnnotationProvider({ children, imageID }: IProviderProps) {
  // Set up some states to keep track of
  const currentProject = useCurrentProject();
  const [annotations, setAnnotations] = useState<ISpecialAnnotation[]>([]);
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const [originalAnnotations, setOriginalAnnotations] = useState<
    ISpecialAnnotation[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [savedTypes, setSavedTypes] = useState<number | null>();
  const [saveFeedback, setSaveFeedback] = useState<string>("");
  const [highlightedId, setHighlightedId] = useState<string | null>();

  //Add the type id for steelwork edit mode
  const steelworkObjectTypeId = 231;

  //Handle the update data and state logic
  const getAnnotationsFunction = useCallback(
    async function getAnnotationsFunction() {
      // Update the state
      setLoading(true);
      if (objectTypes.length === 0) return;
      const response = await getAnnotationsFromAPI(
        currentProject.id,
        imageID,
        objectTypes
      );
      setAnnotations(response);
      setOriginalAnnotations(response);
      setLoading(false);
    },
    [currentProject.id, imageID, objectTypes]
  );

  useEffect(() => {
    getAnnotationsFunction();
  }, [imageID, objectTypes, getAnnotationsFunction]);

  async function updateAnnotation(
    annotationID: string,
    data: ISpecialAnnotation
  ) {
    const newAnnotations = annotations.map((annotation) => {
      if (annotation.id === annotationID) {
        const returnData = annotationsAutoChanges(annotation, data);
        return returnData;
      }
      return annotation;
    });
    setAnnotations(newAnnotations);
  }

  async function deleteAnnotation(annotationID: string) {
    const newAnnotations = annotations.filter((annotation) => {
      if (annotation.id === annotationID) {
        return false;
      }
      return true;
    });
    setAnnotations(newAnnotations);
  }

  // This function is where we can put logic to handle specific automatic changes
  // to the annotation data based on the changes made to the annotation
  function annotationsAutoChanges(
    oldAnnotation: ISpecialAnnotation,
    newAnnotation: ISpecialAnnotation
  ) {
    //Size and position changes
    if (
      oldAnnotation.x !== newAnnotation.x ||
      oldAnnotation.y !== newAnnotation.y ||
      oldAnnotation.w !== newAnnotation.w ||
      oldAnnotation.h !== newAnnotation.h ||
      oldAnnotation.rotation !== newAnnotation.rotation
    ) {
      //go thru all the workflow status and set them to 2 if they are 1
      for (let i = 0; i < oldAnnotation.workflow_status.length; i++) {
        if (oldAnnotation.workflow_status[i] === 1) {
          newAnnotation.workflow_status[i] = 2;
        }
      }
      return newAnnotation;
    }

    return newAnnotation;
  }

  async function saveAnnotationsProvider(
    newAnnotations: ISpecialAnnotation[],
    oldAnnotations: ISpecialAnnotation[]
  ) {
    setLoading(true);
    setSaveFeedback("loading");
    const saveResponse = await saveAnnotations(
      imageID,
      newAnnotations,
      oldAnnotations
    );
    setSaveFeedback(saveResponse);
    const response = await getAnnotationsFromAPI(
      currentProject.id,
      imageID,
      objectTypes
    );
    setAnnotations(response);
    setOriginalAnnotations(response);
    setLoading(false);

    setTimeout(() => {
      setSaveFeedback("");
    }, 1000);
    return saveResponse;
  }

  function resetAnnotations() {
    setAnnotations(originalAnnotations);
  }

  //create a function called setSelectedAnnotation that takes in a string id or null and sets the annotation with that id to highlighted true and all others to false
  async function setSelectedAnnotation(annotationID: string | null) {
    const newAnnotations = annotations.map((annotation) => {
      if (annotation.id === annotationID) {
        annotation.highlighted = true;
      } else {
        annotation.highlighted = false;
      }
      return annotation;
    });
    setAnnotations(newAnnotations);
    setHighlightedId(annotationID);
  }

  async function setHoveredAnnotation(annotationID: string | null) {
    const highlighted = annotations.filter((a) => a.highlighted === true);
    const newAnnotations = annotations.map((annotation) => {
      if (annotation.id === annotationID && highlighted.length === 0) {
        annotation.hover = true;
      } else {
        annotation.hover = false;
      }
      return annotation;
    });
    setAnnotations(newAnnotations);
  }

  const payload: IContextValue = {
    annotations: annotations,
    originalAnnotations: originalAnnotations,
    loading,
    error: undefined,
    savedTypes,
    objectTypes,
    saveFeedback,
    steelworkObjectTypeId: steelworkObjectTypeId,
    highlightedId,
    setSavedTypes: setSavedTypes,
    updateAnnotation: updateAnnotation,
    saveAnnotations: saveAnnotationsProvider,
    setAnnotations: setAnnotations,
    setOriginalAnnotations: setOriginalAnnotations,
    resetAnnotations: resetAnnotations,
    deleteAnnotation: deleteAnnotation,
    setSelectedAnnotation: setSelectedAnnotation,
    getAnnotationsFunction: getAnnotationsFunction,
    setHoveredAnnotation: setHoveredAnnotation,
  };

  return <context.Provider value={payload}>{children}</context.Provider>;
}

export default AnnotationProvider;
