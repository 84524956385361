const translations = {
  PoleCatcher: {
    NoImagesFound: {
      EN: "No images found for this pole",
      SE: "Inga bilder hittades för denna stolpe",
    },
  },
  pole: {
    label: {
      EN: "Label",
      SE: "Littra",
    },
    feedBay: {
      EN: "Feed bay",
      SE: "Fack nr",
    },
    feedStation: {
      EN: "Feed station",
      SE: "Fackstation",
    },
    lastViewedBy: {
      EN: "Last viewed by",
      SE: "Senast visad av",
    },
    lastViewedAt: {
      EN: "Last viewed at",
      SE: "Senast visad",
    },
    assignImagesAroundPole: {
      EN: "Assign images around pole",
      SE: "Tilldela bilder runt stolpe",
    },
  },
  Month: {
    0: {
      EN: "January",
      SE: "Januari",
    },
    1: {
      EN: "February",
      SE: "Februari",
    },
    2: {
      EN: "March",
      SE: "Mars",
    },
    3: {
      EN: "April",
      SE: "April",
    },
    4: {
      EN: "May",
      SE: "Maj",
    },
    5: {
      EN: "June",
      SE: "Juni",
    },
    6: {
      EN: "July",
      SE: "Juni",
    },
    7: {
      EN: "August",
      SE: "Augusti",
    },
    8: {
      EN: "September",
      SE: "September",
    },
    9: {
      EN: "October",
      SE: "Oktober",
    },
    10: {
      EN: "November",
      SE: "November",
    },
    11: {
      EN: "December",
      SE: "December",
    },
  },
  Infobox: {
    NoMission: {
      EN: "No project selected",
      SE: "Inget projekt är valt",
    },
    Mission: {
      EN: "Project",
      SE: "Projekt",
    },
    FilterActive: {
      EN: "Filter is active",
      SE: "Filter är aktivt",
    },
    UsersConnected: {
      EN: "users connected",
      SE: "användare uppkopplade",
    },
  },
  // Image viewer
  ImageViewer: {
    Hidden: {
      EN: "Hidden",
      SE: "Dold",
    },
    Processed: {
      EN: "Processed",
      SE: "Behandlad",
    },
    NotProcessed: {
      EN: "Not processed",
      SE: "Ej behandlad",
    },
    lidarImage: {
      EN: "Lidar image",
      SE: "Lidar bild",
    },
    Discs: {
      EN: "Discs",
      SE: "Tallrikar",
    },
    SendFeedback: {
      EN: "Send feedback about the image to Arkion",
      SE: "Skicka feedback om bilden till Arkion",
      PoorImageQuality: {
        EN: "Image quality is poor",
        SE: "Dålig bildkvalitet",
      },
      ReportImage: {
        EN: "Report image",
        SE: "Rapportera bild",
      },
      AddComment: {
        EN: "Add a comment. Press cancel to skip comment",
        SE: "Lägg till kommentar. Klicka på avbryt för att skippa kommentar",
      },
      AddCommentRequired: {
        EN: "Add a comment.",
        SE: "Lägg till kommentar.",
      },
      Registering: {
        EN: "Registering feedback",
        SE: "Registrerar feedback",
      },
      Registered: {
        EN: "Feedback registered",
        SE: "Feedback mottagen",
      },
      IncorrectDetection: {
        EN: "Incorrect detection",
        SE: "Felaktig detektering",
      },
      MissingBlur: {
        EN: "Sensitive objects visible",
        SE: "Känsliga object synliga",
      },
      CommentRequired: {
        EN: "A comment is needed for this action",
        SE: "En kommentar krävs för denna handling",
      },
      Other: {
        EN: "Other",
        SE: "Annat",
      },
      AlreadyReported: {
        EN: "Already reported",
        SE: "Redan rapporterat",
      },
      Resolve: {
        EN: "Resolve",
        SE: "Lös",
      },
      Resolved: {
        EN: "Resolved",
        SE: "Löst",
      },
      ResolvedBy: {
        EN: "Resolved by",
        SE: "Löst av",
      },
    },
    Severity: {
      EN: "Severity",
      SE: "Allvarlighetsgrad",
    },
    DefectFixed: {
      EN: "Defect fixed",
      SE: "Skada åtgärdad",
    },
    DefectNotFixed: {
      EN: "Defect not fixed",
      SE: "Skada ej åtgärdad",
    },
    DefectReported: {
      EN: "Defect reported",
      SE: "Skada rapporterad",
    },
    DefectNotReported: {
      EN: "Click to set report date",
      SE: "Klicka för att sätta rapportdatum",
    },
    MarkAsFixed: {
      EN: "Not fixed - click to mark as fixed",
      SE: "Ej åtgärdad - klicka för att markera som åtgärdad",
    },
    MarkAsDamaged: {
      EN: "Fixed - click to mark as not fixed",
      SE: "Åtgärdad - klicka för att markera som ej åtgärdad",
    },
    MarkAsFixedVerify: {
      EN: "Are you sure you want to mark this defect as fixed?",
      SE: "Är du säker att du vill markera skadan som åtgärdad?",
    },
    MarkAsDamagedVerify: {
      EN: "Are you sure you want to mark this defect as damaged?",
      SE: "Är du säker att du vill markera skadan som en skada?",
    },
    NavigateParent: {
      EN: "Navigate to nearby images",
      SE: "Navigera till närliggande bilder",
      Title: {
        EN: "Navigate images",
        SE: "Navigera till bild",
      },
    },
    rgbImage: {
      EN: "Show RGB Image",
      SE: "Visa RGB bild",
    },
    wideImage: {
      EN: "Show wide angle image",
      SE: "Visa vidvinkelsbild",
    },
    zoomImage: {
      EN: "Show zoom image. (Tip, shift + click for speed-zoom)",
      SE: "Visa inzoomad bild. (Tips, shift + klicka för snabb-zoom)",
    },
    speedZoom: {
      EN: "Tip, shift + click for speed-zoom on image or list below",
      SE: "Tips, shift + klicka för snabb-zoom på bilden eller listan nedanför",
    },
    thermalImage: {
      EN: "Show thermal image",
      SE: "Visa värmebild",
    },
    feedBay: {
      EN: "Feed bay",
      SE: "Littra",
    },
    Label: {
      EN: "Label",
      SE: "Littra",
    },
    pole: {
      EN: "Pole",
      SE: "Stolpe",
    },
    flight: {
      EN: "Flight",
      SE: "Flygning",
    },
    StationFeedbay: {
      EN: "Station : Feedbay",
      SE: "Station : Fack",
    },
    imageName: {
      EN: "Image name",
      SE: "Bildnamn",
    },
    dateImageTaken: {
      EN: "Date captured",
      SE: "Datum tagen",
    },
    NavigateChild: {
      EN: "Navigate images",
      SE: "Navigera bilder",
    },
    ImageInfo: {
      EN: "Image information",
      SE: "Bildinformation",
      Title: {
        EN: "Image information",
        SE: "Bildinformation",
      },
    },
    List: {
      EN: "Assign to list",
      SE: "Tilldela lista",
      Title: {
        EN: "Assign to list",
        SE: "Tilldela lista",
      },
    },
    BoundingBoxes: {
      EN: "Toggle bounding boxes",
      SE: "Toggla begränsningslåda",
      Title: {
        EN: "Show/Hide boxes",
        SE: "Visa/Dölj lådor",
      },
    },
    Pipelines: {
      EN: "Toggle pipelines",
      SE: "Toggla gasledningar",
      Title: {
        EN: "Show/Hide pipelines",
        SE: "Visa/Dölj gasledningar",
      },
    },
    GoogleMaps: {
      EN: "Open in Google Maps",
      SE: "Öppna i Google Maps",
      Title: {
        EN: "Open in Google Maps",
        SE: "Öppna i Google Maps",
      },
    },
    Download: {
      EN: "Download this image",
      SE: "Ladda ned denna bild",
      Title: {
        EN: "Download image",
        SE: "Ladda ned bild",
      },
    },
    Approve: {
      EN: "Approve image",
      SE: "Godkänn bild",
      Title: {
        EN: "Approve image",
        SE: "Godkänn bild",
      },
    },
    ShowTemp: {
      EN: "Show temperatures",
      SE: "Visa temperaturer",
      Title: {
        EN: "Show temperatures",
        SE: "Visa temperaturer",
      },
    },
    Edit: {
      EN: "Edit bounding boxes and mark defects",
      SE: "Editera objekt på bilden",
      Title: {
        EN: "Edit boxes",
        SE: "Justera annoteringar",
      },
    },
    AnnotatorReview: {
      EN: "Review annotations",
      SE: "Granska annoteringar",
      Title: {
        EN: "Review annotations",
        SE: "Granska annoteringar",
      },
    },
    MLReview: {
      EN: "Review Machine Learning",
      SE: "Granska Maskininlärning",
      Title: {
        EN: "Review Machine Learning",
        SE: "Granska maskininlärning",
      },
    },
    FalsePositiveReview: {
      EN: "False Positive Review",
      SE: "Granska falska positiva",
    },
    SuperFalseReview: {
      EN: "Review false positive classifications",
      SE: "Granska falska positiva klassifikationer",
    },
    Flag: {
      EN: "Flag image for reannotation",
      SE: "Flagga för omannotering",
      Title: {
        EN: "Flag image",
        SE: "Flagga bild",
      },
    },
    Hide: {
      EN: "Hide this image",
      SE: "Dölj denna bild",
      Title: {
        EN: "Hide image",
        SE: "Dölj bild",
      },
    },
    Fullscreen: {
      EN: "Toggle fullscreen",
      SE: "Toggla helskärm",
    },
    Close: {
      EN: "Close image viewer",
      SE: "Stäng bildverktyg",
      Title: {
        EN: "Close",
        SE: "Stäng",
      },
    },
  },
  // Review tool
  ReviewTool: {
    Approve: {
      EN: "Approve",
      SE: "Godkänn",
    },
    Reject: {
      EN: "Flag image for reannotation",
      SE: "Flagga bild för omannotering",
    },
    RejectML: {
      EN: "Remove AI output. Store this as a false warning",
      SE: "Ta bort AI resultat. Spara som falsk varning",
    },
    ConfirmButHide: {
      EN: "Confirm object. But hide.",
      SE: "Verifiera objekt, men dölj.",
    },
    Previous: {
      EN: "Skip to previous reviewable object",
      SE: "Skippa till föregående granskbara objekt.",
    },
    Next: {
      EN: "Skip to next reviewable object",
      SE: "Skippa till nästa granskbara objekt.",
    },
    Exit: {
      EN: "Exit Review Mode",
      SE: "Lämna granskningsläge",
    },
  },
  // Annotation tool
  AnnotationTool: {
    setDate: {
      EN: "Set date",
      SE: "Välj datum",
    },
    BeforeSaving: {
      EN: "Do you want to save your change and please provide a comment",
      SE: "Vill du spara dina ändringar. Lägg in en kommentar om ni önskar.",
    },
    SureYouWantToLeave: {
      EN: "Are you sure you want to leave without saving?",
      SE: "Är du säker att du vill lämna utan att spara?",
    },
    IncompleteObjects: {
      EN: "You must finish your incomplete objects before saving",
      SE: "Du måste göra klart objekt som ännu ej blivit ifyllda innan du sparar.",
    },
    getDataFailed: {
      EN: "Getting data failed",
      SE: "Misslyckades att hämta data",
    },
    addDetection: {
      EN: "Add object",
      SE: "Lägg till objekt",
    },
    addDefect: {
      EN: "Add another defect to the same bounding box",
      SE: "Lägg till ytterligare en skada för samma bounding box",
    },
    deleteClass: {
      EN: "Delete class",
      SE: "Ta bort klassificering",
    },
    editClass: {
      EN: "Edit class",
      SE: "Ändra klassificering",
    },
    convertToDefect: {
      EN: "Convert to defect",
      SE: "Konvertera till skada",
    },
    expand: {
      EN: "Expand",
      SE: "Utöka",
    },
    collapse: {
      EN: "Collapse",
      SE: "Stäng",
    },
    convertToDetection: {
      EN: "Convert to object",
      SE: "Convertera till objekt",
    },
    Select: {
      EN: "Select",
      SE: "Välj",
    },
    Detection: {
      EN: "Add a detection",
      SE: "Skapa objekt",
    },
    Defect: {
      EN: "Add a defect",
      SE: "Skapa skada",
    },
    Reset: {
      EN: "Reset",
      SE: "Återställ",
    },
    AllVerified: {
      EN: "All verified",
      SE: "Alla verifierade",
    },
    VerifyAll: {
      EN: "Verify all",
      SE: "Verifiera alla",
    },
    Save: {
      EN: "Save",
      SE: "Spara",
    },
    NoDefinitionsSpecified: {
      EN: "No definitions specified",
      SE: "Inga definitioner specificerade",
    },
    DefinitionError: {
      EN: "Definition error",
      SE: "Definitions fel",
    },
    TypeMissing: {
      EN: "Type missing",
      SE: "Objekttyp saknas",
    },
    TypeError: {
      EN: "Type error",
      SE: "Objekttyp fel",
    },
    SeverityMissing: {
      EN: "Severity missing",
      SE: "Allvarlighetsgrad saknas",
    },
    SetASevrityLevel: {
      EN: "Please set a severity level",
      SE: "Vänligen sätt en allvarlighetsgrad",
    },
    Close: {
      EN: "Close",
      SE: "Stäng",
    },
    HumanMachine: {
      EN: "Display non-defect verification",
      SE: "Visa icke-defekt verifiering",
    },
    LastUpdated: {
      EN: "Last updated by",
      SE: "Senast uppdaterad av",
    },
    EditStatus: {
      EN: "Edit workflow status",
      SE: "Ändra status för arbetsflöde",
    },
    CreatedBy: {
      EN: "Created by you",
      SE: "Skapad av dig",
    },
    AddComment: {
      EN: "Add comment",
      SE: "Lägg till kommentar",
    },
    ConvertToDetection: {
      EN: "Convert to detection",
      SE: "Omvandla till objekt",
    },
    ReportDefect: {
      EN: "Report defect on top of detection",
      SE: "Rapportera skada ovanpå objekt",
    },
    WarningLocked: {
      EN: "Object is locked, are you sure you want to continue editing?",
      SE: "Objektet är låst, är du säker att du vill fortsätta redigera?",
    },
    Locked: {
      EN: "Locked, click to edit",
      SE: "Låst, tryck för att redigera",
    },
    Delete: {
      EN: "Delete",
      SE: "Radera",
    },
    Hide: {
      EN: "Hide",
      SE: "Dölj",
    },
    Show: {
      EN: "Show",
      SE: "Visa",
    },
    Visibility: {
      EN: "Toggle the visibility for the bounding box on the image. This is useful for when you have multiple overlapping boundingboxes. It does not change anything on the image",
      SE: "Toggla synbarheten för bounding box på bilden. Detta är hjälpsamt om du har flera boundingboxar som ligger över varandra. Det ändrar ingenting på bilden",
    },
    VisibilityAll: {
      EN: "Toggle the visibility for all the bounding boxes. It does not change anything on the image",
      SE: "Toggla synbarheten för alla bounding boxar. Det ändrar ingenting på bilden",
    },
    SelectDetection: {
      EN: "Select detection type",
      SE: "Välj objekttyp",
    },
    SelectSeverity: {
      EN: "Select severity",
      SE: "Välj allvarlighetsgrad",
    },
    NoObjectsMatches: {
      EN: "No objects matches",
      SE: "Inga objekt matchar",
    },
    NewRegion: {
      EN: "New region",
      SE: "Nytt objekt",
    },
    WorkflowStatusMenu: {
      ReviewRequested: {
        EN: "Request review",
        SE: "Begär granskning",
      },
      Approve: {
        EN: "Approve",
        SE: "Godkänn",
      },
      SupervisorApprove: {
        EN: "Supervisor approve",
        SE: "Handledargodkännande",
      },
      FalsePositive: {
        EN: "False positive",
        SE: "Falsk positiv",
      },
      TruePositive: {
        EN: "True positive",
        SE: "Sann positiv",
      },
      SkyqraftHidden: {
        EN: "Hide for non-arkion",
        SE: "Dölj för icke-arkion",
      },
      SkyqraftVisible: {
        EN: "Show for non-arkion",
        SE: "Visa för icke-arkion",
      },
    },
  },
  Menu: {
    Close: {
      EN: "Close menu",
      SE: "Stäng menyn",
    },
    BatchJobs: {
      EN: "Batch Jobs",
      SE: "Batch Jobs",
    },
    // Missions
    Missions: {
      EN: "Projects",
      SE: "Projekt",
      SubHeader: {
        EN: "Find a project analyzing your grid",
        SE: "Hitta ett projekt som analyserar ditt elnät",
      },
      Tooltip: {
        EN: "Create, edit and delete projects",
        SE: "Skapa, ändra och ta bort projekt",
      },
      AddMission: {
        EN: "New project",
        SE: "Nytt projekt",
      },
      OtherMissions: {
        EN: "Other projects",
        SE: "Andra projekt",
      },
      Mission: {
        EN: "Project",
        SE: "Projekt",
      },
      AnnotationWithoutImage: {
        AddAnnotationWithoutImage: {
          EN: "Add imageless annotation",
          SE: "Skapa annotering utan bild",
        },
        Click: {
          EN: "Right-click on map to mark the position of the annotation or type the coordinates.",
          SE: "Högerklicka på kartan för att markera positionen för annoteringen eller skriv in koordinaterna.",
        },
        UseCurrentPosition: {
          EN: "Use Current Position",
          SE: "Använd Nuvarande Position",
        },
        Type: {
          EN: "Critical defect identified",
          SE: "Kritisk skada identifierad",
        },
        Severity: {
          EN: "Select Severity",
          SE: "Välj allvarlighetsgrad",
        },
        Add: {
          EN: "Add",
          SE: "Skapa",
        },
      },
    },
    // Filters
    Filters: {
      EN: "Filters",
      SE: "Filter",
      Tooltip: {
        EN: "Apply various filters for images and objects",
        SE: "Aktivera olika filter för bilder och objekt.",
      },
      DateFilter: {
        EN: "Date Filter",
        SE: "Datumfilter",
        Tooltip: {
          EN: "Filter found images based on the date",
          SE: "Filtrera bilder baserat på datum",
        },
        DatePlaced: {
          EN: "Date defect placed",
          SE: "Datum defekt placerad",
          Tooltip: {
            EN: "Find all defects created during a specific time window",
            SE: "Hitta alla defekter skapade under ett specifikt tidsfönster",
          },
        },
        Processed: {
          EN: "Date processed",
          SE: "Datum bearbetad",
          Tooltip: {
            EN: "Find all defects processed within a time period",
            SE: "Hitta alla defekter bearbetade inom en tidsperiod",
          },
        },
      },
      ImageLists: {
        EN: "Image lists",
        SE: "Bildlistor",
        ToolTip: {
          EN: "Manage image lists or activate an image list to use it as a filter",
          SE: "Hantera bildlistor eller aktivera en bildlista för att använda den som ett filter",
        },
      },
      Distance: {
        EN: "Distance",
        SE: "Avstånd",
      },
      LidarTooltip: {
        EN: "Filter distance in 3D data of a project. The filter will create a heatmap of all the areas where the distance between the wire and surrounding objects is lower than the value set",
        SE: "Filtrera avstånd i 3D datan tillhörande ett projekt. Filtret skapar en heatmap över områden där avståndet mellan linan och kringliggande objekt är kortare än valt värde",
      },
      ClearFilters: {
        EN: "Clear Filters",
        SE: "Rensa Filter",
      },
      TypeSelector: {
        EN: "Type selector",
        SE: "Typ av detektering",
        ToolTip: {
          EN: "Choose to filter on defects or objects",
          SE: "Välja att filtrera på antingen skador eller objekt",
        },
      },
      DefectsAndRemarks: {
        EN: "Defects & Remarks",
        SE: "Skador & upplysningar",
        ToolTip: {
          EN: "Filter different types and severity levels of defects and remarks found on images",
          SE: "Filtrera olika typer och allvarlighetsgrader av skador och upplysningar markerade på bilder",
        },
        DefectAndRemarkType: {
          EN: "Grouped types of defects and remarks",
          SE: "Grupperade typer av skador och upplysningar",
        },
        RemarkSeverity: {
          EN: "Remark Severity",
          SE: "Upplysningar allvarlighetsgrad",
          Tooltip: {
            EN: "Severity level of remarks",
            SE: "Allvarlighetsgrad av upplysningar",
          },
        },
      },
      Defect: {
        EN: "Defect",
        SE: "Skada",
      },
      Defects: {
        EN: "Defects",
        SE: "Skador",
      },
      Remarks: {
        EN: "Remarks",
        SE: "Upplysningar",
      },
      Objects: {
        EN: "Objects",
        SE: "Objekt",
        ToolTip: {
          EN: "Filter different types of objects found on images",
          SE: "Filtrera olika typer av objekt markerade på bilder",
        },
      },
      DefectType: {
        EN: "Defect Type",
        SE: "Typ av Skada",
      },
      DefectSeverity: {
        EN: "Defect severity",
        SE: "Skador allvarlighetsgrad",
        ToolTip: {
          EN: "Severity level of defects",
          SE: "Allvarlighetsgrad av skador",
        },
      },
      Critical: {
        EN: "Critical",
        SE: "Kritisk",
      },
      Issue: {
        EN: "Issue",
        SE: "Anmärkning",
      },
      RemarkSeverity: {
        EN: "Remark severity",
        SE: "Upplysningar allvarlighetsgrad",
        ToolTip: {
          EN: "Severity level of remarks",
          SE: "Allvarlighetsgrad av upplysningar",
        },
      },
      ShouldBeFixed: {
        EN: "Should be fixed",
        SE: "Bör åtgärdas",
      },
      GoodToKnow: {
        EN: "Good to know",
        SE: "För info",
      },
      Inventory: {
        EN: "Object",
        SE: "Objekt",
      },
      WorkFlow: {
        EN: "Workflow",
        SE: "Arbetsstatus",
        ToolTip: {
          EN: "Filter stages of the annotation flow",
          SE: "Filtrera olika typer av stadier i annoteringsflödet",
        },
      },
      Labelled: {
        EN: "Labelled by humans/machine",
        SE: "Märkt av människor/maskin",
      },
      ImageType: {
        EN: "Image type",
        SE: "Bildtyp",
      },
      FlightFilter: {
        EN: "Flight filter",
        SE: "Flygfilter",
        ToolTip: {
          EN: "Filter different flight ID's. Most commonly used by drone pilots for root causing in case of issues with the flight and/or image upload",
          SE: "Filtrera olika flight ID. Används oftast av drönarpiloter vid eventuell felsökning av flygningar och/eller vid bilduppladdning",
        },
      },
      OriginFilter: {
        EN: "Creator Filter",
        SE: "Skaparfilter",
        ToolTip: {
          EN: "Filter the creator of a bounding box. Sometimes called the origin of a bounding box.",
          SE: "Filtrera skaparen av en bounding box. Ibland kallat ursprunget av en bounding box",
        },
      },
      Test: {
        EN: "test en",
        SE: "test sv",
      },
      ImageFilter: {
        EN: "Image types",
        SE: "Bildtyper",
        Tooltip: {
          EN: "Filter different types of images",
          SE: "Filtrera för olika typer av bilder",
        },
        New: {
          EN: "New defects",
          SE: "Nya defekter",
          ToolTip: {
            EN: "Filter images that contains new defects",
            SE: "Filtrera bilder som innehåller nya defekter",
          },
        },
        RGBTooltip: {
          EN: "Regular 2D images",
          SE: "Vanliga 2D bilder",
        },
        Flagged: {
          EN: "Flagged images",
          SE: "Flaggade bilder",
          ToolTip: {
            EN: "Filter images flagged for reannotation",
            SE: "Filtrera bilder flaggade för om-annotering",
          },
        },
        Thermal: {
          EN: "Thermal",
          SE: "Värmebilder",
          Tooltip: {
            EN: "Thermal images",
            SE: "Värmebilder",
          },
        },
        ListFilter: {
          Active: {
            EN: "Active",
            SE: "Aktiv",
          },
          List: {
            EN: "List",
            SE: "Lista",
          },
          Images: {
            EN: "Images",
            SE: "Bilder",
          },
          Info: {
            EN: "Info",
            SE: "Info",
          },
          Delete: {
            EN: "Delete",
            SE: "Radera",
          },
          DeleteConfirmation: {
            EN: "Are you sure you want to delete list",
            SE: "Är du säker att du vill ta bort listan",
          },
          RenamePrompt: {
            EN: "Enter new title for list.",
            SE: "Ange en nytt titel för lista",
          },
          Share: {
            EN: "Share",
            SE: "Dela",
          },
          NewList: {
            EN: "New list",
            SE: "Ny lista",
          },
          Add: {
            EN: "Add",
            SE: "Skapa",
          },
          CopiedToClipboard: {
            EN: "Link was copied to clipboard",
            SE: "Länk kopierades till urlipp",
          },
          Public: {
            EN: "Public",
            SE: "Offentlig",
          },
          Locked: {
            EN: "Locked",
            SE: "Låst",
          },
          UnLocked: {
            EN: "Unlocked",
            SE: "Olåst",
          },
          Rename: {
            EN: "Rename",
            SE: "Döp om",
          },
        },
      },
    },
    // Tools
    Tools: {
      EN: "Tools",
      SE: "Verktyg",
      Tooltip: {
        EN: "See various tools available for the current project",
        SE: "Se vertyg för detta projekt.",
      },
      ProjectTools: {
        EN: "Project",
        SE: "Projekt",
      },
      CustomerTools: {
        EN: "Power Grid",
        SE: "Elnät",
      },
      GlobalTools: {
        EN: "Global",
        SE: "Globala",
      },
      PoleStatus: {
        EN: "Structure Template Editor",
        SE: "Konstruktions Mall Redigerare",
      },
      Reports: {
        EN: "Reports",
        SE: "Rapporter",
        Tooltip: {
          EN: "Download different reports",
          SE: "Nedladdning av olika rapporter",
        },
        ClickOnATabAbove: {
          EN: "Click on a tab above",
          SE: "Klicka på en flik ovanför",
        },
        AssetReport: {
          Download: {
            EN: "Download asset report",
            SE: "Ladda ned tillgångsrapport",
          },
          EN: "Asset report",
          SE: "Tillgångsrapport",
          Description1: {
            EN: "An asset report will extract a report of all identified object properties. A property is added to a normal image annotation and is included in this report.",
            SE: "En tillgångsrapport kommer att extrahera en rapport över alla identifierade objektegenskaper. En egenskap läggs till en normal bildannotering och ingår i denna rapport.",
          },
          Description2: {
            EN: "The asset report gives you an extract of all data without aggregation over physical objects.",
            SE: "Tillgångsrapporten ger dig ett utdrag av alla data utan aggregering över fysiska objekt.",
          },
          PoleReport: {
            EN: "Pole report",
            SE: "Stolpstatus rapport",
            Description1: {
              EN: "A pole report extracts all status reports of pole objects. Each status will have a component, placement and status associated with the row.",
              SE: "En stolpstatus rapport extraherar alla statusrapporter för stolpobjekt. Varje status kommer att ha en komponent, placering och status associerad med raden.",
            },
            Description2: {
              EN: "The pole report allows you to see a report of each physical component in your grid. This currently the most useful method to get a status report of your grid.",
              SE: "Stolpstatusrapporten låter dig se en rapport över varje fysisk komponent i ditt nät. Detta är för närvarande det mest användbara sättet att få en statusrapport över ditt nät.",
            },
          },
          FeedBayReport: {
            EN: "Pole ID report",
            SE: "Stolp ID rapport",
            Description1: {
              EN: "Use this report to get a complete list of all poles in your grid and check if the poles in the real world exist in your asset management system with the correct IDs. The pole IDs that were sent to Arkion in a KML file (or shapefile) can be reviewed here.",
              SE: "Använd denna rapport för att få en komplett lista över alla stolpar i ditt nät och kontrollera om stolparna i verkligheten finns i ditt NIS-system med rätt ID. De Stolp-ID som skickades till Arkion i en KML-fil (eller shapefil) kan granskas här.",
            },
            Description2: {
              EN: "Arkion can generate new poles from drone images and accurately give them a GPS location on the map. Use this report to get a spreadsheet of these poles and use the sheet to import ant create new assets in your asset mangement system.",
              SE: "Arkion kan generera nya stolpar från drönarbilder och exakt ge dem en GPS-position på kartan. Använd denna rapport för att få ett kalkylblad över dessa stolpar och använd kalkylbladet för att importera och skapa nya tillgångar i ditt NIS-system.",
            },
            Description3: {
              EN: "Also use this report to check if there are poles that are missing one or two ID-nummers. Most customers have two ID numbers per pole, one number on the sign of the pole (a pole ID) and one unique database asset number (client asset ID). This report helps you to review this list.",
              SE: "Använd också denna rapport för att kontrollera om det finns stolpar som saknar ett eller två ID-nummer. De flesta kunder har två ID-nummer per stolpe, ett nummer på stolpens skylt (ett stolp-ID) och ett unikt databas-ID-nummer (klient-ID). Denna rapport hjälper dig att granska denna lista.",
            },
          },
        },
      },
      Upload: {
        EN: "Upload",
        SE: "Uppladdning",
        Tooltip: {
          EN: "Upload menu",
          SE: "Uppladdnings meny",
        },
      },
      Download: {
        EN: "Download",
        SE: " Nedladdning",
        Tooltip: {
          EN: "Download menu",
          SE: "Nedladdnings meny",
        },
        ClickOnATabToDownload: {
          EN: "Click on a tab to download",
          SE: "Klicka på en flik för att ladda ner",
        },
      },
      MapObjectsToggle: {
        NormalImage: {
          EN: "Normal image",
          SE: "Normal bild",
        },
      },
      ManageAccess: {
        EN: "Manage Access",
        SE: "Hantera Åtkomst",
        Users: {
          EN: "Users",
          SE: "Användare",
          AddUser: {
            EN: "Add User",
            SE: "Lägg till användare",
            Name: {
              EN: "Name of new user",
              SE: "Namn på ny användare",
            },
            Email: {
              EN: "Email of new user",
              SE: "Epost på ny användare",
            },
            SendInvite: {
              EN: "Send Invite",
              SE: "Skicka inbjudan",
            },
          },
          NoGroups: {
            EN: "No customers",
            SE: "Inga kunder",
          },
          FullName: {
            EN: "Full Name",
            SE: "Fullständigt namn",
          },
          NameMissing: {
            EN: "Name missing",
            SE: "Namn saknas",
          },
          PhoneNumber: {
            EN: "Phone number",
            SE: "telefonnummer",
          },
        },
        Groups: {
          EN: "Customers",
          SE: "Kunder",
          GroupBucket: {
            EN: "Customer Bucket",
            SE: "Kundhink",
          },
          UsingDefaultBucket: {
            EN: "Using default bucket",
            SE: "Använder förinställd hink",
          },
          YourAccessLevel: {
            EN: "Your access level",
            SE: "Din accessnivå",
          },
          Group: {
            EN: "Customer",
            SE: "Kund",
          },
          NoGroup: {
            EN: "No customer",
            SE: "Ingen kund",
          },
        },
        Missions: {
          EN: "Projects",
          SE: "Projekt",
          MissionName: {
            EN: "Project Name",
            SE: "Projektsnamn",
          },
          StopEditing: {
            EN: "Stop editing",
            SE: "Sluta redigera",
          },
          EditMission: {
            EN: "Edit project",
            SE: "Redigera projekt",
          },
          Delete: {
            EN: "Delete",
            SE: "Ta bort",
          },
        },
        SelectTabAbove: {
          EN: "Select tab above",
          SE: "Välj flik ovanför",
        },
        Search: {
          EN: "Search by email, name or phone number",
          SE: "Sök på email, namn eller telefonnummer",
        },
        NoUsersFound: {
          EN: "No users found. Try changing the search text",
          SE: "Inga användare funna, prova att ändra söktexten",
        },
        NoMissionsFound: {
          EN: "No projects found. Try changing the search text",
          SE: "Inga projekt funna, prova att ändra söktexten",
        },
      },
      Objects: {
        EN: "Objects",
        SE: "Objekt",
      },
      UserManager: {
        EN: "User Manager",
        SE: "Hantera Användare",
      },
      MissionManager: {
        EN: "Project Manager",
        SE: "Hantera projekt",
      },
      CustomerManager: {
        EN: "Customer Manager",
        SE: "Hantera Kunder",
      },
      Changelog: {
        EN: "Changelog",
        SE: "Ändringslogg",
      },
      BlurImages: {
        EN: "Blur sensitive data",
        SE: "Sudda ut känslig information",
        Tooltip: {
          EN: "Start batch job blurring images of faces, licence plates and the sky?",
          SE: "Starta ett arbete för att sudda ut ansikten, bilskyltar och himlen?",
        },
        Yes: {
          EN: "Yes",
          SE: "Ja",
        },
        No: {
          EN: "No",
          SE: "Nej",
        },
      },
      ResetTutorial: {
        EN: "Reset Tutorial",
        SE: "Återställ genomgång",
      },
      SelectAMission: {
        EN: "Select a project to see available tools",
        SE: "Välj ett projekt för att se tillgängliga verktyg",
      },
      UsageReport: {
        EN: "Project info",
        SE: "Projektsinformation",
        Usage: {
          EN: "Usage",
          SE: "Användning",
        },
        Tooltip: {
          EN: "Get a report of how much resources each customer is using",
          SE: "Hämta en rapport på mängden resurser varje kundgrupp använder",
        },
        GeneratingExcel: {
          EN: "Generating project info for Excel...",
          SE: "Genererar projektinformation för Excel...",
        },
        GeneratingCSV: {
          EN: "Generating project info in CSV...",
          SE: "Genererar projektinformation i CSV...",
        },
        Generated: {
          EN: "Report generated!",
          SE: "Rapport genererad!",
        },
      },
      WorkSessionReport: {
        EN: "Work sessions info",
        SE: "Information om arbetssessioner",
        Title: {
          EN: "Work Sessions",
          SE: "Arbetssessioner",
        },
        Tooltip: {
          EN: "Get a report of all work sessions",
          SE: "Hämta en rapport på alla arbetssessioner",
        },
        GeneratingExcel: {
          EN: "Generating work session info in Excel...",
          SE: "Genererar arbetssessioner info i Excel...",
        },
        GeneratingCSV: {
          EN: "Generating work session info in CSV...",
          SE: "Genererar arbetssessioner info i CSV...",
        },
        Generated: {
          EN: "Report generated!",
          SE: "Rapport genererad!",
        },
      },
      InventoryReport: {
        EN: "Object Report",
        SE: "Objektrapport",
        Inventory: {
          EN: "Object",
          SE: "Objekt",
        },
        Tooltip: {
          EN: "Get a report for the objects in the grid",
          SE: "Hämta en objektrapport för elnätet",
        },
        Download: {
          EN: "Download object report",
          SE: "Ladda ned objektrapport",
        },
        Rows: {
          EN: "rows",
          SE: "rader",
        },
        Types: {
          EN: "Types",
          SE: "Typer",
        },
        NoData: {
          EN: "Report had no data. Try changing the filters",
          SE: "Rapporten hade ingen data. Försök byta filter",
        },
        GeneratingCSV: {
          EN: "Generating CSV file...",
          SE: "Genererar CSV fil...",
        },
        GeneratingExcel: {
          EN: "Generating Excel file...",
          SE: "Genererar excelfil...",
        },
        ExcelGenerated: {
          EN: "Excel file generated!",
          SE: "Excelfil genererad!",
        },
        CSVGenerated: {
          EN: "CSV file generated!",
          SE: "CSV-fil genererad!",
        },
        ExcelFailed: {
          EN: "Excel generation failed.",
          SE: "Generering av excelfil misslyckades",
        },
        CSVFailed: {
          EN: "CSV generation failed",
          SE: "Generering av CSV-fil misslyckades",
        },
      },
      VegetationReport: {
        EN: "Vegetation Report",
        SE: "Vegitationsrapport",
        Tooltip: {
          EN: "Get a vegetation report",
          SE: "Hämta en vegitationsrapport",
        },
      },
      // Defect report
      DefectReport: {
        EN: "Defect Report",
        SE: "Defektrapport",
        NewTool: {
          EN: "There is a new tool for creating defect reports. Click here to see the new tool",
          SE: "Vi har byggt ett nytt verktyg för att skapa defektrapporter. Klicka här för att se det nya verktyget",
        },
        Defect: {
          EN: "Defects",
          SE: "Skador",
        },
        Tooltip: {
          EN: "Downlad a report of all defects",
          SE: "Ladda ned rapport av alla skador",
        },
        Generating: {
          EN: "Generating...",
          SE: "Genererar...",
        },
        Title: {
          EN: "Download defect report",
          SE: "Ladda ned defektrapport",
        },
        SelectAll: {
          EN: "Select All",
          SE: "Välj alla",
        },
        OnePage: {
          EN: "One page per defect",
          SE: "En sida per anmärkning",
        },
        DownloadOnePagePerDefectPDF: {
          EN: "Download one page per defect PDF",
          SE: "Ladda ned en sida per anmärkning PDF ",
        },
        HideFixed: {
          EN: "Hide fixed",
          SE: "Dölj lagade skador",
        },
        ImageLists: {
          EN: "Image Lists",
          SE: "Bildlistor",
        },
        DefectTypes: {
          EN: "Defect types",
          SE: "Typ av Skada",
        },
        Severities: {
          EN: "Severities",
          SE: "Allvarlighetsgrad",
        },
        DownloadPDF: {
          EN: "Download PDF",
          SE: "Ladda ned PDF",
        },
        DownloadCSV: {
          EN: "Download CSV",
          SE: "Ladda ned CSV",
        },
        ExportKML: {
          EN: "Download KML",
          SE: "Ladda ned KML",
        },
        DownloadExcel: {
          EN: "Download Excel",
          SE: "Ladda ned Excel",
        },
        ExportDP: {
          EN: "Export to dpPower (deprecated)",
          SE: "Exportera till dpPower (gammal)",
        },
        ExportDPCSV: {
          EN: "Export to dpPower as CSV",
          SE: "Exportera till dpPower som CSV",
        },
        ExportDPExcel: {
          EN: "Export to dpPower as Excel",
          SE: "Exportera till dpPower som Excel",
        },
        ExportDPLock: {
          EN: "Export to dpPower and lock objects",
          SE: "Exportera till dpPower och lås objekt",
        },
        ExportIBM: {
          EN: "Export to IBM Mazimo",
          SE: "Exportera till IBM Mazimo",
        },
        ExportSAP: {
          EN: "Export to SAP",
          SE: "Exportera till SAP",
        },
        Objects: {
          EN: "objects",
          SE: "objekt",
        },
        Images: {
          EN: "Images",
          SE: "Bilder",
        },
        Poles: {
          EN: "Poles",
          SE: "Stolpar",
        },
      },
      AssetReport: {
        EN: "Assets",
        SE: "Anläggningrapport",
        Title: {
          EN: "Download an asset report",
          SE: "Ladda ned anläggningsrapport",
        },
        Description: {
          EN: "An asset report allows you to identify all properties regarding each pole on the current project. This can be used to find which towers have been damaged the most by rust, or which towers are tilting the most.",
          SE: "En anläggningsrapport låter dig identifiera alla egenskaper angående varje stolpe på nuvarand projekt. Detta låter dig hitta vilka stolpar som har skadats mest av rost, eller vilka stolpar som lutar mest.",
        },
      },
      UploadZones: {
        EN: "Upload zones",
        SE: "Ladda upp zoner",
        Description: {
          EN: "Upload a KML file with zone data to current map. ",
          SE: "Ladda upp en KML-fil med Zon-data till nuvaranda projekt. ",
        },
        Support: {
          EN: "We currently only support MultiPolygons.",
          SE: "Vi stöder för tillfället bara MultiPolygons",
        },
        ChooseFile: {
          EN: "Choose File",
          SE: "Välj Fil",
        },
        UploadZoneKml: {
          EN: "Upload Zone KML",
          SE: "Ladda upp zon-KML",
        },
      },

      // Image Clusters
      ImageClusters: {
        EN: "Image Clusters",
        SE: "Bild kluster",
        Clusters: {
          EN: "Clusters",
          SE: "Kluster",
        },
        Tooltip: {
          EN: "Manage image cluster data",
          SE: "Hantera klusterdata för bilder",
        },
        Download: {
          EN: "Download",
          SE: "Nedladdning",
          Title: {
            EN: "Download cluster data",
            SE: "Ladda ned klusterdata",
          },
          Description: {
            EN: "Download data of all clusters connected to this project. This data groups together images that are nearby and allows the user to view them as a cluster.",
            SE: "Ladda ned data för alla kluster kopplade till detta projekt. Denna data gruppar ihop bilder som är i närheten av varandra och låter användaren se dem som ett kluster.",
          },
          Button: {
            EN: "Download",
            SE: "Ladda ned",
          },
        },
        Upload: {
          EN: "Upload",
          SE: "Uppladdning",
          ClickOnATabToUpload: {
            EN: "Click on a tab to upload",
            SE: "Klicka på en flik för att göra en uppladdning",
          },
          Title: {
            EN: "Upload cluster data",
            SE: "Ladda upp klusterdata",
          },
          Description: {
            EN: "Upload data of all clusters connected to this project. This data groups together images that are nearby and allows the user to view them as a cluster.",
            SE: "Ladda upp data för alla kluster kopplade till detta projekt. Denna data gruppar ihop bilder som är i närheten av varandra och låter användaren se dem som ett kluster.",
          },
          Button: {
            EN: "Upload",
            SE: "Ladda upp",
          },
          ChooseFile: {
            EN: "Choose File",
            SE: "Välj fil",
          },
          Uploading: {
            EN: "Uploading data",
            SE: "Laddar upp data",
          },
          Uploaded: {
            EN: "Upload successful. Reload page to see changes.",
            SE: "Uppladdning lyckades. Ladda om sidan för att se ändringar",
          },
          UploadFailed: {
            EN: "Upload failed",
            SE: "Uppladdning misslyckades",
          },
          TooLarge: {
            EN: "is too large, please pick a smaller file",
            SE: "är för stor, var god välj en mindre fil",
          },
        },
      },
      // Image Objects
      ImageObjects: {
        EN: "Image Objects",
        SE: "Bildobjekt",
        Tooltip: {
          EN: "Synchronize annotation data",
          SE: "Synkronisera annoteringsdata",
        },
        // Download
        Download: {
          EN: "Download",
          SE: "Nedladdning",
          Title: {
            EN: "Download image objects",
            SE: "Ladda ned bildobjekt",
          },
          Description: {
            EN: "Download Human Annotation and ML Detections as a JSON file. Objects are downloaded per flight. The number of objects per flight is show within the (braces).",
            SE: "Ladda ned mänskliga annoteringar och maskindetekterade objekt som en JSON fil. Bildobjekt är nedladdare för varje flygning. Antal objekt per flygning visas med en (parantes).",
          },
          Human: {
            EN: "Human",
            SE: "Människa",
          },
          ML: {
            EN: "Arkion ML",
            SE: "Arkion ML",
          },
          AllFlights: {
            EN: "All flights",
            SE: "Alla flygningar",
          },
          Flight: {
            EN: "Flight",
            SE: "Flygning",
          },
          ObjectsAvailable: {
            EN: "Objects available for download.",
            SE: "Objekt tillgängliga för nedladdning.",
          },
          NoObjects: {
            EN: "No Objects to download",
            SE: "Inga object att ladda ner",
          },
          DownloadFailed: {
            EN: "Download failed",
            SE: "Nedladdning misslyckades",
          },
          PreparingHuman: {
            EN: "Preparing Human Annotations for download",
            SE: "Förbereder objekt av människor för nedladdning",
          },
          PreparingML: {
            EN: "Preparing ML Object Files",
            SE: "Förbereder objekt från Maskininlärning för nedladdning",
          },
        },
        // Upload
        Upload: {
          EN: "Upload",
          SE: "Uppladdning",
          Title: {
            EN: "Upload image objects",
            SE: "Ladda upp bildobjekt",
          },
          Description: {
            EN: "Upload a JSON file with image objects.",
            SE: "Ladda upp en JSON fil med bildobjekt.",
          },
          SelectUser: {
            EN: "Select user",
            SE: "Välj användare",
            FromFile: {
              EN: "User from file",
              SE: "Användare från fil",
            },
            Human: {
              EN: "Human Input - Arkion Demo",
              SE: "Människa - Arkion Demo",
            },
            Vattenfall: {
              EN: "Vattenfall human user import",
              SE: "Vattenfall människa import",
            },
          },
          Upload: {
            EN: "Upload",
            SE: "Ladda upp",
          },
          ChooseFile: {
            EN: "Choose File",
            SE: "Välj Fil",
          },
        },
      },
      ImageUpload: {
        EN: "Image Upload",
        SE: "Bilduppladdning",
        Tooltip: {
          EN: "Import images to this project",
          SE: "Importera bilder till detta projekt",
        },
        Images: {
          EN: "Images",
          SE: "Bilder",
        },
        Upload: {
          EN: "Upload",
          SE: "Ladda upp",
          Tooltip: {
            EN: "Upload images to cloud provider",
            SE: "Ladda upp bilder till molnleverantör",
          },
          InfoText: {
            EN: "The selected folder for uploads should be the date when the images were taken. It should not be the date of the upload.",
            SE: "Den valda mappen för uppladdning borde vara datumet bilden blev tagen. Det borde inte vara datumet för uppladdning.",
          },
          DragAndDrop: {
            EN: "Drag and drop files and folders here or click below to select folders or images.",
            SE: "Släpp flera mappar och filer här, eller klicka nedanför för att välja en mapp eller bilder.",
          },
          DropHere: {
            EN: "Drop the folder here",
            SE: "Släpp mappen här",
          },
          StartAutomatically: {
            EN: "Start import automatically after upload",
            SE: "Starta import automatiskt efter uppladdning",
          },
          OfflineStatus: {
            EN: "You are offline",
            SE: "Du är offline",
          },
          ContinueUpload: {
            EN: "Continue Upload",
            SE: "Fortsätt uppladdning",
          },
          ImportStarted: {
            EN: "Import started",
            SE: "Import påbörjad",
          },
          UploadFinished: {
            EN: "Upload finished",
            SE: "Uppladdning klar",
          },
          NewUpload: {
            EN: "Reset",
            SE: "Rensa",
          },
          StopUpload: {
            EN: "Stop upload",
            SE: "Stoppa uppladdning",
          },
          Images: {
            EN: "Images",
            SE: "Bilder",
          },
          SaveInNewFolder: {
            EN: "Save in new folder",
            SE: "Spara i ny mapp",
          },
          SaveInExistingFolder: {
            EN: "Save in existing folder",
            SE: "Spara i befintlig mapp",
          },
          NoFoldersFound: {
            EN: "No folders found",
            SE: "Inga mappar hittades",
          },
        },
        Import: {
          EN: "Import",
          SE: "Importera",
          Tooltip: {
            EN: "Import images from cloud provider to map",
            SE: "Importera bilder from molnleverantör till kartan",
          },
          Home: {
            EN: "Home",
            SE: "Hem",
          },
          FoldersFound: {
            EN: "Folders Found",
            SE: "Mappar hittade",
          },
          Rerun: {
            EN: "Rerun",
            SE: "Kör igen",
            Tooltip: {
              EN: "Ignore import.json",
              SE: "Ignorera import.json",
            },
          },
          Yolo: {
            EN: "Enable queue",
            SE: "Aktivera kö",
            Tooltip: {
              EN: "Start YOLO after import",
              SE: "Starta YOLO efter import",
            },
          },
          OverwriteDB: {
            EN: "Overwrite DB",
            SE: "Skriv över DB",
            Tooltip: {
              EN: "overwrites 'is_thermal', 'lat', 'lng', 'compass_dir', 'location' and 'flight_id'",
              SE: "Skriver över 'is_thermal', 'lat', 'lng', 'compass_dir', 'location' och 'flight_id'",
            },
          },
          CreateJob: {
            EN: "New job",
            SE: "Skapa nytt jobb",
          },
          Images: {
            EN: "Images",
            SE: "Bilder",
          },
          Created: {
            EN: "Created",
            SE: "Skapat",
          },
          Folder: {
            EN: "Folder",
            SE: "Mapp",
          },
          Status: {
            EN: "Status",
            SE: "Status",
          },
          Skipped: {
            EN: "Skipped",
            SE: "Skippade",
          },
          Logs: {
            EN: "Logs",
            SE: "Loggar",
          },
          SeeLogs: {
            EN: "See log",
            SE: "Se loggar",
          },
          Aborted: {
            EN: "Aborted",
            SE: "Avbruten",
          },
          Successful: {
            EN: "Successful",
            SE: "Lyckades",
          },
          Running: {
            EN: "Running",
            SE: "Pågående",
          },
          PartiallySuccessful: {
            EN: "Partially Successful",
            SE: "Lyckades delvis",
          },
          Job: {
            EN: "Job",
            SE: "Jobb",
          },
          Finished: {
            EN: "finished",
            SE: "klart",
          },
          CloudDuplicate: {
            EN: "Cloud Duplicates",
            SE: "Dubletter i moln",
          },
          DatabaseDuplicate: {
            EN: "Database Duplicates",
            SE: "Dubletter i database",
          },
        },
      },
      MapTool: {
        EN: "Map Tool",
        SE: "Kartverktyg",
        Disabled: {
          EN: "Disabled",
          SE: "Inaktiverad",
        },
        AddPowerline: {
          EN: "Add powerline",
          SE: "Skapa ledning",
        },
        AddZone: {
          EN: "Add zone",
          SE: "Skapa zon",
        },
      },
      // Powerlines
      Powerlines: {
        EN: "Powerlines",
        SE: "Ledningar",
        Tooltip: {
          EN: "Powerline tools",
          SE: "Ledningsverktyg",
        },
        ClickOnATabAbove: {
          EN: "Click on a tab above",
          SE: "Klicka på en flik ovanför",
        },
        Poles: {
          EN: "Poles",
          SE: "Stolpar",
          Title: {
            EN: "Manage Poles",
            SE: "Hantera Stolpar",
          },
          Description: {
            EN: "Assign images to poles, or generate poles from clusters",
            SE: "Tilldela bilder till stolpar, eller generera stolpar från kluster.",
          },
          PoleGenerator: {
            EN: "Pole Generator",
            SE: "Stolp Generator",
          },
          AssignImagesToExistingPoles: {
            EN: "Assign Images To existing Poles",
            SE: "Tilldela bilder till nuvarande stolpar",
          },
        },
        Zones: {
          EN: "Zones",
          SE: "Zoner",
          Menu: {
            Lidar: {
              EN: "Open in LIDAR View",
              SE: "Öppna i LIDAR visaren",
            },
            Link: {
              EN: "Copy Zone Link",
              SE: "Kopiera Zon-länken",
            },
          },
        },
        Bluify: {
          EN: "Bluify",
          SE: "Blåifiera",
          Title: {
            EN: "Bluify powerlines",
            SE: "Blåifiera ledningar",
          },
          Description: {
            EN: "All powerlines with images close will turn blue.",
            SE: "Alla ledningar på kartan med bilder i närheten kommer bli blåa.",
          },
          Button: {
            EN: "Bluify powerlines",
            SE: "Blåifiera ledningar",
          },
          MakeBlue: {
            EN: "Making powerlines blue...",
            SE: "Gör ledningar blåa...",
          },
          MakeBlueCompleted: {
            EN: "Powerlines are now blue. Reload page to see changes",
            SE: "Ledningar är nu blåa. Ladda om för att se ändringarna",
          },
        },
        Divide: {
          EN: "Divide",
          SE: "Dela upp",
          Title: {
            EN: "Divide up powerlines",
            SE: "Dela upp ledningar",
          },
          Description: {
            EN: "Divide all powerlines longer than 200m into many smaller powerlines",
            SE: "Dela upp alla ledningar längre än 200m till flera mindre ledningar.",
          },
          Button: {
            EN: "Divide powerlines",
            SE: "Dela upp ledningar",
          },
          Dividing: {
            EN: "Dividing powerlines job started",
            SE: "Uppdelning av ledningar har startat",
          },
          DividingComplete: {
            EN: "Division is completed. Please reload page",
            SE: "Uppdelning av ledningar är klart.",
          },
        },
        Upload: {
          EN: "Upload",
          SE: "Uppladdning",
          Title: {
            EN: "Upload powerline",
            SE: "Ladda upp ledning",
          },
          Description: {
            EN: "Upload powerline data and load the powerlines into the current map.",
            SE: "Ladda upp data för ledningar och ladda in i nuvarande kartan.",
          },
          Button: {
            EN: "Upload powerline",
            SE: "Ladda upp ledningar",
          },
          ChooseFile: {
            EN: "Choose File",
            SE: "Välj Fil",
          },
          UploadSucceeded: {
            EN: "Upload succeded. Reload page to see changes.",
            SE: "Uppladdning lyckades. Ladda om sida för att se ändringar.",
          },
          Uploading: {
            EN: "Uploading File",
            SE: "Laddar upp fil",
          },
          Disclaimer: {
            EN: "We currently have support for KML and Shape files (zip)",
            SE: "Vi stödjer för KML och Shapefiler (zip)",
          },
          TooLarge: {
            EN: "is too large, please pick a smaller file",
            SE: "är för stor, var god välj en mindre fil",
          },
        },
        Download: {
          EN: "Download KML",
          SE: "Ladda ned KML",
          Title: {
            EN: "Download KML",
            SE: "Ladda ned KML",
          },
          Description: {
            EN: "Download all powerlines as a .kml file.",
            SE: "Ladda ned alla ledningar som en .kml fil.",
          },
          Button: {
            EN: "Download KML",
            SE: "Ladda ned KML",
          },
          ButtonReflight: {
            EN: "Download Reflight KML",
            SE: "Ladda ned KML för omflygning",
          },
        },
      },
      // User Admin
      UserAdmin: {
        EN: "User admin",
        SE: "Användaradmin",
        Tooltip: {
          EN: "Add users and edit permissions",
          SE: "Lägg till användare och justera behörigheter",
        },
        GroupName: {
          EN: "Customer Name",
          SE: "Kundnamn",
        },
        AssignedUsers: {
          EN: "Assigned users",
          SE: "Användare tilldelade",
        },
        AssignedMissions: {
          EN: "Assigned projects",
          SE: "projekt tilldelade",
        },
        UserPermissions: {
          EN: "User permissions",
          SE: "Användarbehörigheter",
        },
        CloudPostfix: {
          EN: "Cloud Postfix",
          SE: "Molntjänst postfix",
        },
        SkyqraftInspectors: {
          EN: "Arkion Inspectors",
          SE: "Arkion Inspektörer",
        },
        NameTooShort: {
          EN: "Name too short",
          SE: "Namnet är för kort",
        },
        AddGroup: {
          EN: "Add customer",
          SE: "Skapa kund",
        },
        AddUser: {
          EN: "Add user",
          SE: "Skapa användare",
        },
        Users: {
          EN: "Users",
          SE: "Användare",
        },
        Missions: {
          EN: "Projects",
          SE: "projekt",
        },
        Permissions: {
          EN: "Permissions",
          SE: "Behörigheter",
        },
        Password: {
          EN: "Password",
          SE: "Lösenord",
        },
        TemporaryPassword: {
          EN: "Temporary Password",
          SE: "Temporärt Lösenord",
        },
        AddUserFailed: {
          EN: "Invite failed",
          SE: "Inbjudan misslyckades",
        },
        AddUserSuccess: {
          EN: "Invite sent",
          SE: "Inbjudan skickad",
        },
        AddGroupFailed: {
          EN: "Adding customer failed",
          SE: "Skapa kund misslyckades",
        },
        AddGroupSuccess: {
          EN: "Adding customer succeeded",
          SE: "Skapa kund lyckades",
        },
        Saving: {
          EN: "Saving settings",
          SE: "Sparar inställningar",
        },
        Saved: {
          EN: "Saved settings",
          SE: "Inställningar sparade",
        },
        PartialSave: {
          EN: "Update partially failed.",
          SE: "Inställningar sparades delvis",
        },
      },
      // Tutorials
      Tutorials: {
        EN: "Tutorials",
        SE: "Handledningar",
        Tooltip: {
          EN: "Edit tutorial for users",
          SE: "Ändra handledningar för användare",
        },
        Undo: {
          EN: "Undo Changes",
          SE: "Ångra ändringar",
        },
        Save: {
          EN: "Save",
          SE: "Spara",
        },
        Close: {
          EN: "Close",
          SE: "Stäng",
        },
        AddNew: {
          EN: "Add new item",
          SE: "Lägg till nytt steg",
        },
      },
      ObjectManager: {
        EN: "Object manager",
        SE: "Objekthanteraren",
        Tooltip: {
          EN: "Edit object types and categories",
          SE: "Ändra objekttyper och kategorier",
        },
      },
      Broadcast: {
        EN: "Broadcast message",
        SE: "Sänd meddelande",
        Warning: {
          EN: "This will be broadcasted to all active users. Are you sure you want to do this?",
          SE: "Detta meddelande kommer skickas till alla användare. Är du säker att du vill göra detta?",
        },
      },
    },
    // Statistics
    Statistics: {
      EN: "Statistics",
      SE: "Statistik",
      Tooltip: {
        EN: "See some stats of the current project",
        SE: "Se statistik för nuvarande projekt",
      },
      nImages: {
        EN: "Image count",
        SE: "Antal bilder",
      },
      lidarLength: {
        EN: "Grid with lidar",
        SE: "Nät med lidar",
      },
      nPoles: {
        EN: "Pole count",
        SE: "Antal stolpar",
      },
      nSupervisorReviewImages: {
        EN: "Images to superannotate",
        SE: "Bilder att superannotera",
      },
      nMachineReviewImages: {
        EN: "Images to review",
        SE: "Bilder att annotera",
      },
      nFlaggedImages: {
        EN: "Flagged images",
        SE: "Flaggade bilder",
      },
      // Powergrid
      Powergrid: {
        EN: "Powerline",
        SE: "Kraftnät",
        Length: {
          EN: "Grid length",
          SE: "Nätlängd",
        },
        Images: {
          EN: "Grid with images",
          SE: "Nät med bilder",
        },
        Inspected: {
          EN: "Analyzed",
          SE: "Analyserat",
        },
        Reflight: {
          EN: "Reflight needed",
          SE: "Omflygning krävs",
        },
        Helicopter: {
          EN: "Helicopter emissions",
          SE: "Helikopter utsläpp",
        },
        Drone: {
          EN: "Drone emissions",
          SE: "Drönare utsläpp",
        },
        CO2: {
          EN: "CO2 saved",
          SE: "Koldioxid sparad",
        },
      },
      MachineLearning: {
        EN: "Machine Learning",
        SE: "Maskininlärning",
        WorkflowStatus: {
          EN: "Workflow Status",
          SE: "Arbetsflöde Status",
        },
        ShowWithInventory: {
          EN: "Show With objects",
          SE: "Visa med objekt",
        },
        DetectionCategories: {
          EN: "Detection Categories",
          SE: "Objekt Kategorier",
        },
        IssueCategories: {
          EN: "Issue Categories",
          SE: "Skada Kategorier",
        },
        FixedDefects: {
          EN: "Fixed Defects",
          SE: "Reparerade Objekt",
        },
        NotFixed: {
          EN: "Not Fixed",
          SE: "Ej Reparerat",
        },
        Fixed: {
          EN: "Fixed",
          SE: "Reparerat",
        },
        Detections: {
          EN: "All Detections",
          SE: "Alla Objekt",
        },
        Defect: {
          EN: "Defect",
          SE: "Skador",
        },
        Inventory: {
          EN: "Objects",
          SE: "Objekt",
        },
        Human: {
          EN: "Human",
          SE: "Människa",
        },
        Machine: {
          EN: "Machine",
          SE: "Maskin",
        },
        Updated: {
          EN: "last updated",
          SE: "senast uppdaterad",
        },
        Accuracy: {
          EN: "Accuracy",
          SE: "Nogrannhet",
        },
        Precision: {
          EN: "Precision",
          SE: "Precision",
        },
        Recall: {
          EN: "Recall",
          SE: "Återkallelse",
        },
        WorkReduction: {
          EN: "Work reduction",
          SE: "Arbetsminskning",
        },
        Positive: {
          EN: "Positive",
          SE: "Positiv",
        },
        Negative: {
          EN: "Negative",
          SE: "Negativ",
        },
        True: {
          EN: "True",
          SE: "Sann",
        },
        False: {
          EN: "False",
          SE: "Falsk",
        },
        MachineDetectionCategories: {
          EN: "Machine Detection Categories",
          SE: "Maskin Detekterings kategorier",
        },
      },
    },
    // Settings
    Settings: {
      EN: "Settings",
      SE: "Inställningar",
      Tooltip: {
        EN: "Edit settings for a more customised experience",
        SE: "Justera inställningar för en mer anpassad upplevelse",
      },
      ZoomSpeed: {
        EN: "Zoom speed for images",
        SE: "Zoom-hastighet för bilder",
      },
      ClusterDistance: {
        EN: "Proximity radius",
        SE: "Cirkelns radie",
      },
      RoleOverride: {
        EN: "Role Override",
        SE: "Rollöverskridning",
      },
      ClusterMethod: {
        EN: "Thumbnail method",
        SE: "Ikon-logik",
      },
      ThumbnailSorting: {
        EN: "Thumbnail sorting",
        SE: "Ikon-sortering",
      },
      ShowDatesBeforeProjectName: {
        EN: "Show dates before project name",
        SE: "Visa datum före projektnamn",
      },
      Proximity: {
        EN: "Proximity",
        SE: "Närliggande",
      },
      Cluster: {
        EN: "Show thumbnail images",
        SE: "Visa ikon-bilder",
      },
      Language: {
        EN: "Language",
        SE: "Språk",
      },
      ClientNaming: {
        EN: "Client Naming",
        SE: "Klient Namngivning",
      },
      ClientSettings: {
        EN: "Client Settings",
        SE: "Klient Inställningar",
      },
      CustomizeDefectTypes: {
        EN: "Customize defect types",
        SE: "Anpassa skadekategorier",
      },
      EditClientIssueSeverities: {
        EN: "Edit client issue severities",
        SE: "Redigera klient Anmärknings allvarlighetsgrad",
      },
      NamingConvention: {
        EN: "Naming convention for objects, defects and severity levels",
        SE: "Namngivningstyp för objekt, defekter och allvarlighetsgrader",
      },
      Map: {
        EN: "Map",
        SE: "Karta",
      },
      InspectorMode: {
        EN: "Inspector mode",
        SE: "Inspektörsläge",
        Tooltip: {
          EN: "Mark analyzed powerlines green/yellow/blue using inspector mode. Use right mouse button and mark an area.",
          SE: "Markera analyserade ledningar i inspektörsläge. Använd högerklick för att markera ett område",
        },
      },
      ExtraInfo: {
        EN: "Show extra info",
        SE: "Visa extra information",
        Tooltip: {
          EN: "Show more info in the infobox to the right",
          SE: "Visa mer information i lådan till höger",
        },
      },
      DemonstrationMode: {
        EN: "Demonstration mode",
        SE: "Demonstrationsläge",
        Tooltip: {
          EN: "Enter demonstration mode for sales and demos",
          SE: "Aktivera demonstrationsläge för sälj och demo",
        },
      },
      Algorithm: {
        EN: "Algorithm",
        SE: "Algoritm",
      },
    },
    Account: {
      EN: "Account",
      SE: "Konto",
      Save: {
        EN: "Save",
        SE: "Spara",
      },
      Cancel: {
        EN: "Cancel",
        SE: "Avbryt",
      },
      NotSet: {
        EN: "Not set",
        SE: "Inte inställt",
      },
    },
    Logout: {
      EN: "Log out",
      SE: "Logga ut",
    },
  },
  MissionUpload: {
    SelectMission: {
      EN: "Project Name",
      SE: "Projekts Namn",
    },
    UploadPowerline: {
      EN: "Upload Powerline",
      SE: "Ladda up ledning",
    },
    MarkOrderType: {
      EN: "Mark order type",
      SE: "Markera order typ",
    },
    Back: {
      EN: "Back",
      SE: "Bakåt",
    },
    Next: {
      EN: "Next",
      SE: "Nästa",
    },
    Finish: {
      EN: "Finish",
      SE: "Klar",
    },
    ClickNextToEdit: {
      EN: "Create a new Project by adding a project name.",
      SE: "Skapa ett nytt projekt genom att skriva in ett projektsnamn nedan.",
    },
    IfCreatingNewMission: {
      EN: "Local Area + Purpose",
      SE: "Område + Syfte",
    },
    CreateNewMission: {
      EN: "New project",
      SE: "Nytt projekt",
    },
    RightClickAndDrag: {
      EN: "Right click and drag to mark type of order on powerline. Click finish when done.",
      SE: "Högerklicka och dra för att markera ordertyp på ledningen. Klicka på klar när du är klar",
    },
    MissionName: {
      EN: "Project name",
      SE: "Projektets namn",
    },
    UploadSuccessful: {
      EN: "Upload Successful",
      SE: "Uppladning lyckades",
    },
    SavingPowerline: {
      EN: "Saving Powerline",
      SE: "Sparar ledning",
    },
    ProcessingFile: {
      EN: "Processing File",
      SE: "Bearbetar Filen",
    },
    PowerlineIsSaved: {
      EN: "Powerline is saved",
      SE: "Ledningen har sparats",
    },
    ProcessingCompleted: {
      EN: "Processing Completed",
      SE: "Bearbetningen Klar",
    },
    FailedSavingPowerline: {
      EN: "Failed saving powerline",
      SE: "Misslyckades med att spara ledningen",
    },
    ProcessingFailed: {
      EN: "Processing Failed",
      SE: "Bearbetningen Misslyckad",
    },
  },
  ListView: {
    EN: "List View",
    SE: "List Vy",
    ActivateFilter: {
      EN: "Select a filter to activate list view toggle",
      SE: "Välj ett filter för att aktivera listvy knappen",
    },
    NumberOfProcessedDefects: {
      EN: "Number of processed defects",
      SE: "Antal bearbetade defekter",
    },
    NumberOfDefects: {
      EN: "Number of defects",
      SE: "Antal defekter",
    },
    CollapseAll: {
      EN: "Collapse all",
      SE: "Fäll ihop alla",
    },
    ExpandAll: {
      EN: "Expand all",
      SE: "Fäll ut alla",
    },
    GoToNextImage: {
      EN: "Go to next image",
      SE: "Gå till nästa bild",
    },
    GoToPreviousImage: {
      EN: "Go to previous image",
      SE: "Gå till föregående bild",
    },
  },
  MapInfo: {
    EN: "Color guide",
    SE: "Färgguide",
    ShowImage: {
      EN: "Show image",
      SE: "Visa bild",
    },
    Glossary: {
      EN: "Glossary",
      SE: "Ordlista",
      Detection: {
        EN: "Detection",
        SE: "Detektion",
        Explanation: {
          EN: "Detections are all things marked on images. They can be either Objects, Defects or Remarks",
          SE: "Detektioner är alla saker som markeras på bilder. De kan vara antingen Objekt, Skador eller Upplysningar",
        },
      },
      Object: {
        EN: "Object",
        SE: "Objekt",
        Explanation: {
          EN: "Things found on images, for example a pole or an insulator",
          SE: "Saker som hittats på bilder, exempelvis en stolpe eller en isolator",
        },
      },
      Defect: {
        EN: "Defect",
        SE: "Skada",
        Explanation: {
          EN: "Something found on an image that is a critical defect or an issue, for example a tree on wire or missing insulator",
          SE: "Någonting som hittats på en bild som är en Kritisk Skada eller Anmärkning, till exempel träd på lina eller en isolator som saknas",
        },
      },
      Remark: {
        EN: "Remark",
        SE: "Upplysning",
        Explanation: {
          EN: "Something found on an image that is noteworthy. For example a loose wire tie or a slightly leaning wooden pole",
          SE: "Någonting som hittats på en bild som är en intressant upplysning. Till exempel en lös najning eller en stolpe som lutar något",
        },
      },
    },
    WhenNoFiltersAreApplied: {
      EN: "When no filters are applied",
      SE: "När inget filter är aktivt",
      NoDetections: {
        EN: "Image that has no detections",
        SE: "Bild utan några detektioner",
      },
      FewerThanSix: {
        EN: "Image that has fewer than 6 detections",
        SE: "Bild med färre än 6 detektioner",
      },
      MoreThanSix: {
        EN: "Image that has 6 or more detections",
        SE: "Bild som har 6 eller fler detektioner",
      },
      Thermal: {
        EN: "Thermal image",
        SE: "Värmebild",
      },
      ImageWithDefect: {
        EN: "Image with defect(s)",
        SE: "Bild med skador",
      },
    },
    WhenTheFilterForRemarksIsApplied: {
      EN: "When the filter for 'Remarks' is applied",
      SE: "När filtret för 'Upplysningar' är aktivt",
      Explanation: {
        EN: "All colours will be the same when filters are applied, except when the filter for 'Remarks' has been applied. 'Remarks' will. after filtering for them, show up in a soft red that is easily distinguished from the more severe red colour used for 'defects'",
        SE: "Alla färger förblir desamma med filter, förutom när filtret för 'Upplysningar' används. 'Upplysningar' kommer, vid filtrering, få en mjukt röd färg som är enkel att skilja från den mer skarpa röda färgen som används för 'skador'",
      },
      Remark: {
        EN: "Remark(s)",
        SE: "Upplysning(ar)",
      },
    },
    ColorGuidePowerlines: {
      EN: "Powerlines",
      SE: "Ledningar",
      HasNoImages: {
        EN: "Has no images",
        SE: "Har inga bilder",
      },
      IsBuried: {
        EN: "Is buried",
        SE: "Är nedgrävd",
      },
      HasImages: {
        EN: "Has images",
        SE: "Har bilder",
      },
      NeedsReflight: {
        EN: "Needs Reflight",
        SE: "Behöver omflygning",
      },
      HasImagesAnalysisDone: {
        EN: "Has images, analysis done",
        SE: "Har bilder, analys klar",
      },
    },
    ColorGuidePowerlinesInspectorMode: {
      EN: "Powerlines - Inspector mode",
      SE: "Powerlines - Inspector mode",
      HasNoImages: {
        EN: "Has no images",
        SE: "Har inga bilder",
      },
      IsBuried: {
        EN: "Is buried",
        SE: "Är nedgrävd",
      },
      HasImages: {
        EN: "Has images",
        SE: "Har bilder",
      },
      NeedsReflight: {
        EN: "Needs Reflight",
        SE: "Behöver omflygning",
      },
      SuperUserAnalysed: {
        EN: "Superuser analysed",
        SE: "Superuser analysed",
      },
      SupervisorAnalysed: {
        EN: "Supervisor analysed",
        SE: "Supervisor analysed",
      },
      AnnotatorAnalysed: {
        EN: "Annotator analysed",
        SE: "Annotator analysed",
      },
      CriticalAnalysisDone: {
        EN: "Critical analysis done",
        SE: "Kritisk analysis klar",
      },
    },
  },
  ClearanceAnnotation: {
    Markers: {
      EN: "Clearance markers",
      SE: "Regelöverträdelser",
    },
    SingleViolation: {
      EN: "clearance violation",
      SE: "regelöverträdelse",
    },
    MultipleViolation: {
      EN: "clearance violations",
      SE: "regelöverträdelser",
    },
  },
  MissionLanding: {
    Description: {
      EN: "You can find quick access to valuable information about your project on this page.",
      SE: "Här har du snabb tillgång till värdefull information angående detta projekt.",
    },
    CreatingNewMission: {
      EN: "You are in the process of creating a new project",
      SE: "Du håller på att skapa ett nytt projekt",
    },
    UserProgress: {
      EN: "User progress",
      SE: "Användares status",
      Description: {
        EN: "See all assignments for inspectors on this project",
        SE: "Se alla tilldelningar för inspektörer på detta projekt",
      },
    },
    ProjectId: {
      EN: "Project ID",
      SE: "Projekt ID",
    },
    MyAssignments: {
      EN: "My assignments",
      SE: "Mina tilldelningar",
      Description: {
        EN: "See all assignments given to me",
        SE: "Se alla tilldelningar till mig",
      },
    },
    UploadImages: {
      EN: "Upload images",
      SE: "Ladda upp bilder",
    },
    OrderProgress: {
      EN: "Project overview",
      SE: "Överblick av projektet",
      YouWillBeNotified: {
        EN: "You will be notified by email whenever there is a status update.",
        SE: "Du kommer notifieras via email när projektets status uppdateras.",
      },
      PlanningPhase: {
        EN: "Planning Phase",
        SE: "Planering",
        EmailList: {
          EN: "Critical Defect Email List",
          SE: "Kritiska skador mail-lista",
          Description: {
            EN: "Arkion reports critical defect as soon as they are found during the analysis. Add the users who will get an email regarding critical defects found during the analysis of images in this project.",
            SE: "Arkion rapporterar kritiska skador via email direkt när de upptäcks under analys. Lägg till de användare som ska få email för kritiska skador som upptäcks vid inspektion av bilder i detta projekt.",
          },
          Footnote: {
            EN: "If you do not add any other people on the email list, you are the only one we will email regarding critical defects/skador found during the analysis. The users you add need to have an active Arkion account and belong to the same access group as yourself. If you are unable to find a person you want in the email list, please contact your Arkion contact and we will help you.",
            SE: "Om du inte lägger till några andra personer på e-postlistan är du den enda vi mailar angående kritiska defekter/skador som upptäcks under analysen. Användarna du lägger till behöver ha ett aktivt Arkion-konto och tillhöra samma åtkomstgrupp som du själv. Om du inte kan hitta en person du vill ha i e-postlistan, vänligen kontakta din Arkion-kontakt så hjälper vi dig.",
          },
        },
        UploaderList: {
          EN: "Uploader list",
          SE: "Uppladdarlista",
          Description: {
            EN: "Add the users who will be uploading images to the project",
            SE: "Lägg till användare som skall ladda upp bilder på projektet",
          },
          Footnote: {
            EN: "You can change this at a later point if needed. The users needs to exist on our platform, and you should must share a project or customer. If you are unable to find a person you want in the uploader list, please contact your Arkion contact.",
            SE: "Du kan ändra denna lista senare. Endast användare som finns på vår plattform dyker upp, och ni måste dela på ett projekt eller en kund. Om du inte kan hitta en person du vill ha med, kontakt din Arkion kontakt",
          },
        },
        InvoiceReference: {
          EN: "Invoice reference",
          SE: "Fakturareferens / beställningsnummer",
          Description: {
            EN: "Enter the invoice reference in your organization that Arkion should label the invoice with.",
            SE: "Ange den fakturareferens / beställningsnummer som din organisation önskar att Arkion märker fakturan med.",
          },
          Footnote: {
            EN: "According to the orderer's (customer's) internal instructions for marking invoices. If you do not specify an invoice reference in connection with the order, we would like to receive this at the latest with the final report of the assignment.",
            SE: "Enligt beställarens (kunds) interna anvisningar för märkning av faktura. Om du ej anger fakturareferens i samband med beställning önskar vi motta denna senast vid slutrapporten av projektet.",
          },
        },
        PlanningDone: {
          EN: "Planning done",
          SE: "Planering slutfört",
        },
        Button: {
          EN: "Submit",
          SE: "Skicka",
        },
      },
      FlyingPhase: {
        EN: "Flying",
        SE: "Flygning",
        WhenComplete: {
          EN: "When flying is complete, please press this button, and the analysis phase will start",
          SE: "När flygningen är klar, klicka på knappen så kommer analysfasen påbörjas",
        },
        Button: {
          EN: "Mark flying phase as complete",
          SE: "Markera flygning som klar",
        },
        TakesPlace: {
          EN: "Flight takes place on",
          SE: "Flygningen sker",
        },
      },
      FinalReport: {
        EN: "Final report",
        SE: "Slutrapport",
        HasBeenDelivered: {
          EN: "has been delivered",
          SE: "har levererats",
        },
        WillBeDelivered: {
          EN: "will be delivered",
          SE: "kommer levereras",
        },
        Description: {
          EN: "When the final report is done, the project is classified as completed.",
          SE: "När slutrapporten är klar klassificeras projektet som slutfört.",
        },
        Button: {
          EN: "Mark final report as done",
          SE: "Markera slutrapport som klar",
        },
      },
      OrderRecieved: {
        EN: "Confirm order",
        SE: "Godkännande av beställning",
        OrderPlaced: {
          EN: "This order was placed on",
          SE: "Denna order placerades den",
        },
        OrderAccepted: {
          EN: "Order accepted on",
          SE: "Beställningen godkändes",
        },
      },
      OrderMission: {
        Year: {
          Footnote: {
            EN: "The year where the project will take place.",
            SE: "Året projektet utförs.",
          },
        },
        RequiredFields: {
          EN: "Required fields",
          SE: "Obligatoriska fält",
        },
        Comment: {
          Footnote: {
            EN: "Please add a comment to Arkion if you have any questions or if there is something we should know.",
            SE: "Ange gärna en kommentar till Arkion om du har några frågor eller om det är någonting annat vi bör veta.",
          },
        },
        MissionName: {
          FootNote: {
            EN: "Please refer from using customer name, region name, year/date or project type in the project name. This is already saved.",
            SE: "Vänligen använd ej kundnamn, region, årtal/datum eller projektstyp i namnet. De fälten sparas redan separat.",
          },
        },
        Analysis: {
          Description: {
            EN: "Choose the type of analysis you would like Arkion to perform on the data gathered during the flight",
            SE: "Välj den typ av analys du vill att Arkion skall genomföra på datan insamlad från flygningen",
          },
          ImageAnalysis: {
            EN: "Image Analysis",
            SE: "Bildanalys",
            Tooltip: {
              EN: "Normal images will be analysed. Object detections and defect detections will be marked on these images.",
              SE: "Vanliga bilder kommer analyseras. Objektdetektering och identifiering av skador kommer markeras på bilder.",
            },
          },
          ThermalAnalysis: {
            EN: "Thermal Image Analysis",
            SE: "Värmeanalys",
            Tooltip: {
              EN: "Thermal images will be analysed for hot spots. Requires thermal images.",
              SE: "Värmebilder kommer analyseras för att hitta värmgångar. Kräver att värmebilderna laddas upp.",
            },
          },
          LidarAnalysis: {
            EN: "3D analysis",
            SE: "3D Analys",
            Tooltip: {
              EN: "3D (point cloud data) will be processed and objects that are too close to the wires will be marked with a red color in the 3D map.",
              SE: "3D (punktmolnsdata) kommer analyseras och objekt som är för nära ledningarna kommer markeras och rapporteras.",
            },
          },
          CorrosionAnalysis: {
            EN: "Corrosion analysis",
            SE: "Rostanalys",
            Tooltip: {
              EN: "Based on images, the asset health of lattice towers will be analyzed and reported. A downloadable report where each pole has it's own row is included.",
              SE: "Utifrån bilder rapporterar Arkion hur mycket visuall rost (corrosion) som finns på fackverksstolpar. En nedladdningsbar rapport med en rad per stolpe blir tillgänglig.",
            },
          },
          Footnote: {
            EN: "If you are unsure or would like to add/change this later, please discuss with your Arkion contact",
            SE: "Om du är osäker eller vill ändra detta vid ett senare skede, kontakta din kontakt hos Arkion.",
          },
        },
        DroneOperator: {
          Description: {
            EN: "Provide the name of the drone partner that will perform the flight and upload the data to Arkion",
            SE: "Ange namnet på drönarpartner som kommer flyga och ladda upp datan till Arkion",
          },
          Footnote: {
            EN: "Text field, for Arkion to know who will be the data collector. You can change supplier later if neccesary. Make sure to communicate any change in drone operator to Arkion.",
            SE: "Fritextfält så att Arkion förstår vilken leverantör som kommer leverera sensordata. Du kan ändra leverantör senare. Vänligen kommunicera byte till Arkion",
          },
        },
        Region: {
          Description: {
            EN: "What region does the new project belong to?",
            SE: "Vilken region tillhör projektet?",
          },
          NoRegions: {
            EN: "There are no regions available. You can still create an order without a region.",
            SE: "Det finns inga regioner tillgängliga. Du kan fortfarande skicka en order utan en region",
          },
          Footnote: {
            EN: "The region is associated to the geographical location of the grid. All regions for your grid should be listed here. If a region is project, contact Arkion.",
            SE: "Regionen associeras till den geografiska positionen av nätet. Alla regioner för ert nät ska ligga här i listan. Saknar ni någon region kontakta Arkion.",
          },
          NoAccess: {
            EN: "You do not have access to any regions",
            SE: "Du har inte tillgång till några regioner",
          },
        },
        DeleteDraft: {
          EN: "Are you sure you want to delete this draft?",
          SE: "Är du säker att du vill radera utkastet?",
        },
        OrderPlaced: {
          EN: "Order placed on",
          SE: "Order placerades",
        },
        MissionType: {
          EN: "Project type",
          SE: "Projektstyp",
          Description: {
            EN: "Please select the type of project you want to order",
            SE: "Välj vilken typ av projekt vill du beställa",
          },
          Maintenance: {
            EN: "Maintenance",
            SE: "Driftbesiktning",
            Tooltip: {
              EN: "A maintenance project is where you fly over your grid on a regular basis to spot potential issues. If you only want to see the status of your grid, pick this option.",
              SE: "En driftbesiktning är när du flyger över ditt nät minst 1 ggr per år för att hitta potentiella skador. Om du vill se statusen för alla skador för ditt nät, välj detta alternativ.",
            },
          },
          Troubleshoot: {
            EN: "Troubleshoot",
            SE: "Felsökning",
            Tooltip: {
              EN: "A troubleshooting project is where you know there is something wrong with a specific stretch of grid, and you want to identify the issues. This is best coupled with extra analysis like thermal and lidar.",
              SE: "Ett felsökningsprojekt är när du vet att någonting är fel med nätet, och du vill identifiera grundorsaken. Detta är bäst kombinerat med extra analys av värmebilder och 3D lidar-data-analys.",
            },
          },
          Trial: {
            EN: "Trial / POC",
            SE: "Test / POC",
            Tooltip: {
              EN: "If you want to test and see how our platform behaves, then pick this option.",
              SE: "Om du vill se hur vår plattform fungerar, välj detta alternativet.",
            },
          },
          Storm: {
            EN: "After Storm",
            SE: "Störning / Storm",
            Tooltip: {
              EN: "Inspect a stretch of your grid after a storm. Only analysis of overview images.",
              SE: "Inspektera en sträcka av ert nät efter en extern störning som t.ex. en storm. Analys av endast översiktsbilder för att snabbt hitta stora skador.",
            },
          },
          Projecting: {
            EN: "Project Planning",
            SE: "Projektering",
            Tooltip: {
              EN: "Upload images for a strech that will be built or rebuilt. No analysis by Arkion.",
              SE: "Ladda upp övsiktsbilder på en sträcka som ska byggas eller renoveras. Ingen analys av Arkion.",
            },
          },
          Footnote: {
            EN: "The project type determines how we will look for damages on the grid. Please hover the information icons above to see more details.",
            SE: "Projektstypen bestämmer hur vi analyserar skador på nätet. Du kan hålla musen över informationsikonen för att få mer detaljer.",
          },
        },
        NoGroups: {
          EN: "You do not have access to any customer. Please contact Arkion or your company admin.",
          SE: "Du har inte tillgång till några kunder. Kontakta Arkion",
        },
        GroupDescription: {
          EN: "What customer does the new project belong to?",
          SE: "Vilken kund tillhör projektet?",
        },
        GroupFootnote: {
          EN: "",
          SE: "",
        },
        PowerlineUpload: {
          EN: "Powerline upload",
          SE: "Ladda upp elnät",
          MultiFile: {
            EN: "You can select one or multiple files. You don't need to upload all files now.",
            SE: "Du kan välja en eller flera filer. Du måste inte ladda upp alla filer direkt.",
          },
          Description: {
            EN: "Upload SHP (.zip) or KML file or powerlines",
            SE: "Ladda upp KML-fil eller Shapefil (.zip) som innehåller ledningar",
          },
          HasLength: {
            EN: "has length",
            SE: "har längd",
          },
          Remove: {
            EN: "Remove",
            SE: "Ta bort",
          },
          Upload: {
            EN: "Upload",
            SE: "Ladda upp",
          },
          Highlight: {
            EN: "Highlight",
            SE: "Markera",
          },
          UnHighlight: {
            EN: "Unhighlight",
            SE: "Avmarkera",
          },
          Footnote: {
            EN: "The grid will be vizialized on the map when you upload the file/files.",
            SE: "Nätet kommer visualiseras på kartan när du laddar upp filen/filerna.",
          },
        },
        PreliminaryFlightDate: {
          EN: "Preliminary flight date",
          SE: "Preliminärt flygdatum",
          Description: {
            EN: "Please pick a preliminary date when the flight will take place. If the flight duration spans across multiple days, pick the first day of flying. If you are uploading historical data, please pick a date going back in time to when the sensor data was collected.",
            SE: "Välj ett preliminärt flygdatum. Om flygningen löper över ett flertal dagar, välj den första dagen av flygningen. Om det är historisk data, välj ett datum bakåt i tiden.",
          },
          Footnote: {
            EN: "The preliminary flight date is an estimate of when the flight is planned to start. You can change this date closer to the flight start, as long as it is communicated to Arkion and your drone partner.",
            SE: "Det preliminära flygdatumet är en uppskattning om när flygningen kommer ta plats. Du kan ändra detta datum närmare flygdatumet, så länge det kommuniceras till Arkion och din drönarpartner.",
          },
        },
        Reset: {
          EN: "Reset",
          SE: "Återställ",
        },
        SaveDraft: {
          EN: "Save draft",
          SE: "Spara utkast",
        },
        SendOrder: {
          EN: "Send order",
          SE: "Skicka order",
        },
      },
      AnalysisPhase: {
        EN: "Analysis",
        SE: "Analys",
        AnalysisDone: {
          EN: "The analysis of this project has been completed",
          SE: "Analysen för detta projekt har slutförts.",
        },
        AnalysisPending: {
          EN: "The analysis of this project is yet to be completed",
          SE: "Analysen för detta projekt har inte slutförts ännu",
        },
        ButtonHelpText: {
          EN: "When analysis is complete, please press this button, and the next phase will start",
          SE: "När analysen är klar, klicka på denna knappen för att gå vidare till nästa fas. ",
        },
        ButtonText: {
          EN: "Mark analysis as complete",
          SE: "Markera analys som slutförd",
        },
      },
    },
  },
  Landing: {
    EN: "Landing Page",
    SE: "Landningssida",
    NewDefects: {
      EN: "New defects",
      SE: "Nya defekter",
      MarkAllDefectsAsViewed: {
        EN: "Mark all defects as viewed",
        SE: "Markera alla defekter som sedda",
      },
      RefreshData: {
        EN: "Refresh data",
        SE: "Uppdatera data",
      },
      Description: {
        EN: "This is a list of all new defects that have been found in the project. Only defects you have not seen before are shown here.",
        SE: "Detta är en lista över alla nya defekter som har hittats i projektet. Endast defekter du inte har sett tidigare visas här.",
      },
      TooltipOne: {
        EN: "These numbers are updated every 5 minutes. You can force refresh the data by clicking the refresh button in the options menu.",
        SE: "Dessa nummer uppdateras var 5:e minut. Du kan tvinga en uppdatering genom att klicka på uppdateringsknappen i alternativmenyn.",
      },
      TooltipTwo: {
        EN: "You can manually mark all defects as viewed by clicking the menu and mark all defects as viewed button in the top right corner. This action can also be done on a project level by clicking the options menu.",
        SE: "Du kan manuellt markera alla defekter som sedda genom att klicka på menyn och markera alla defekter som sedda knappen i det övre högra hörnet. Denna åtgärd kan också göras på projektnivå genom att klicka på alternativmenyn.",
      },
    },
    Assignments: {
      EN: "Assignments",
      SE: "Tilldelningar",
      NotAssigned: {
        EN: "Not assigned",
        SE: "Inte tilldelad",
      },
      Description: {
        EN: "See what areas you have been assigned to analyze",
        SE: "Se vilka områden du har blivit tilldelad att analysera.",
      },
      Analysis: {
        EN: "Analysis",
        SE: "Analys",
      },
      CriticalAnalysis: {
        EN: "Critical Analysis",
        SE: "Kritisk Analys",
      },
      AssignmentOverview: {
        EN: "Assignment overview",
        SE: "Översikt tilldelningar",
        Description: {
          EN: "See all assignments that have been given out",
          SE: "Se alla projekt som blivit tilldelade",
        },
      },
      SeeUsersAssignment: {
        EN: "See assignments for all users",
        SE: "Se tilldelningar för alla användare",
      },
      ActiveMissions: {
        EN: "Active projects",
        SE: "Aktiva projekt",
        Description: {
          EN: "See assignments for all active projects",
          SE: "See tilldelningar för alla aktiva projekt",
        },
      },
      InactiveMissions: {
        EN: "Inactive projects",
        SE: "Inaktiva projekt",
        Description: {
          EN: "See assignments for all inactive projects",
          SE: "See tilldelningar för alla inaktiva projekt",
        },
      },
      AssignedImages: {
        EN: "Assigned images",
        SE: "Tilldelade bilder",
      },
      FlaggedImages: {
        EN: "Flagged images",
        SE: "Flaggade bilder",
        Description: {
          EN: "See where you have flagged images",
          SE: "Se vart det finns flaggade bilder",
        },
      },
    },
    ResponsibleAnnotator: {
      EN: "Responsible annotator",
      SE: "Ansvarig annotarere",
    },
    SeeMap: {
      EN: "See map",
      SE: "Se karta",
    },
    SeeMission: {
      EN: "See project",
      SE: "Se projekt",
    },
    Description: {
      EN: "This is your landing page. This will give you an overview of your projects, uploads and more.",
      SE: "Detta är din landningssida. Här kan du se en överblick över dina projekt, uppladdningar och mer.",
    },
    PlannedMissions: {
      EN: "Planned projects",
      SE: "Planerade projekt",
      Description: {
        EN: "Click to see projects that are being planned",
        SE: "Klicka för att se projekt som är planerade",
      },
    },
    OrderedMissions: {
      EN: "Ordered projects",
      SE: "Beställda projekt",
      Description: {
        EN: "Click to see missions that have been ordered",
        SE: "Klicka för att se projekt som har beställts",
      },
    },
    LastUploads: {
      EN: "Previous uploads",
      SE: "Tidigare uppladdningar",
      Description: {
        EN: "See the previous uploads for your projects",
        SE: "Se tidigare uppladdningar för dina projekt",
      },
    },
    PreviousLogins: {
      EN: "My previous logins",
      SE: "Mina tidigare inloggningar",
      Description: {
        EN: "See previous logins on your account",
        SE: "Se tidigare inloggningar på ditt konto",
      },
    },
    DoneMissions: {
      EN: "Completed Projects",
      SE: "Avklarade projekt",
      Description: {
        EN: "Click to see projects that are completed",
        SE: "Klicka för att se vilka projekt som är klara",
      },
    },
    ActiveMissions: {
      EN: "Active Projects",
      SE: "Aktiva projekt",
      Description: {
        EN: "Click to see projects that are active",
        SE: "Klicka för att se aktiva projekt",
      },
    },
    ImageFeedback: {
      EN: "Image feedback",
      SE: "Rapporterade bilder",
      SeeAllImageFeedbacks: {
        EN: "See all image feedbacks",
        SE: "Se alla rapporterade bilder",
      },
      clientId: {
        EN: "Client ID",
        SE: "Klient ID",
      },
      imageId: {
        EN: "Image ID",
        SE: "Bild ID",
      },
      type: {
        EN: "type",
        SE: "typ",
      },
      comment: {
        EN: "comment",
        SE: "kommentar",
      },
      resolved: {
        EN: "resolved",
        SE: "avklarad",
      },
    },
    ClientAlteredTypes: {
      EN: "Client altered severities",
      SE: "Klient ändrade allvarlighetsgrader",
      SeeAllClientAlteredTypes: {
        EN: "See all client altered types",
        SE: "Se alla klient ändrade objekttyper",
      },
      SeeMostRecentAlteredTypes: {
        EN: "See most recent client altered types",
        SE: "Se senaste klient ändrade objekttyper",
      },
      Old: {
        EN: "Old",
        SE: "Före",
      },
      New: {
        EN: "New",
        SE: "Efter",
      },
    },
  },
  DefectReport: {
    EN: "Arkion Defect Report",
    SE: "Arkion Skaderapport",
    FilterExplanation: {
      EN: "Add filters to the report by opening the filter tab on the left side of the screen. When you have chosen a filter, the numbers above will update.",
      SE: "Lägg till filter i rapporten genom att öppna filterfliken till vänster. När du har valt ett filter kommer siffrorna ovan att uppdateras.",
    },
    Excel: {
      Description: {
        EN: "Export the defects to an excel format. The excel format is used for Microsoft Excel, but is also compatible with other spreadsheet software.",
        SE: "Exportera defekterna till ett excelformat. Excelformatet används i Microsoft Excel, men är även kompatibelt med andra kalkylbladsprogram.",
      },
    },
    CSV: {
      Description: {
        EN: "Export the defects to a CSV format. The CSV format is a general-purpose format to export and import data. The CSV file exported can also be viewed in any spreadsheet software.",
        SE: "Expoetera defekterna till ett CSV-format. CSV-formatet är ett generellt format som ofta används för att exportera data. CSV filen kan även öppnas i alla kalkylbladsprogram.",
      },
    },
    DPPower: {
      Description: {
        EN: "Export the defects found in your project to DPPower. This file is a binary file specifically designed to integrate with DPPowers systems. If you do not have DPPower as an inventory management system, then this feature is not for you.",
        SE: "Exportera defekterna i detta projekt till DPPower. Denna fil är en binar fil speciellt designad för att integrera med DPPowers system. Om ni inte använder DPPower för att hantera ert elnät, så är inte denna funktion för er.",
      },
    },
    MissingOID: {
      EN: "Missing OID on some defects",
      SE: "Vissa defekter saknar OID",
    },
    SomePolesAreMission: {
      EN: "Some poles are missing for the defects that you are about to download. Please choose an option for your download.",
      SE: "Vissa stolpar saknas för defekterna som du håller på att ladda ner. Var god och välj ett av alternativen för din nedladdning.",
    },
    AllDefects: {
      EN: "All defects",
      SE: "Alla defekter",
    },
    AllDefectsWithoutOID: {
      EN: "All defects without OID",
      SE: "Alla defekter utan OID",
    },
    DefectsWithoutOID: {
      EN: "Defects without OID",
      SE: "Defekter utan OID",
    },
    DefectsWithoutPoles: {
      EN: "Defects without poles",
      SE: "Defekter utan stolpar",
    },
    Numbers: {
      EN: "We found the defects as described in the table below. To change what defects you want in the report, open the filter in the menu to the left.",
      SE: "Vi hittade defekter enligt beskrivning i tabellen nedan. För att ändra vilka defekter ni vill ha i rapporten, öppna filtret i menyn till vänster.",
    },
    Guide: {
      EN: "Welcome to the Arkion Report Editor",
      SE: "Välkommen till Arkions rapportverktyg",
      WhatIsIt: {
        EN: "What is it?",
        SE: "Vad är detta?",
        Content: {
          EN: "You can generate a report and edit it directly here in the browser. Thus, you will be able to see exactly what the report will look like when you export it.",
          SE: "Du kan generera en rapport och ändra den direkt i webbläsaren. Därmed kan du se exakt hur rapporten kommer se ut när du exporterar den.",
        },
      },
      WhatToDo: {
        EN: "What do I do?",
        SE: "Vad ska jag göra?",
        Content: {
          EN: "Activate a filter in the menu to the left. Then press 'Load report' and a report will be displayed below.",
          SE: "Aktivera ett filter i menyn till vänster. Tryck därefter 'Ladda rapport' och en rapport kommer visas nedan.",
        },
      },
      HowToDownload: {
        EN: "How do I download the report?",
        SE: "Hur laddar jag ned rapporten?",
        Content: {
          EN: "When a report has been loaded, make sure all images have been downloaded (that you can see an image on each page below). Then press 'Export'",
          SE: "När en rapport har laddats bör du se till att alla bilder har laddats ned. Du kan se detta genom att skrolla igenom rapporten. Därefter trycker du på 'Exportera'",
        },
      },
      WhatIsSpecial: {
        EN: "What's so special about this?",
        SE: "Vad är så speciellt med rapporten?",
        Content: {
          EN: "You can edit anything and everything in your report (except for images and QR codes). This can be used to add information such as:",
          SE: "Du kan editera allt i rapporten (förrutom bilder och QR koder). Detta kan användas för att lägga till informatino som:",
        },
        Table: {
          Grid: {
            EN: "Grid owner",
            SE: "Nätägare",
          },
          Pole: {
            EN: "Pole type",
            SE: "Stolptyp",
          },
          Replacement: {
            EN: "Replacement parts",
            SE: "Reservdel",
          },
          More: {
            EN: "And much more",
            SE: "Och mycket mer",
          },
        },
      },
      MoreQuestions: {
        EN: "Do you have more questions?",
        SE: "Har du fler frågor?",
        Content: {
          EN: "Feel free to contact Arkion if you want more information about this tool.",
          SE: "Kontakta gärna Arkion om du vill ha mer information om detta verktyg.",
        },
      },
    },
    ImageName: {
      EN: "Image name",
      SE: "Bildnamn",
    },
    Export: {
      EN: "Export",
      SE: "Exportera",
    },
    ReloadReport: {
      EN: "Reload report",
      SE: "Ladda om rapport",
    },
    LoadReport: {
      EN: "Load report",
      SE: "Ladda rapport",
    },
    Clear: {
      EN: "Clear",
      SE: "Rensa",
    },
    Count: {
      EN: "Count",
      SE: "Antal",
    },
    Defect: {
      EN: "Defect",
      SE: "Skada",
    },
    FailedToLoadData: {
      EN: "Failed to load report",
      SE: "Misslyckades att ladda rapporten",
    },
    Severity: {
      EN: "Severity",
      SE: "Allvarlighetsgrad",
    },
    Link: {
      EN: "Link",
      SE: "Länk",
    },
    Lat: {
      EN: "Lat",
      SE: "Lat",
    },
    Lng: {
      EN: "Lng",
      SE: "Lng",
    },
    Comment: {
      EN: "Comment",
      SE: "Kommentar",
    },
    CommentedBy: {
      EN: "Commented by",
      SE: "Kommenterad av",
    },
    TotalImages: {
      EN: "Total images",
      SE: "Antal bilder",
    },
    TotalDefects: {
      EN: "Total defects",
      SE: "Antal skador",
    },
    NoDefectsFound: {
      EN: "No defects found. Try to change the filter in the menu on your left-hand side.",
      SE: "Inga skador hittades. Testa att ändra filtret i menyn till vänster.",
    },
    ScanQR: {
      EN: "Scan the QR code to view the location of the defect in google maps.",
      SE: "Skanna QR koden för att se skadans position i google maps.",
    },
  },
  Inbox: {
    Reports: {
      ReportContent: {
        EN: "Report content",
        SE: "Rapportens innehåll",
      },
      Language: {
        EN: "Language",
        SE: "Språk",
      },
      SummaryPage: {
        EN: "Summary page",
        SE: "Sammanfattning",
      },
      ImagePage: {
        EN: "Image page",
        SE: "Exempel",
      },
      IncludeFixed: {
        EN: "Include fixed defects",
        SE: "Inkludera lagade defekter",
      },
      Title: {
        EN: "My reports",
        SE: "Mina rapporter",
      },
      AllProjects: {
        EN: "All projects",
        SE: "Alla projekt",
      },
      Deleted: {
        EN: "Report deleted",
        SE: "Rapport raderad",
      },
      FailedToLoad: {
        EN: "Failed to load",
        SE: "Misslyckades att ladda",
      },
      Loading: {
        EN: "The report is being created. Please wait. The page will update automatically",
        SE: "Rapporten laddar. Var god vänta. Sidan kommer uppdateras automatiskt",
      },
      ApplyOnMap: {
        EN: "Apply filter and view the map",
        SE: "Applicera filter och se på en karta",
      },
      DownloadPDF: {
        EN: "Download PDF",
        SE: "Ladda ned PDF",
      },
      Share: {
        EN: "Share this with another user",
        SE: "Dela med en annan användare",
      },
      DeletePDF: {
        EN: "Delete this PDF",
        SE: "Radera denna PDF",
      },
      DeleteConfirm: {
        EN: "Are you sure you want to delete this report?",
        SE: "Är du säker att du vill radera denna rapport?",
      },
      RenameReport: {
        EN: "Rename this report",
        SE: "Byt namn på denna rapport",
      },
      Created: {
        EN: "Created",
        SE: "Skapad",
      },
      Creator: {
        EN: "Creator",
        SE: "Skapare",
      },
      PdfSize: {
        EN: "PDF Size",
        SE: "Storlek",
      },
      Images: {
        EN: "Images",
        SE: "Antal bilder",
      },
      Defects: {
        EN: "Defects",
        SE: "Antal Skador",
      },
      FilterDetails: {
        EN: "Filter details",
        SE: "Filterdetaljer",
      },
      NoGroup: {
        EN: "No customer",
        SE: "Ingen kund",
      },
      CreateNewReport: {
        EN: "Create new report",
        SE: "Skapa ny rapport",
      },
      SelectProject: {
        EN: "Select project to create a report for",
        SE: "Välj ett projekt att skapa en rapport för",
      },
      SelectSeverity: {
        EN: "Select which severity levels you want to include in the report",
        SE: "Välj en de allvarlighetsgrader du vill ha med i rapporten",
      },
      DefectType: {
        EN: "Defect Type",
        SE: "Skadetyp",
      },
      SelectDefect: {
        EN: "What defects do you want to have in the report?",
        SE: "Vilka defekter vill du ha med i rapporten?",
      },
      ExplainFilters: {
        EN: "Selecting nothing is the same as selecting everything. If you select certain defect types, only defects of those types will show up in the report.",
        SE: "Att inte välja något är samma sak som att välja allt. Om du väljer vissa skadetyper, kommer endast defekter av dessa typer att visas i rapporten.",
      },
      SelectImageLists: {
        EN: "What image lists do you want to use?",
        SE: "Vilken bildlista vill du använda?",
      },
      SelectImageTypes: {
        EN: "What types of images do you want?",
        SE: "Vilka typer av bilder vill du ha med?",
      },
      AdditionalDetails: {
        EN: "Additional Details",
        SE: "Extra detaljer",
      },
      AdvancedFilters: {
        EN: "Advanced filters",
        SE: "Avancerade filter",
      },
      Preview: {
        EN: "Preview",
        SE: "Förhandsvisning",
        SummaryPage: {
          StatisticsSummary: {
            EN: "Report statistics summary",
            SE: "Rapportens statistiska sammanfattning",
          },
          ofTotal: {
            EN: "of total",
            SE: "av totalt",
          },
          defects: {
            EN: "defects",
            SE: "defekter",
          },
          imagesWithDefects: {
            EN: "images with defects",
            SE: "bilder med defekter",
          },
          Other: {
            EN: "Other",
            SE: "Annat",
          },
        },
        PageTitle: {
          EN: "Arkion Defect Report",
          SE: "Arkion Skaderapport",
        },
        QRCode: {
          EN: "QR CODE",
          SE: "QR KOD",
        },
        Property: {
          EN: "Property",
          SE: "Egenskap",
        },
        Value: {
          EN: "Value",
          SE: "Värde",
        },
        ImageName: {
          EN: "Image name",
          SE: "Bildnamn",
        },
        TimeTaken: {
          EN: "Time taken",
          SE: "Tid av bild",
        },
        SkyqraftLink: {
          EN: "Arkion link",
          SE: "Arkion länk",
        },
        Location: {
          EN: "Location",
          SE: "Plats",
        },
      },
    },
  },
  UserAdmin: {
    EN: "User Administration",
    SE: "Användarhantering",
    InviteNewUser: {
      EN: "Invite new user",
      SE: "Bjud in ny användare",
    },
    SearchForUser: {
      EN: "Search for a user",
      SE: "Sök efter en användare",
    },
    SearchForMissionOrGroup: {
      EN: "Search for a project or a customer",
      SE: "Sök efter ett projekt eller en kund",
    },
    NoUsersFound: {
      EN: "No users found",
      SE: "Inga användare hittades",
    },
    LoadingUser: {
      EN: "Loading user",
      SE: "Laddar användare",
    },
    UserNotFound: {
      EN: "User not found",
      SE: "Användare hittades inte",
    },
    FailedToUpdateUser: {
      EN: "Failed to update user",
      SE: "Misslyckades att uppdatera användare",
    },
    GiveAccess: {
      EN: "Give access",
      SE: "Ge tillgång",
    },
    ExplicitProjectPermissions: {
      EN: "Explicit project permissions for this user",
      SE: "Explicita projekträttigheter för denna användare",
    },
    UnableToEditEmail: {
      EN: "You are not able to update email of users",
      SE: "Du har inte behörighet att uppdatera användares email",
    },
    LeaveEditMode: {
      EN: "Leave edit mode",
      SE: "Lämna redigerarläge",
    },
    SaveChanges: {
      EN: "Save changes",
      SE: "Spara ändringar",
    },
    UpdateUserProperties: {
      EN: "Update properties of the user",
      SE: "Uppdatera användarens egenskaper",
    },
    FailedToInvite: {
      EN: "Failed to invite",
      SE: "Misslyckades att bjuda in",
    },
    SelectMissions: {
      EN: "Select which projects you want to give",
      SE: "Välj vilka projekt du vill ge",
    },
    AccessTo: {
      EN: "access to",
      SE: "tillgång till",
    },
    GroupProject: {
      EN: "Customer-wide access",
      SE: "Tillgång till hela kunden",
    },
    AccessTarget: {
      EN: "Where should they have access?",
      SE: "Vart ska de ha tillgång?",
    },
    NoGroup: {
      EN: "No customer",
      SE: "Ingen kund",
    },
    AddAccessLevel: {
      EN: "Add new access level",
      SE: "Lägg till behörighet",
    },
    EmailInvalid: {
      EN: "Email is invalid",
      SE: "Mailadressen är inte korrekt",
    },
    NameInvalid: {
      EN: "Name is too short",
      SE: "Namnet är för kort",
    },
    InviteDescription: {
      EN: "Add a users email below to invite them to the Arkion Platform. You can also give them access to a project or an entire customer.",
      SE: "Lägg till en användares mailadress nedan för att bjuda in dem till Arkion plattform. Du kan även ge dem tillgång till ett projekt eller en kund.",
    },
    SendInvite: {
      EN: "Send invite",
      SE: "Skicka inbjudan",
    },
    EmailWillBeSent: {
      EN: "An email will be sent to",
      SE: "Ett mail kommer skickas till",
    },
    PasswordWillBeSent: {
      EN: "with an autogenerated password if they do not already have an account. If they have an account, they will be notified that they have been given new access.",
      SE: "med ett autogenererat lösenord om de redan har har ett konto. Om de har ett konto kommer de notifieras om den nya access.",
    },
    UpdatePermissionsFor: {
      EN: "Update permissions for",
      SE: "Uppdatering av rättigheter för",
    },
    RemoveAccessConfirm: {
      EN: "Are you sure you want to remove access for",
      SE: "Är du säker att du vill ta bort berörighet för",
    },
    RemoveAccessFailed: {
      EN: "Removing access failed for",
      SE: "Borttagning av behörighet misslyckades för",
    },
    TimeBasedAccess: {
      EN: "Time-based access",
      SE: "Tidsbaserad åtkomst",
    },
    RemoveAccess: {
      EN: "Remove access",
      SE: "Ta bort åtkomst",
    },
  },

  words: {
    on: {
      EN: "on",
      SE: "på",
    },
    ShowMore: {
      EN: "Show more",
      SE: "Visa mer",
    },
    ShowLess: {
      EN: "Show less",
      SE: "Visa mindre",
    },
    ShowAll: {
      EN: "Show all",
      SE: "Visa alla",
    },
    ProjectName: {
      EN: "Project name",
      SE: "Projektnamn",
    },
    Severity1: {
      EN: "Critical",
      SE: "Kritisk",
    },
    Severity2: {
      EN: "Issue",
      SE: "Anmärkning",
    },
    Severity3: {
      EN: "Should be fixed",
      SE: "Bör åtgärdas",
    },
    Severity4: {
      EN: "Good to know",
      SE: "För info",
    },
    Start: {
      EN: "Start date",
      SE: "Startdatum",
    },
    End: {
      EN: "End date",
      SE: "Slutdatum",
    },
    Severity: {
      EN: "Severity",
      SE: "Allvarlighetsgrad",
    },
    Confirm: {
      EN: "Confirm",
      SE: "Bekräfta",
    },
    Clear: {
      EN: "Clear",
      SE: "Rensa",
    },
    Of: {
      EN: "of",
      SE: "av",
    },
    Failed: {
      EN: "failed",
      SE: "misslyckades",
    },
    Note: {
      EN: "Note",
      SE: "Anteckning",
    },
    By: {
      EN: "by",
      SE: "av",
    },
    Has: {
      EN: "has",
      SE: "har",
    },
    Customer: {
      EN: "Customer",
      SE: "Kund",
    },
    Region: {
      EN: "Region",
      SE: "Region",
    },
    Mission: {
      EN: "Project",
      SE: "Projekt",
    },
    Deleted: {
      EN: "Deleted",
      SE: "Raderad",
    },
    Welcome: {
      EN: "Welcome",
      SE: "Välkommen",
    },
    ID: {
      EN: "ID",
      SE: "ID",
    },
    Folder: {
      EN: "Folder",
      SE: "Mapp",
    },
    Timestamp: {
      EN: "Timestamp",
      SE: "Tidsstämpel",
    },
    IP: {
      EN: "IP",
      SE: "IP",
    },
    None: {
      EN: "None",
      SE: "Ingen",
    },
    Type: {
      EN: "Type",
      SE: "Typ",
    },
    You: {
      EN: "Client",
      SE: "Klient",
    },
    Name: {
      EN: "Name",
      SE: "Namn",
    },
    DroneOperator: {
      EN: "Drone operator / Helicopter operator / Data collector",
      SE: "Drönaroperatör / Helikopteroperatör / Datainsamlare",
    },
    Analysis: {
      EN: "Analysis",
      SE: "Analys",
    },
    Comment: {
      EN: "Comment",
      SE: "Kommentar",
    },
    Email: {
      EN: "Email",
      SE: "Epost",
    },
    PhoneNumber: {
      EN: "Phone number",
      SE: "Telefonnummer",
    },
    Edit: {
      EN: "Edit",
      SE: "Ändra",
    },
    Year: {
      EN: "Year",
      SE: "Årtal",
    },
    Poles: {
      EN: "poles",
      SE: "stolpar",
    },
    Image: {
      EN: "Image",
      SE: "Bild",
    },
    Object: {
      EN: "Object",
      SE: "Objekt",
    },
    Users: {
      EN: "Users",
      SE: "Användare",
    },
    User: {
      EN: "User",
      SE: "Användare",
    },
    Assignment: {
      EN: "Assignment",
      SE: "Tilldelning",
    },
    Fixed: {
      EN: "Defect fixed",
      SE: "Skada lagad",
    },
    Yes: {
      EN: "Yes",
      SE: "Ja",
    },
    No: {
      EN: "No",
      SE: "Nej",
    },
    AccessLevel: {
      EN: "Access level",
      SE: "Behörighetsnivå",
    },
    TheUser: {
      EN: "the user",
      SE: "användaren",
    },
    GoToNewTool: {
      EN: "Go to the new tool",
      SE: "Gå till det nya verktyget",
    },
    ThisIsYou: {
      EN: "This is you",
      SE: "Detta är du",
    },
    Placed: {
      EN: "placed",
      SE: "placerad",
    },
    For: {
      EN: "for",
      SE: "för",
    },
  },
  ClientSetting: {
    CustomizeDefectTypes: {
      EN: "Customize defect types",
      SE: "Anpassa skadekategorier",
    },
    ClientSeverityTypes: {
      EN: "Client severity types",
      SE: "Klient allvarlighetsgrad typer",
    },
    ClientDefectType: {
      EN: "Client defect type",
      SE: "Klient skadetyper",
    },
    AddANewClientDefectType: {
      EN: "Add a new client defect type",
      SE: "Lägg till en ny klient skadetyp",
    },
    AddANewClientSeverityType: {
      EN: "Add a new client severity type",
      SE: "Lägg till en ny klient allvarlighetsgradstyp",
    },
    DidYouMissAnyTypes: {
      EN: "Did you miss any types in the list? Please, add it",
      SE: "Saknade du några typer i listan? Lägg gärna till den",
    },
    TypeName: {
      EN: "type name",
      SE: "typ namn",
    },
    Color: {
      EN: "color",
      SE: "färg",
    },
    ClientInternalId: {
      EN: "client internal id",
      SE: "klient id internt bruk",
    },
    SeverityName: {
      EN: "severity name",
      SE: "allvarlighetsgrad namn",
    },
    EditClientSeverities: {
      EN: "Edit client severities",
      SE: "Redigera klient allvarlighetsgrader",
    },
    AddSeverity: {
      EN: "Select a severity or type in a new one",
      SE: "Välj en allvarlighetsgrad eller skriv in en ny",
    },
    ClientObjectTypeCategory: {
      EN: "Client object type category",
      SE: "Klient objekttyp kategori",
    },
    AddANewClientObjectTypeCategory: {
      EN: "Add a new client object type category",
      SE: "Lägg till en ny klient objekttyps kategori",
    },
    DidYouMissAnyCategories: {
      EN: "Did you miss any categories in the list? Please, add it",
      SE: "Saknade du några kategorier i listan? Lägg gärna till den",
    },
    CategoryName: {
      EN: "category name",
      SE: "kategori namn",
    },
    ClientInternalCategoryId: {
      EN: "client internal category id",
      SE: "klient kategori id internt bruk",
    },
    EditClientIssueType: {
      EN: "Edit client defect type for id",
      SE: "Redigera klient skadetype för id",
    },
    AddClientDefectType: {
      EN: "Select a defect type or type in a new one",
      SE: "Välj en skadetyp eller skriv in en ny",
    },
    ClientInternaldefectCode: {
      EN: "Client internal defect code that can be included in all excel reports",
      SE: "Klient internt id för skadetyp som kan inkluderas i excelrapporter",
    },
  },
  Markers: {
    LidarMarkers: {
      Update: {
        EN: "Update",
        SE: "Uppdatera",
        Title: {
          EN: "Update clearance marker",
          SE: "Uppdatera rensningsmarkör",
        },
        Description: {
          EN: "Updating the clearance marker will change the severity level of the lidar marker.",
          SE: "Uppdatering av rensningsmarkörer kommer att ändra allvarlighetsgraden för lidarmarkören.",
        },
      },
      Cancel: {
        EN: "Cancel",
        SE: "Avbryt",
      },
      Reset: {
        EN: "Reset",
        SE: "Återställ",
      },
    },
    ClearanceMarkers: {
      Verify: {
        EN: "Verify",
        SE: "Verifiera",
        Title: {
          EN: "Verify clearance marker",
          SE: "Verifiera rensningsmarkör",
        },
        Description: {
          EN: "Marking the clearance marker as verified will remove the clearance marker from the map, and be replaced by a lidar marker. The lidar marker is visible to customers.",
          SE: "Markering av rensningsmarkörer som verifierad kommer att ta bort rensningsmarkören från kartan och ersättas med en lidarmarkör. Lidarmarkören är synlig för kunder.",
        },
      },
      Cancel: {
        EN: "Cancel",
        SE: "Avbryt",
      },
      Reject: {
        EN: "Reject",
        SE: "Avvisa",
      },
    },
  },
  Filter: {
    EN: "Filter",
    SE: "Filter",
    FindInsights: {
      EN: "Find insights in your grid",
      SE: "Hitta insikter i ditt nät",
    },
    CreateReport: {
      EN: "Create report",
      SE: "Skapa rapport",
    },
    ApplyFilter: {
      EN: "Apply filter",
      SE: "Applicera filter",
    },
    ClearFilter: {
      EN: "Clear filter",
      SE: "Rensa filter",
    },
    DisableFilter: {
      EN: "Disable filter",
      SE: "Inaktivera filter",
    },
    SaveCurrentFilter: {
      EN: "Save current filter",
      SE: "Spara nuvarande filter",
    },
    Severity: {
      EN: "Severity",
      SE: "Nivå",
    },
    Electric: {
      EN: "Electric",
      SE: "El",
    },
    Vegetation: {
      EN: "Vegetation",
      SE: "Vegetation",
    },
    Spatial: {
      EN: "Spatial",
      SE: "Spatialt",
    },
    Saved: {
      EN: "Saved",
      SE: "Sparat",
    },
    Close: {
      EN: "Close",
      SE: "Stäng",
    },
    Defects: {
      EN: "Defects",
      SE: "Defekter",
    },
    Objects: {
      EN: "Objects",
      SE: "Objekt",
    },
    SavedFilters: {
      EN: "Your saved filters",
      SE: "Dina sparade filter",
    },
    ListFilters: {
      EN: "Saved image lists",
      SE: "Sparde bildlistor",
    },
    AdvancedFilters: {
      EN: "Advanced filters",
      SE: "Avancerade filter",
    },
    SkyqraftHidden: {
      EN: "Arkion hidden",
      SE: "Arkion dold",
    },
    EnterNameOfType: {
      EN: "Enter the name of an object type",
      SE: "Skriv in namnet på en objekttyp",
    },
    SearchForObjectType: {
      EN: "Search for object type",
      SE: "Sök efter objekttyp",
    },
    NoTypeMatches: {
      EN: "No object type matches",
      SE: "Ingen objekttyp matchar",
    },
    Loading: {
      EN: "Loading",
      SE: "Laddar",
    },
    ActorsFilter: {
      EN: "Actors filter",
      SE: "Aktörsfilter",
    },
    ComingSoon: {
      EN: "Coming soon",
      SE: "Kommer snart",
    },
    SelectArea: {
      EN: "Select Area",
      SE: "Välj Område",
    },
    UnSelectArea: {
      EN: "Unselect Area",
      SE: "Avmarkera Område",
    },
    CleranceAxis: {
      EN: "Clerance Axis",
      SE: "Rensningsaxel",
    },
    ClearanceRule: {
      EN: "Clearance Rule",
      SE: "Rensningsregel",
    },
    DateFilter: {
      EN: "Date filter",
      SE: "Datumfilter",
    },
    DateImageCaptured: {
      EN: "Date image captured",
      SE: "Datum bild tagen",
    },
    DateImageUploaded: {
      EN: "Date image uploaded",
      SE: "Datum bild uppladdad",
    },
    DateDefectAdded: {
      EN: "Date defect added",
      SE: "Datum skada tillagd",
    },
    DateDefectProcessed: {
      EN: "Date defect processed",
      SE: "Datum skada behandlad",
    },
    DateDefectFixed: {
      EN: "Date defect fixed",
      SE: "Datum skada lagad",
    },
    DateDefectReported: {
      EN: "Date defect reported",
      SE: "Datum skada rapporterad",
    },
    SelectDate: {
      EN: "Select date",
      SE: "Välj datum",
    },
    ShowDsoTso: {
      EN: "Show DSO and TSO structures",
      SE: "Visa DSO och TSO strukturer",
    },
    Flight: {
      EN: "Flight",
      SE: "Flygning",
    },
    SelectFlight: {
      EN: "Select flight",
      SE: "Välj flygning",
    },
    LidarMarkers: {
      EN: "3D (lidar) markers",
      SE: "3D (lidar) markörer",
    },
    MachineDetectedDefects: {
      EN: "Machine detected defects",
      SE: "Maskinidentifierade defekter",
    },
    NewDefects: {
      EN: "New defects",
      SE: "Nya defekter",
    },
    EnterNameForFilter: {
      EN: "Enter name for filter",
      SE: "Skriv in namn för filter",
    },
    NoSavedFilters: {
      EN: "No saved filters",
      SE: "Inga sparade filter",
    },
    PressStarToSave: {
      EN: "Press the star to save the filter",
      SE: "Tryck på stjärnan för att spara filtret",
    },
    SelectFilterToSave: {
      EN: "Select a filter to create a saved filter",
      SE: "Välj ett filter för att skapa ett sparat filter",
    },
    Activate: {
      EN: "Activate",
      SE: "Aktivera",
    },
    DeleteConfirmationFilter: {
      EN: "Are you sure you want to delete this filter?",
      SE: "Är du säker att du vill radera detta filter?",
    },
    Defect: {
      EN: "Defect",
      SE: "Kritisk",
    },
    Remarks: {
      EN: "Remarks",
      SE: "Upplysningar",
    },
    Severity1: {
      EN: "Critical",
      SE: "Kritisk",
    },
    Severity2: {
      EN: "Issue",
      SE: "Anmärkning",
    },
    Severity3: {
      EN: "Should be fixed",
      SE: "Bör åtgärdas",
    },
    Severity4: {
      EN: "Good to know",
      SE: "För info",
    },
    HideHidden: {
      EN: "Hide hidden objects",
      SE: "Dölj dolda objekt",
    },
    ShowAll: {
      EN: "Show all objects",
      SE: "Visa alla objekt",
    },
    HiddenOnly: {
      EN: "Show only hidden objects",
      SE: "Visa endast dolda objekt",
    },
    FeedBayFilter: {
      EN: "Feed bay filter",
      SE: "Fack filter",
    },
    NoFeedBaysFound: {
      EN: "No feed bays found",
      SE: "Inga fack hittades",
    },
    FeedBaySearch: {
      EN: "Feed Bay Search",
      SE: "Sök fack",
      Section1: {
        EN: "Search for a feed bay by name. You can then chose to hide all other powerlines, or highlight the selected feed bay.",
        SE: "Sök efter ett fack efter namn. Du kan sedan välja att dölja alla andra kraftledningar, eller markera den valda fack.",
      },
      Section2: {
        EN: "You can select multiple feed bays to visualize on the map and find anything and evetything you need.",
        SE: "Du kan välja flera fack att visualisera på kartan och hitta allt du behöver.",
      },
    },
    GenerateColors: {
      EN: "Generate colors automatically",
      SE: "Generera färger automatiskt",
    },
    Show: {
      EN: "Show",
      SE: "Visa",
    },
    Cancel: {
      EN: "Cancel",
      SE: "Avbryt",
    },
    WhatIs3D: {
      EN: "What is 3D data? (LiDAR)",
      SE: "Vad är 3D data? (LiDAR)",
      Title: {
        EN: "3D data (LiDAR)",
        SE: "3D data (LiDAR)",
      },
      Section1: {
        EN: "Our platform support data analysis in both 2D (Images) and 3D (LiDAR). Images are excellent for detecting visual damages on your equipment. However, the images are not optimal for seeing the vegetation around your grid.",
        SE: "Vår plattform stödjer dataanalys i både 2D (bilder) och 3D (LiDAR). Bilder är utmärkta för att upptäcka visuella skador på din utrustning. Dock är bilderna inte optimala för att se vegetationen runt ditt nät.",
      },
      Section2: {
        EN: "LiDAR data is one of the best methods to identify and analyze potential issues with vegetation. The 3D data can be coupled with the 2D images in our platform to give you a complete overview of your grid.",
        SE: "LiDAR data är en av de bästa metoderna för att identifiera och analysera potentiella problem med vegetation. 3D-data kan kopplas samman med 2D-bilder i vår plattform för att ge dig en komplett översikt över ditt nät.",
      },
    },
    WhatAreTheseFilters: {
      EN: "What are these filters?",
      SE: "Vad är dessa filter?",
      Title: {
        EN: "Spatial filters",
        SE: "Spatiala filter",
      },
      AreaFilter: {
        EN: "Area filter",
        SE: "Områdesfilter",
        Description: {
          EN: "The area filter allows you to select a specific area on the map to filter for. To clear the filter, click the button again.",
          SE: "Områdesfiltret låter dig välja ett specifikt område på kartan att filtrera för. För att rensa filtret, klicka på knappen igen.",
        },
      },
      FlightFilter: {
        EN: "Flight filter",
        SE: "Flygfilter",
        Description: {
          EN: "Each upload of images is called a flight. The flight filter allows you to see how many images and what folder the upload is in. You can select multiple flights to filter for.",
          SE: "Varje uppladdning av bilder kallas ett flyg. Flygfilteret låter dig se hur många bilder och vilken mapp uppladdningen är i. Du kan välja flera flyg för att filtrera för.",
        },
      },
      DateSelector: {
        EN: "Date selector",
        SE: "Datumväljare",
        Description: {
          EN: "The date selector allows you to search for items within specific date ranges. The date can either be one date (filtering out everything that happened after that date), or two dates (filtering out everyhing that happened between those dates).",
          SE: "Datumväljaren låter dig söka efter objekt inom specifika datumintervall. Datumet kan antingen vara ett datum (filtrera ut allt som hände efter det datumet) eller två datum (filtrera ut allt som hände mellan de datumen).",
        },
      },
      FeedBay: {
        EN: "Feed bay",
        SE: "Fack",
        Description: {
          EN: "The feed bay allows you to visualize how each powerline is electrically connected. The feed bay is specified when uploading the powerline. If you want to add or modify the feed bay.",
          SE: "Fack låter dig visualisera hur varje kraftledning är elektriskt ansluten. Fack är definerad vid uppladdningen av kraftledningen. Om du vill lägga till eller ändra fack.",
        },
      },
      NewDefects: {
        EN: "New defects",
        SE: "Nya defekter",
        Description: {
          EN: "The filter shows you all defects that you have not seen before. If you open an image with defects, the defects will be marked as seen. More specifically, any defect that was created after you last viewed an image will be flagged as a new defect. (or if you have not viewed the image yet)",
          SE: "Filtret visar dig alla defekter som du inte har sett tidigare. Om du öppnar en bild med defekter kommer defekterna att markeras som sedda. Mer specifikt kommer alla defekter som skapades efter att du senast såg en bild att flaggas som en ny defekt. (eller om du inte har sett bilden ännu)",
        },
      },
    },
    WhatAreTheseNumbers: {
      EN: "What are these numbers?",
      SE: "Vad är dessa nummer?",
      Title: {
        EN: "Filter numbers",
        SE: "Filter nummer",
      },
      Section1: {
        EN: "The numbers presented represent the amount of objects (bounding boxes) found on images in this project. If there are 10 critical defects found, then the number 10 will be shown next to the 'Critical' filter.",
        SE: "De presenterade numren representerar antalet objekt (bounding boxes) som hittats på bilder i detta projekt. Om det finns 10 kritiska defekter hittade, kommer numret 10 att visas bredvid 'Kritisk' filtret.",
      },
      Section2: {
        EN: "If there are multiple filters selected, the number will represent the amount of objects that match all the selected filters.",
        SE: "Om det finns flera filter valda, kommer numret att representera antalet objekt som matchar alla valda filter.",
      },
      Section3: {
        EN: "The numbers in the filters represent the number of defects that match the filter. The numbers are updated every 15 minutes.",
        SE: "Numren i filtren representerar antalet defekter som matchar filtret. Numren uppdateras var 15:e minut.",
      },
    },
    WhatIsDefect: {
      EN: "What is a Defect or Objects?",
      SE: "Vad är en defekt eller objekt?",
      Title: {
        EN: "Defect vs Objects",
        SE: "Defekt vs Objekt",
      },
      Section1: {
        EN: "A defect is an identified problem found on an image. A defect always has a severity level accompanied with it. The meaning of each severity level depends on your internal guidelines.",
        SE: "En defekt är ett identifierat problem som hittats på en bild. En defekt har alltid en allvarlighetsgrad kopplad till sig. Betydelsen av varje allvarlighetsgrad beror på dina interna riktlinjer.",
      },
      Section2: {
        EN: "An object is an element we have identified on an image, but it is not necessarily a defect. Detections are very useful for finding components in your grid. For example, you can use detections to find where all your transformers are located.",
        SE: "Ett objekt är ett element som vi har identifierat på en bild, men det är inte nödvändigtvis en defekt. Detekteringar är mycket användbara för att hitta komponenter i ditt nät. Till exempel kan du använda detekteringar för att hitta var alla dina transformatorer är placerade.",
      },
      Section3: {
        EN: "You can rename all types using the",
        SE: "Du kan döpa om alla typer med hjälp av",
      },
      ClientSettings: {
        EN: "client settings",
        SE: "klientinställningar",
      },
    },
  },
  notifications: {
    noTopologyDataFound: {
      EN: "No topology data found",
      SE: "Ingen topologidata hittades",
    },
  },
};

export default translations;
