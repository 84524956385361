import React from "react";
import { Mission } from "interfaces";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import md5 from "md5-hash";
import MissionItem from "../MissionItem";
import { ExpandMore } from "@mui/icons-material";

type Props = {
  name: string;
  missions: Mission[];
  demoMode: boolean;
};

export default function RegionItem({ demoMode, missions, name }: Props) {
  return (
    <Accordion
      square
      sx={{ backgroundColor: "#DDDDDD", p: 0, m: 0 }}
      disableGutters
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{demoMode ? md5(name) : name}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ backgroundColor: "#FFFFFF", color: "#404040", p: 0, m: 0 }}
      >
        {missions.map((mission) => (
          <MissionItem key={mission.id} mission={mission} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
