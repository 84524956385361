import { isEqual } from "lodash";
import store from "state/store";
import { clearFilterArea } from "state/actions";

export const defaultValues = {
  defect: [],
  severity: [],
  detection: [],
  workflow_status: [],
  confidence: [0, 100],
  image_type: [0, 1],
  flagged: false,
  hideFixed: false,
  list: [],
  workflow: [],
  annotator: 0,
  date: null,
  flight: [],
  lidar_distance: 0,
  origin: "any",
  creationStart: "",
  creationEnd: "",
  processedStart: "",
  processedEnd: "",
  clearanceRules: [],
  clearanceAxes: [],
  area: "",
  skyqraftHidden: false,
  showDsoTso: "",
  new: false,
};
type filterName =
  | "defect"
  | "severity"
  | "detection"
  | "workflow_status"
  | "confidence"
  | "image_type"
  | "flagged"
  | "hideFixed"
  | "list"
  | "workflow"
  | "annotator"
  | "date"
  | "flight"
  | "lidar_distance"
  | "origin"
  | "area"
  | "creationStart"
  | "creationEnd"
  | "processedStart"
  | "processedEnd"
  | "clearanceRules"
  | "clearanceAxes"
  | "new"
  | "skyqraftHidden"
  | "showDsoTso";

export function getSeverityFilter(searchParams: URLSearchParams) {
  if (searchParams.has("severity")) {
    return searchParams
      .get("severity")
      .split(",")
      .map((s) => parseInt(s))
      .filter((s) => !Number.isNaN(s));
  }
  return [];
}

export function getFilter(key: filterName, searchParams: URLSearchParams) {
  if (searchParams.has(key)) {
    if (
      [
        "defect",
        "severity",
        "detection",
        "workflow_status",
        "confidence",
        "list",
        "workflow",
        "flight",
        "image_type",
        "area",
        "clearanceRules",
        "clearanceAxes",
      ].includes(key)
    ) {
      const value = searchParams
        .get(key)
        .split(",")
        .map((s) => parseInt(s))
        .filter((s) => !Number.isNaN(s));
      return value;
    }
    if (
      [
        "origin",
        "date",
        "creationStart",
        "creationEnd",
        "processedStart",
        "processedEnd",
      ].includes(key)
    ) {
      const value = searchParams.get(key);
      return value;
    }
    const value = JSON.parse(searchParams.get(key));
    return value;
  }
  return defaultValues[key];
}

export function setFilter(
  key: filterName,
  value,
  searchParams: URLSearchParams,
  setSearchParams: (params: URLSearchParams, options) => void
) {
  if (isEqual(value, defaultValues[key])) {
    searchParams.delete(key);
  } else {
    if (
      [
        "defect",
        "severity",
        "detection",
        "workflow_status",
        "confidence",
        "list",
        "workflow",
        "flight",
        "image_type",
        "area",
        "clearanceRules",
        "clearanceAxes",
      ].includes(key)
    ) {
      searchParams.set(key, value.join(","));
    } else if (
      [
        "origin",
        "date",
        "creationStart",
        "creationEnd",
        "processedStart",
        "processedEnd",
      ].includes(key)
    ) {
      searchParams.set(key, value);
    } else {
      searchParams.set(key, JSON.stringify(value));
    }
  }
  setSearchParams(searchParams, { replace: true });
}

export function clearFilters(
  keys: filterName[],
  searchParams: URLSearchParams,
  setSearchParams: (params: URLSearchParams, options) => void
) {
  for (const key of keys) {
    searchParams.delete(key);
  }
  setSearchParams(searchParams, { replace: true });
}

export function isFilterActive(searchParams: URLSearchParams) {
  let active = false;
  const keys = Object.keys(defaultValues);
  for (const key of keys) {
    if (searchParams.has(key) && key !== "showDsoTso") {
      active = true;
    }
  }
  return active;
}

export const resetFilters = (
  searchParams: URLSearchParams,
  setSearchParams: (params: URLSearchParams) => void
) => {
  clearFilters(
    [
      "defect",
      "severity",
      "hideFixed",
      "detection",
      "image_type",
      "flagged",
      "confidence",
      "list",
      "annotator",
      "date",
      "workflow",
      "flight",
      "new",
      "lidar_distance",
      "origin",
      "area",
      "creationStart",
      "creationEnd",
      "processedStart",
      "clearanceRules",
      "clearanceAxes",
      "processedEnd",
      "skyqraftHidden",
      "showDsoTso",
    ],
    searchParams,
    setSearchParams
  );
  // @ts-ignore
  store.dispatch(clearFilterArea());
};

export function getFilterHash(searchParams: URLSearchParams) {
  const newSearchParams = new URLSearchParams();
  const keys = Object.keys(defaultValues);
  for (const key of keys) {
    if (searchParams.has(key)) {
      newSearchParams.set(key, searchParams.get(key));
    }
  }
  let queryString = newSearchParams.toString();
  queryString = queryString.length === 0 ? "empty" : queryString;
  return queryString;
}
