import { CalendarToday } from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { ThumbDown } from "@mui/icons-material";
import { Divider, IconButton, Stack, Typography, Box } from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import { IssueSeverity, ObjectType } from "interfaces";
import { IImageAnnotation } from "views/AnnotationTool/api";
import translations from "translations";
import { authorizedDelete, authorizedPut } from "utils/request";
import { getClientObjectTypeName, getClientSeverityName } from "utils/utils";
import { updateAnnotations } from "state/actions";
import { useCurrentProject, useDispatch, useSelector } from "hooks";

type Props = {
	annotation: IImageAnnotation;
	objectTypes: ObjectType[];
	severities: IssueSeverity[];
	fixableDefects: boolean;
	updateAnnotationsData: (newAnnotation, image) => void;
	image: number;
	setGetData: (payload) => void;
	processedPossible: boolean;
};

export default function InfoContent({
	annotation,
	objectTypes,
	severities,
	fixableDefects,
	updateAnnotationsData,
	setGetData,
	image,
	processedPossible,
}: Props) {
	const currentProject = useCurrentProject();
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const skyqraftHiddenSetting = useSelector(
		(state) => state.user.skyqraft_hidden,
	);
	const types = objectTypes.filter((ot) => annotation.types.includes(ot.id));
	const language = user.language;

	if (types.length === 0) {
		console.error("No type found. Something went wrong");
		return null;
	}

	const text = [];

	const skyqraft_only = [];
	const workflow_status = [];

	const issueObject =
		annotation.severities.filter((a) => !!a).length > 0 &&
		severities.find((issue) => annotation.severities.includes(issue.id));

	annotation.types.forEach((type, index) => {
		const ot = getClientObjectTypeName(type);

		let currentString = ot.name;
		skyqraft_only.push(ot.skyqraft_only);
		workflow_status.push(annotation.workflow_status[index]);
		if (annotation.skyqraft_hidden[index]) {
			currentString += ` (${translations.ImageViewer.Hidden[language]})`;
		}
		text.push(currentString);
	});

	if (annotation.ring_count) {
		text.push(
			`${annotation.ring_count} ${translations.ImageViewer.Discs[language]}`,
		);
	}

	if (annotation.severities.filter((a) => !!a).length > 0) {
		text.push(
			`${translations.ImageViewer.Severity[language]}: ${issueObject.id} (${
				getClientSeverityName(issueObject.id).name
			})`,
		);
	}

	const isDefect = annotation.severities.filter((a) => !!a).length > 0;
	const defectVisible = isDefect && fixableDefects;

	let reported: Date | boolean;
	if (annotation.reported) {
		reported = new Date(annotation.reported);
	} else {
		reported = false;
	}

	const defectIndexes = [];
	const defectTypes = [];
	annotation.severities.forEach((severity, index) => {
		if (severity) {
			defectIndexes.push(index);
			const ot = getClientObjectTypeName(annotation.types[index]);
			defectTypes.push(ot.name);
		}
	});

	let fixed: Date | boolean;
	if (annotation.fixed) {
		fixed = new Date(annotation.fixed);
	} else {
		fixed = false;
	}

	return (
		<Stack
			spacing={0.5}
			sx={{ p: 0.5 }}
			onMouseDown={(e) => e.stopPropagation()}
		>
			<div>
				{annotation.types.map((type, i) => (
					<Stack key={type} direction="row" spacing={1} alignItems="center">
						<div
							style={{
								padding: "5px",
								margin: "5px 0 5px 0",
								borderRadius: "5px",
								borderColor: "#757575",
								width: "100%",
							}}
						>
							<Typography fontSize="small">
								{`${getClientObjectTypeName(type)?.name} ${
									annotation.skyqraft_hidden[i]
										? `(${translations.ImageViewer.Hidden[language]})`
										: ""
								} `}
							</Typography>
							<Typography fontSize="small">
								{!!annotation.item_id && `Item id: ${annotation.item_id}`}
							</Typography>
							<Typography fontSize="small">
								{!!annotation.ring_count &&
									`${annotation.ring_count} ${translations.ImageViewer.Discs[language]}`}
							</Typography>
							<Typography fontSize="small">
								{!!annotation.severities[i] &&
									annotation.severities[i] !== 11 &&
									`${translations.ImageViewer.Severity[language]}: ${
										getClientSeverityName(annotation.severities[i])?.name
									}`}
							</Typography>
							<Typography fontSize="small">
								{!!annotation.type_comment[i] &&
									`${translations.Landing.ImageFeedback.comment[language]}: ${annotation.type_comment[i]}`}
							</Typography>
						</div>
						{skyqraft_only[i] && workflow_status[i] === 1 ? (
							<>
								<RoleWrapper keyName="reportDefect">
									<IconButton
										size="small"
										onClick={() => {
											const newWorkflowStatus = annotation.workflow_status;
											newWorkflowStatus[i] = 2;
											updateAnnotationsData(
												{ ...annotation, workflow_status: newWorkflowStatus },
												image,
											);
										}}
									>
										<Done
											htmlColor={
												annotation.workflow_status[i] === 2 ? "green" : "white"
											}
										/>
									</IconButton>

									<IconButton
										size="small"
										onClick={() => {
											const newWorkflowStatus = annotation.workflow_status;
											newWorkflowStatus[i] = 4;
											updateAnnotationsData(
												{ ...annotation, workflow_status: newWorkflowStatus },
												image,
											);
										}}
									>
										<ThumbDown
											htmlColor={
												annotation.workflow_status[i] === 4 ? "red" : "white"
											}
										/>
									</IconButton>
								</RoleWrapper>
							</>
						) : (
							annotation.severities[i] === 11 &&
							workflow_status[i] === 1 && (
								<>
									<RoleWrapper keyName="reportDefect">
										<IconButton
											size="small"
											onClick={() => {
												const newWorkflowStatus = annotation.workflow_status;
												newWorkflowStatus[i] = 4;
												updateAnnotationsData(
													{
														...annotation,
														workflow_status: newWorkflowStatus,
													},
													image,
												);
											}}
										>
											<ThumbDown
												htmlColor={
													annotation.workflow_status[i] === 4 ? "red" : "white"
												}
											/>
										</IconButton>
									</RoleWrapper>
								</>
							)
						)}
					</Stack>
				))}
			</div>

			{((isDefect && annotation.severities.includes(1)) || defectVisible) && (
				<Divider light variant="middle" sx={{ borderColor: "white", p: 0.5 }} />
			)}
			{isDefect && annotation.severities.includes(1) && (
				<Stack direction="row" spacing={1} alignItems="center">
					<RoleWrapper keyName="reportDefect">
						<IconButton
							size="small"
							onClick={() =>
								setGetData({
									visible: true,
									comment: "",
									isProcessDefect: false,
									callback: (date) => {
										if (date) {
											authorizedPut(`/object/${annotation.id}/report`, {
												reported: date,
											}).then(() =>
												dispatch(
													updateAnnotations(
														currentProject.id,
														image,
														skyqraftHiddenSetting,
													),
												),
											);
										} else {
											authorizedDelete(`/object/${annotation.id}/report`).then(
												() =>
													dispatch(
														updateAnnotations(
															currentProject.id,
															image,
															skyqraftHiddenSetting,
														),
													),
											);
										}
									},
								})
							}
						>
							<CalendarToday htmlColor="white" fontSize="small" />
						</IconButton>
					</RoleWrapper>
					<Typography variant="body1" fontSize="small">
						{reported
							? `${
									translations.ImageViewer.DefectReported[language]
							  } ${reported.toDateString()}`
							: translations.ImageViewer.DefectNotReported[language]}
					</Typography>
				</Stack>
			)}
			{defectVisible && (
				<Stack direction="row" spacing={1} alignItems="center">
					<RoleWrapper keyName="fixDefect">
						<IconButton
							size="small"
							onClick={() =>
								setGetData({
									visible: true,
									comment: "",
									isProcessDefect: false,
									callback: (date) => {
										if (date) {
											authorizedPut(`/object/${annotation.id}/fix`, {
												fixed: date,
											}).then(() =>
												dispatch(
													updateAnnotations(
														currentProject.id,
														image,
														skyqraftHiddenSetting,
													),
												),
											);
										} else {
											authorizedDelete(`/object/${annotation.id}/fix`).then(
												() =>
													dispatch(
														updateAnnotations(
															currentProject.id,
															image,
															skyqraftHiddenSetting,
														),
													),
											);
										}
									},
								})
							}
						>
							<CalendarToday htmlColor="white" fontSize="small" />
						</IconButton>
					</RoleWrapper>
					<Typography variant="body1" fontSize="small">
						{fixed
							? `${
									translations.ImageViewer.DefectFixed[language]
							  } ${fixed.toDateString()}`
							: translations.ImageViewer.DefectNotFixed[language]}
					</Typography>
				</Stack>
			)}
			{processedPossible && defectIndexes.length > 0 && (
				<Stack>
					{defectIndexes.map((index, i) => (
						<>
							<Stack
								key={index}
								direction="row"
								spacing={1}
								alignItems="center"
							>
								<IconButton
									size="small"
									onClick={() =>
										setGetData({
											visible: true,
											comment: annotation.processed[index]?.comment,
											isProcessDefect: true,
											callback: (date, comment) => {
												//filter out html tags
												const filteredComment = comment.replace(
													/<\/?[^>]+(>|$)/g,
													"",
												);
												if (date) {
													authorizedPut(
														`/object/${annotation.id}/${annotation.types[index]}/processed`,
														{
															date,
															comment: filteredComment,
														},
													).then(() =>
														dispatch(
															updateAnnotations(
																currentProject.id,
																image,
																skyqraftHiddenSetting,
															),
														),
													);
												} else {
													authorizedDelete(
														`/object/${annotation.id}/${annotation.types[index]}/processed`,
													).then(() =>
														dispatch(
															updateAnnotations(
																currentProject.id,
																image,
																skyqraftHiddenSetting,
															),
														),
													);
												}
											},
										})
									}
								>
									<CalendarToday htmlColor="white" fontSize="small" />
								</IconButton>
								<Typography variant="body1" fontSize="small">
									{annotation.processed[index]
										? `${translations.ImageViewer.Processed[language]}${
												defectIndexes.length > 1
													? ` ${translations.words.For[language]} ${defectTypes[index]}`
													: ""
										  } ${new Date(
												annotation.processed[index].time,
										  ).toDateString()}`
										: `${translations.ImageViewer.NotProcessed[language]}${
												defectIndexes.length > 1
													? ` ${translations.words.For[language]} ${defectTypes[index]}`
													: ""
										  }`}
								</Typography>
							</Stack>
							{!!annotation.processed[index]?.comment && (
								<Box ml={5}>
									<Typography variant="body1" fontSize="small">
										{`${translations.DefectReport.Comment[language]}: ${annotation.processed[index].comment}`}
									</Typography>
								</Box>
							)}
						</>
					))}
				</Stack>
			)}
		</Stack>
	);
}
