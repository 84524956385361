import React from "react";
import RoleWrapper from "components/RoleHOC/wrapper";
import MenuItem from "components/MenuItem";

import { EditNotifications } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function Annotations() {
	const location = useLocation();
	const isAnnotate = location.pathname.includes("/annotate");

	let searchParams = new URLSearchParams(location.search);
	if (searchParams.has("pole")) {
		searchParams.delete("pole");
	}
	if (searchParams.has("box")) {
		searchParams.delete("box");
	}
	if (searchParams.has("addPoleItem")) {
		searchParams.delete("addPoleItem");
	}

	const searchString = searchParams.toString();
	const search = searchString ? `?${searchString}` : "";

	return (
		<RoleWrapper keyName="annotationToolOld">
			<MenuItem
				tooltip="Old annotation tool, soon to be deprecated"
				id="image-open-annotation-tool"
			>
				<Link
					to={`${isAnnotate ? "." : "annotate-dev"}${search}`}
					style={{ textDecoration: "none", color: "inherit" }}
				>
					<EditNotifications htmlColor="red" />
				</Link>
			</MenuItem>
		</RoleWrapper>
	);
}
