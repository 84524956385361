import {
	OBJECT_ZOOM,
	MAX_VISIBLE_OBJECT_DETECTION_MARKERS,
	EXTRA_LOADING_PERCENTAGE,
} from "config";
import store, { RootState } from "state/store";
import { authorizedGet, axiosInstance } from "utils/request";
import { setToastMessage, clearToastMessage, updatePoles } from "state/actions";
import { defaultValues } from "utils/filter";
import {
	getHeatmap,
	getImageMarkers,
	getUnfilteredMarkers,
	IProps,
} from "api/image/marker";
import { getFilterActors } from "hooks/filter/search_parameters/useActorsFilter";
import { getFilterClearanceAxes } from "hooks/filter/search_parameters/useClearanceAxesFilter";
import { getFilterArea } from "hooks/filter/search_parameters/useAreaFilter";
import { getClearanceRulesFilter } from "hooks/filter/search_parameters/useClearanceRulesFilter";
import { getDateDefectAddedFilter } from "hooks/filter/search_parameters/useDateDefectAddedFilter";
import { getDateDefectFixedFilter } from "hooks/filter/search_parameters/useDateDefectFixedFilter";
import { getDateDefectProcessedFilter } from "hooks/filter/search_parameters/useDateDefectProcessedFilter";
import { getDateDefectReportedFilter } from "hooks/filter/search_parameters/useDateDefectReportedFilter";
import { getDateImageCaptureFilter } from "hooks/filter/search_parameters/useDateImageCaptureFilter";
import { getDateImageUploadedFilter } from "hooks/filter/search_parameters/useDateImageUploadedFilter";
import { getFlaggedFilter } from "hooks/filter/search_parameters/useFlaggedFilter";
import { getFlightFilter } from "hooks/filter/search_parameters/useFlightFilter";
import { getListFilter } from "hooks/filter/search_parameters/useListFilter";
import { getNewDefectsFilter } from "hooks/filter/search_parameters/useNewDefectsFilter";
import { getObjectTypeFilter } from "hooks/filter/search_parameters/useObjectTypeFilter";
import { getSeverityFilter } from "hooks/filter/search_parameters/useSeverityFilter";
import {
	getDefaultValue,
	getSkyqraftHiddenFilter,
} from "hooks/filter/search_parameters/useSkyqraftHiddenFilter";
import { getWorkflowFilter } from "hooks/filter/search_parameters/useWorkflowFilter";
import { getShowDsoTsoFilter } from "hooks/filter/search_parameters/useShowDsoTsoFilter";
import { getFilterActive } from "hooks/filter/useFilterPlay";
import {
	isImageFilterPopulated,
	isLidarFilterPopulated,
} from "hooks/filter/useFilterPopulated";
import { getLidarMarkerValue } from "hooks/filter/search_parameters/useLidarMarkersFilter";

export const HEATMAP_MARKER_THRESHOLD = 1000;

function getBounds(gmap: google.maps.Map) {
	const innerbounds = gmap.getBounds().toJSON();
	const center = gmap.getCenter().toJSON();
	const screenWidth = center.lng - innerbounds.west;
	const screenHeight = center.lat - innerbounds.south;
	return {
		north: innerbounds.north + screenHeight * EXTRA_LOADING_PERCENTAGE,
		east: innerbounds.east + screenWidth * EXTRA_LOADING_PERCENTAGE,
		west: innerbounds.west - screenWidth * EXTRA_LOADING_PERCENTAGE,
		south: innerbounds.south - screenHeight * EXTRA_LOADING_PERCENTAGE,
	};
}

function getZoomLevel(gmap: google.maps.Map) {
	const zoom = gmap.getZoom();
	if (zoom < 15) {
		return "filters";
	} else if (zoom < 16) {
		return "poles";
	} else if (zoom < 17) {
		return "objects";
	} else {
		return "everything";
	}
}

export function setMarkers(markers) {
	return {
		type: "RECIEVE_MARKERS",
		payload: markers,
	};
}

export function setListViewMarkers(markers) {
	return {
		type: "RECIEVE_LIST_VIEW_MARKERS",
		payload: markers,
	};
}

export function setMarkersHighlight(value) {
	return {
		type: "SET_MARKERS_HIGHLIGHT",
		payload: value,
	};
}

export function setHeatmap(points) {
	return {
		type: "SET_HEATMAP",
		payload: points,
	};
}

export function setLidarDistance(points) {
	return {
		type: "SET_LIDAR_DISTANCE",
		payload: points,
	};
}

function getList(type: string) {
	const uriQuery = new URLSearchParams(window.location.search);
	const query = uriQuery.get(type);
	if (query) {
		return query.split(",").map((s) => parseInt(s));
	} else {
		return defaultValues[type];
	}
}

function getBool(type: string) {
	const uriQuery = new URLSearchParams(window.location.search);
	return !!uriQuery.get(type);
}

function getInt(type: string) {
	const uriQuery = new URLSearchParams(window.location.search);
	const query = uriQuery.get(type);
	if (query) {
		return parseInt(query);
	} else {
		return defaultValues[type];
	}
}

function getString(type: string) {
	const uriQuery = new URLSearchParams(window.location.search);
	const query = uriQuery.get(type);
	if (query) {
		return query;
	} else {
		return defaultValues[type];
	}
}

export function updateLidarMarkers() {
	return async (dispatch, getState) => {
		const state: RootState = getState();
		if (!state.map.gmap) return;
		if (!state.mission?.id) return;

		const gmap = state.map.gmap;
		const zoomLevel = getZoomLevel(gmap);
		const bounds = getBounds(gmap);

		const searchParams = new URLSearchParams(window.location.search);
		const lidarFilterActive = getLidarMarkerValue(searchParams);
		const filterActive =
			getFilterActive(searchParams) && isLidarFilterPopulated(searchParams);

		let shouldShowLidarMarkers = false;
		if (!state.ui.showLidarMarkers) shouldShowLidarMarkers = false;
		if (zoomLevel === "everything") shouldShowLidarMarkers = true;
		if (zoomLevel === "objects") shouldShowLidarMarkers = true;
		if (lidarFilterActive && filterActive) shouldShowLidarMarkers = true;
		if (zoomLevel === "filters" && filterActive) shouldShowLidarMarkers = true;
		if (zoomLevel === "poles" && filterActive) shouldShowLidarMarkers = true;

		if (!shouldShowLidarMarkers) {
			dispatch(setLidarMarkers([]));
			return;
		}

		const lidarMarkersPayload: {
			area?: string;
			clearanceAxes?: string;
			clearanceRules?: string;
			severity?: string;
			north: number;
			south: number;
			west: number;
			east: number;
		} = {
			...bounds,
		};
		if (filterActive) {
			const areaFilter = getFilterArea(searchParams);
			if (areaFilter.length > 0)
				lidarMarkersPayload.area = getFilterArea(searchParams);
			const clearanceAxes = getFilterClearanceAxes(searchParams);
			if (clearanceAxes.length > 0)
				lidarMarkersPayload.clearanceAxes = clearanceAxes.join(",");

			const clearanceRules = getClearanceRulesFilter(searchParams);
			if (clearanceRules.length > 0)
				lidarMarkersPayload.clearanceRules = clearanceRules.join(",");

			const severity = getSeverityFilter(searchParams);
			if (severity.length > 0) {
				lidarMarkersPayload.severity = severity.join(",");
			}
		}

		const response = await axiosInstance.get<{ markers }>("/marker/lidar", {
			params: lidarMarkersPayload,
			headers: {
				MissionID: state.mission.id,
			},
		});
		if (response.status !== 200) {
			dispatch(setLidarMarkers([]));
			return;
		}
		dispatch(setLidarMarkers(response.data.markers));
	};
}

function setLidarMarkers(markers) {
	return {
		type: "SET_LIDAR_MARKERS",
		payload: markers,
	};
}

function updateClearanceMarkers() {
	return async (dispatch, getState) => {
		const state: RootState = getState();
		if (!state.ui.showClearanceMarkers) {
			return;
		}
		const gmap = state.map.gmap;
		const zoom = gmap.getZoom();
		const severity = getList("severity");
		const bounds = getBounds(gmap);
		const cid = state.mission?.id ?? -1;
		if (
			cid > 0 &&
			state.ui.showClearanceMarkers &&
			(zoom > OBJECT_ZOOM || severity.length > 0)
		) {
			const urlParams = new URLSearchParams(window.location.search);
			urlParams.set("north", JSON.stringify(bounds.north));
			urlParams.set("south", JSON.stringify(bounds.south));
			urlParams.set("west", JSON.stringify(bounds.west));
			urlParams.set("east", JSON.stringify(bounds.east));
			const requestURI = `/marker/lidar/clearance?${urlParams.toString()}`;
			const response = await authorizedGet<{ markers }>(requestURI);
			if (!!response && !!response.markers) {
				dispatch(setClearanceMarkers(response.markers));
			}
		} else {
			dispatch(setClearanceMarkers([]));
		}
	};
}

function setClearanceMarkers(markers) {
	return {
		type: "SET_CLEARANCE_MARKERS",
		payload: markers,
	};
}

export function updateHeatmap() {
	return async (dispatch, getState) => {
		const state: RootState = getState();
		const gmap = state.map.gmap;
		const cid = state.mission?.id ?? -1;

		// Skip if google maps isnt loaded
		if (!gmap) {
			return;
		}
		// Skip if we have no project ID
		if (!cid || !Number.isInteger(cid)) {
			return;
		}
		// Skip if user has disabled images
		if (state.map.visibleImageTypes.length === 0) {
			return;
		}

		// Collect the map coordinates
		const bounds = getBounds(gmap);

		const oldController = state.map.markerController;
		if (oldController) {
			oldController.abort();
		}

		const newController = new AbortController();
		dispatch(setMarkerController(newController));
		// Collect the markers

		try {
			const { positions } = await getHeatmap(cid, bounds, newController);
			// Clear markers and then set heatmap
			dispatch(setHeatmap(positions));
		} catch (error) {
			if (error.name === "AbortError" || error.name === "CanceledError") {
				// The request was aborted, we will not update the markers
				return;
			} else {
				throw error;
			}
		}
		dispatch(setMarkerController(null));
	};
}

export function updateUnfilteredMarkers() {
	return async (dispatch, getState) => {
		const state: RootState = getState();
		const gmap = state.map.gmap;
		const cid = state.mission?.id ?? -1;
		const heatmapEnabled = state.ui.heatmapEnabled;

		// Skip if google maps isnt loaded
		if (!gmap) {
			return;
		}
		// Skip if we have no project ID
		if (!cid || !Number.isInteger(cid)) {
			return;
		}
		// Skip if user has disabled images
		if (state.map.visibleImageTypes.length === 0) {
			return;
		}

		// Collect the map coordinates
		const bounds = getBounds(gmap);

		const oldController = state.map.markerController;
		if (oldController) {
			oldController.abort();
		}

		const newController = new AbortController();
		dispatch(setMarkerController(newController));
		// Collect the markers

		try {
			const { markers } = await getUnfilteredMarkers(
				cid,
				bounds,
				newController,
			);
			// Clear markers and then set heatmap
			dispatch(setMarkers(markers));

			const nMarkers = markers.length;
			// If we have more than 1000 markers, we will show a heatmap
			if (nMarkers > HEATMAP_MARKER_THRESHOLD) {
				if (heatmapEnabled) {
					dispatch(setMarkerController(null));
					return dispatch(
						setHeatmap(markers.map((m) => ({ lat: m.lat, lng: m.lng }))),
					);
				}
				dispatch(setMarkerController(null));
				return dispatch(setMarkers(markers));
			}

			dispatch(setMarkers(markers));
		} catch (error) {
			if (error.name === "AbortError" || error.name === "CanceledError") {
				// The request was aborted, we will not update the markers
				return;
			} else {
				throw error;
			}
		}
		dispatch(setMarkerController(null));
	};
}

export function updateImageMarkers() {
	return async (dispatch, getState) => {
		const state: RootState = getState();
		const heatmapEnabled = state.ui.heatmapEnabled;
		const gmap = state.map.gmap;
		const cid = state.mission?.id ?? -1;
		// Skip if google maps isnt loaded
		if (!gmap) {
			return;
		}
		// Skip if we have no project ID
		if (!cid || !Number.isInteger(cid)) {
			return;
		}
		// Skip if user has disabled images
		if (state.map.visibleImageTypes.length === 0) {
			return;
		}
		// Skip if the user is on the powerline page
		if (window.location.pathname.includes("/upload/powerlines")) {
			return;
		}
		// Collect the map coordinates
		const bounds = getBounds(gmap);
		const zoom = gmap.getZoom();

		const searchParams = new URLSearchParams(window.location.search);
		const filterActive =
			getFilterActive(searchParams) && isImageFilterPopulated(searchParams);
		const isZoomedOut = zoom <= OBJECT_ZOOM;
		const hasPowerlines = state.map.hasPowerlines;
		const powerlinesLoaded = state.map.powerlinesLoaded;

		// Skip zoomed out requests when filter is not active
		// and there are no powerlines but the client has checked
		// for powerlines

		if (isZoomedOut && !filterActive) {
			if (!hasPowerlines && powerlinesLoaded) {
				return dispatch(updateUnfilteredMarkers());
			} else {
				return dispatch(setMarkers([]));
			}
		}

		// Reset markers to avoid bugs
		const { north, east, west, south } = bounds;

		const filterPayload: IProps = {
			projectID: cid,
			north,
			east,
			west,
			south,
			actor: getFilterActors(searchParams),
			area: getFilterArea(searchParams),
			clearanceAxes: getFilterClearanceAxes(searchParams),
			clearanceRules: getClearanceRulesFilter(searchParams),
			dateDefectAdded: getDateDefectAddedFilter(searchParams),
			dateDefectFixed: getDateDefectFixedFilter(searchParams),
			dateDefectProcessed: getDateDefectProcessedFilter(searchParams),
			dateDefectReported: getDateDefectReportedFilter(searchParams),
			dateImageCapture: getDateImageCaptureFilter(searchParams),
			dateImageUploaded: getDateImageUploadedFilter(searchParams),
			flagged: getFlaggedFilter(searchParams),
			flightIDs: getFlightFilter(searchParams),
			imageLists: getListFilter(searchParams),
			new: getNewDefectsFilter(searchParams),
			detection: getObjectTypeFilter(searchParams),
			severity: getSeverityFilter(searchParams),
			showDsoTso: getShowDsoTsoFilter(searchParams),
			skyqraftHidden: getSkyqraftHiddenFilter(
				searchParams,
				getDefaultValue(state.user.skyqraft_employee),
			),
			workflowStatus: getWorkflowFilter(searchParams),
			filteredOnly: zoom < OBJECT_ZOOM,
			filterActive: getFilterActive(searchParams),
		};

		const oldController = state.map.markerController;
		if (oldController) {
			oldController.abort();
		}

		const newController = new AbortController();
		dispatch(setMarkerController(newController));

		// Collect the markers
		type IIMageMarker = Awaited<
			ReturnType<typeof getImageMarkers>
		>["data"][number];
		let imageMetas: IIMageMarker[];
		try {
			const { data } = await getImageMarkers(filterPayload, newController);
			imageMetas = data;
		} catch (error) {
			if (error.name === "AbortError" || error.name === "CanceledError") {
				// The request was aborted, we will not update the markers
				return;
			} else {
				throw error;
			}
		}

		const markersToRender = imageMetas.filter((i) => {
			if (OBJECT_ZOOM > zoom) {
				// If we are zoomed out, we will only show the filtered markers
				return i.is_filtered;
			} else {
				// If we are zoomed in, we will show all the markers
				return true;
			}
		});
		dispatch(setMarkerController(null));

		// Decide what to do with the markers
		const nMarkers = markersToRender.length;
		// If we have more than 1000 markers, we will show a heatmap
		if (nMarkers > HEATMAP_MARKER_THRESHOLD) {
			if (heatmapEnabled) {
				return dispatch(
					setHeatmap(markersToRender.map((m) => ({ lat: m.lat, lng: m.lng }))),
				);
			}
			return dispatch(setMarkers(markersToRender));
		}
		// If we have less than 1000 markers, we will show the markers
		return dispatch(
			setMarkers(
				markersToRender.map((i) => {
					return { ...i, separateHumanMachine: false, heatmap: false };
				}),
			),
		);
	};
}

export function updateListViewMarkers() {
	return async (dispatch, getState) => {
		const state: RootState = getState();
		const cid = state.mission?.id ?? -1;
		const markersVisible = state.map.visibleImageTypes.length > 0;
		const hasPowerlines = state.map.hasPowerlines;
		const powerlinesLoaded = state.map.powerlinesLoaded;
		const gmap = state.map.gmap;
		if (!gmap) {
			return;
		}
		const page = state.map.listViewPage;
		const zoom = gmap.getZoom();

		// Extract filter settings from URI
		const lists = getList("list");
		const defect = getList("defect");
		const severity = getList("severity");
		const hideFixed = getBool("hideFixed");
		const objects = getList("detection");
		const image_type = getString("image_type");
		const flagged = getBool("flagged");
		const newDefects = getBool("new");
		const confidence = getList("confidence");
		const date = getString("date");
		const annotator = getInt("annotator");
		const workflow = getList("workflow");
		const flight = getList("flight");
		const origin = getString("origin");
		const area = getString("area");
		const creationStart = getString("creationStart");
		const creationEnd = getString("creationEnd");
		const processedStart = getString("processedStart");
		const processedEnd = getString("processedEnd");
		const skyqraftHidden = getString("skyqraftHidden");

		const active =
			lists.length > 0 ||
			defect.length > 0 ||
			flight.length > 0 ||
			severity.length > 0 ||
			objects.length > 0 ||
			workflow.length > 0 ||
			creationStart.length > 0 ||
			creationEnd.length > 0 ||
			processedStart.length > 0 ||
			processedEnd.length > 0 ||
			area.length > 0 ||
			JSON.stringify(image_type) !== JSON.stringify(defaultValues.image_type) ||
			flagged ||
			newDefects ||
			skyqraftHidden ||
			JSON.stringify(confidence) !== JSON.stringify(defaultValues.confidence) ||
			date !== defaultValues.date ||
			annotator !== defaultValues.annotator ||
			hideFixed ||
			origin !== defaultValues.origin;
		// Create the fetch URI
		if (
			markersVisible &&
			cid > 0 &&
			((!hasPowerlines && powerlinesLoaded) || zoom >= OBJECT_ZOOM || active)
		) {
			const urlParams = new URLSearchParams(window.location.search);
			urlParams.set("filteractive", JSON.stringify(active));
			urlParams.set("filteredOnly", "true");
			urlParams.set("page", page.toString());
			const requestURI = `/image/markers?${urlParams.toString()}`;

			const oldController = state.map.markerController;
			if (oldController) {
				oldController.abort();
			}

			const newController = new AbortController();
			dispatch(setMarkerController(newController));
			newController.signal.onabort = () => {};

			authorizedGet<{ data; pages }>(
				requestURI,
				{},
				{ signal: newController.signal },
			)
				.then((json) => {
					const imageMetas = json.data;
					const pages = json.pages;
					const filteredMarkers = imageMetas.filter((m) => m.is_filtered);
					dispatch(setMarkerController(null));

					if (imageMetas.length < MAX_VISIBLE_OBJECT_DETECTION_MARKERS) {
						dispatch(clearToastMessage());
						return dispatch(
							setListViewMarkers(
								imageMetas.map((i) => {
									return {
										...i,
										separateHumanMachine: false,
										heatmap: false,
										pages: pages,
									};
								}),
							),
						);
					} else if (
						filteredMarkers.length < MAX_VISIBLE_OBJECT_DETECTION_MARKERS ||
						zoom > OBJECT_ZOOM
					) {
						dispatch(clearToastMessage());
						return dispatch(
							setListViewMarkers(
								filteredMarkers.map((i) => {
									return {
										...i,
										separateHumanMachine: false,
										heatmap: false,
										pages: pages,
									};
								}),
							),
						);
					} else if (active || !hasPowerlines) {
						dispatch(
							setToastMessage(
								`${filteredMarkers.length} objects in selection. Zoom in to less than ${MAX_VISIBLE_OBJECT_DETECTION_MARKERS} `,
							),
						);
						return dispatch(
							setListViewMarkers(
								filteredMarkers.map((i) => {
									return {
										...i,
										separateHumanMachine: false,
										heatmap: true,
										pages: pages,
									};
								}),
							),
						);
					} else {
						dispatch(
							setToastMessage(
								`${filteredMarkers.length} objects in selection. Zoom in to less than ${MAX_VISIBLE_OBJECT_DETECTION_MARKERS} `,
							),
						);
						return dispatch(setListViewMarkers([]));
					}
				})
				.catch(() => {
					dispatch(setListViewMarkers([]));
				});
		} else {
			dispatch(setToastMessage("Zoom In to see markers."));
			return dispatch(setListViewMarkers([]));
		}
	};
}

export function updateMarkers() {
	return (dispatch, getState) => {
		const state: RootState = getState();
		dispatch(updatePoles());
		dispatch(updateClearanceMarkers());
		dispatch(updateLidarMarkers());
		if (state.ui.showListView) {
			dispatch(updateListViewMarkers());
		} else {
			dispatch(updateImageMarkers());
		}
	};
}

export function setVisibleImageTypes(types: number[]) {
	return {
		type: "SET_VISIBLE_IMAGE_TYPES",
		payload: types,
	};
}

export function setMarkerController(controller: AbortController | null) {
	return {
		type: "SET_MARKER_CONTROLLER",
		payload: controller,
	};
}

export function setPowerlineController(controller: AbortController | null) {
	return {
		type: "SET_POWERLINE_CONTROLLER",
		payload: controller,
	};
}

export function setListViewExpandPoleId(types: number[]) {
	return {
		type: "SET_LIST_VIEW_EXPAND_POLE_ID",
		payload: types,
	};
}

export function setListViewExpandImageId(types: number[]) {
	return {
		type: "SET_LIST_VIEW_EXPAND_IMAGE_ID",
		payload: types,
	};
}

export function setListViewPage(types: number) {
	return {
		type: "SET_LIST_VIEW_PAGE",
		payload: types,
	};
}

export function setListViewPoleImageToggle(types: boolean) {
	return {
		type: "SET_LIST_VIEW_POLE_IMAGE_TOGGLE",
		payload: types,
	};
}

export function resetOpenedMarkers() {
	return setOpenedMarkers([]);
}

export function addOpenedMarker(markerID: number) {
	const existingMarkers: number[] = store.getState().map.openedMarkers;
	existingMarkers.push(markerID);
	return setOpenedMarkers(existingMarkers);
}

export function setOpenedMarkers(markerIDs: number[]) {
	return {
		type: "SET_OPENED_MARKERS",
		payload: markerIDs,
	};
}
