import { connect } from "react-redux";
import SelectedItem from "./SelectedItem";
import { RootState } from "state/store";

interface IStateProps {
  objectTypes;
  issueSeverities;
  issueCategories;
  user;
  annotatorObjectColor;
}

interface IDispatchProps {}

interface IOwnProps {
  region;
  onChangeRegion: (newRegion: any) => void;
  index;
  ml_class: boolean;
  savedRegions;
}

const mapStateToProps = (state: RootState) => {
  const language = state.user.language;
  return {
    objectTypes: state.objects.objectTypes
      .filter((t) => !t.skyqraft_only || state.user.skyqraft_employee)
      .map((item) => ({
        ...item,
        name: item[`${language.toLowerCase()}_name`],
      })),
    issueSeverities: state.objects.issueSeverities.map((item) => ({
      ...item,
      name: item[`${language.toLowerCase()}_name`],
    })),
    issueCategories: state.objects.issueCategories.map((item) => ({
      ...item,
      name: item[`${language.toLowerCase()}_name`],
    })),
    user: state.user,
    annotatorObjectColor: state.user.annotator_color,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(SelectedItem);
