import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Chip, Divider, Box, CircularProgress } from "@mui/material";
import { useLanguage, useSelector } from "hooks";
import { groupBy, mapValues } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { getClientObjectTypeName } from "utils/utils";
import { AnnotationContext } from "../../provider";
import ListItem from "./ListItem/ListItem";
import { checkRegionUpdate } from "./Utils";

export default function AnotationsList() {
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const { language } = useLanguage();
  const {
    annotations,
    originalAnnotations,
    loading,
    setSelectedAnnotation,
    highlightedId,
  } = useContext(AnnotationContext);

  const [memoSortedRegions, setMemoSortedRegions] = useState([]);
  const [defectRegions, setDefectRegions] = useState([]);
  const [objectRegions, setObjectRegions] = useState([]);

  let newRegions = memoSortedRegions;

  const objectTypesNew = objectTypes.map((e) => getClientObjectTypeName(e.id));

  //This is for the ordering and reordering of the list
  const sortedRegions = useMemo(() => {
    const shouldReOrderList =
      annotations
        .map((e) => checkRegionUpdate(e, originalAnnotations, objectTypesNew))
        .filter((f) => !f.saved).length > 0;

    const sorted = [...annotations].sort((a, b) => {
      //New objects contains n, sort them first
      if (a.id.includes("n") && !b.id.includes("n")) {
        return -1;
      }
      if (!a.id.includes("n") && b.id.includes("n")) {
        return 1;
      }

      return (
        Math.min(...a.severities.filter((c) => !!c)) -
          Math.min(...b.severities.filter((c) => !!c)) ||
        (!!a.types[0] &&
        objectTypesNew.find((ot) => ot.id === a.types[0]).name >
          (!!b.types[0] &&
            objectTypesNew.find((ot) => ot.id === b.types[0]).name)
          ? 1
          : -1)
      );
    });

    //Remember objects order before save
    const sortedMemo = memoSortedRegions
      .map((a) => {
        const newRegions = [...annotations].filter((f) => f.id === a.id);

        return newRegions[0];
      })
      .filter((f) => !!f);

    [...annotations].map((r) => {
      if (r.id.includes("n") && !sortedMemo.find((e) => e.id === r.id)) {
        sortedMemo.unshift(r);
      }
    });

    if (shouldReOrderList) {
      newRegions = sorted;
    } else {
      newRegions = sortedMemo;
    }

    setMemoSortedRegions(newRegions);

    return newRegions;
  }, [annotations, language, objectTypes, originalAnnotations]);

  const groupedIds = [];

  useEffect(() => {
    setDefectRegions(sortedRegions.filter((r) => r.is_defect.includes(true)));
    setObjectRegions(sortedRegions.filter((r) => r.is_defect.every((i) => !i)));

    //Group same objects together
    objectGroups.map((e, i) =>
      e.map((h) => !!h.highlighted).some((c) => c === true)
        ? e.map((f, index) =>
            groupedIds.push({
              object: [f],
              length: 1,
              ExpandedPosition:
                e.length > 1 &&
                (e.length - 1 === index ? "last" : index === 0 && "first"),
            })
          )
        : groupedIds.push({ object: e, length: e.length })
    );
  }, [sortedRegions]);

  const objectRegionsUnOrdered = mapValues(objectRegions, (val) => {
    const clientTypes = val.types.map(
      (v) => getClientObjectTypeName(v).client_object_type_id
    );
    return {
      ...val,
      client_types: clientTypes,
    };
  });

  let objectGroups = groupBy(objectRegionsUnOrdered, "client_types");
  objectGroups = Object.values(objectGroups);

  objectGroups.sort((a, b) => {
    const indexA = sortedRegions.findIndex((obj) => obj.id === a[0].id);
    const indexB = sortedRegions.findIndex((obj) => obj.id === b[0].id);

    if (indexA < indexB) {
      return -1;
    }
    if (indexA > indexB) {
      return 1;
    }
    return 0;
  });

  objectGroups.map((e) =>
    e.map((h) => !!h.highlighted).some((c) => c === true)
      ? e.map((f, index) =>
          groupedIds.push({
            object: [f],
            length: 1,
            ExpandedPosition:
              e.length > 1 &&
              (e.length - 1 === index ? "last" : index === 0 && "first"),
          })
        )
      : groupedIds.push({ object: e, length: e.length })
  );

  useEffect(() => {
    // Scroll active item into view
    if (highlightedId) {
      const listItemRef = document.querySelector(".list-item.active");
      if (listItemRef) {
        setTimeout(() => {
          listItemRef.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 500);
      }
    }
  }, [highlightedId]);

  return (
    <Box
      sx={{
        width: "377px",
        height: "100%",
        overflowY: "auto",
        flexShrink: 0,
        display: objectTypes.length > 0 && loading === false ? "block" : "flex",
        alignItems:
          objectTypes.length > 0 && loading === false ? "start" : "center",
        justifyContent: "center",
      }}
    >
      {objectTypes.length > 0 && loading === false ? (
        <div>
          {defectRegions.length > 0 && (
            <Divider sx={{ py: 1 }}>
              <Chip label="Defects" />
            </Divider>
          )}
          {defectRegions.map((region) => {
            return <ListItem key={region.id} annotation={region} />;
          })}
          {objectRegions.length > 0 && (
            <Divider sx={{ py: 1 }}>
              <Chip label="Objects" />
            </Divider>
          )}
          {groupedIds.map((regionIn) => {
            const region = regionIn.object[0];
            return (
              <>
                {regionIn.ExpandedPosition === "first" && (
                  <Divider sx={{ my: 1, mx: 2 }} />
                )}

                {regionIn.length > 1 ? (
                  <ListItem
                    key={region.id}
                    annotation={region}
                    expandLength={regionIn.length}
                  />
                ) : (
                  <ListItem key={region.id} annotation={region} />
                )}
                {regionIn.ExpandedPosition === "last" && (
                  <Divider sx={{ py: 0, mx: 2 }}>
                    <Chip
                      label={<ExpandLessIcon htmlColor="#616161" />}
                      size="small"
                      onClick={() => {
                        setSelectedAnnotation(null);
                      }}
                    />
                  </Divider>
                )}
              </>
            );
          })}
        </div>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}
