import { useContext } from "react";
import { IconButton, Tooltip, CircularProgress } from "@mui/material";
import { Save, Done, ErrorOutline } from "@mui/icons-material";
import { AnnotationContext } from "../../provider";
import { canSaveObject, regionsAreEqual } from "../AnnotationsList/Utils";
import { ObjectType } from "interfaces";
import { useLanguage } from "hooks";

interface IProps {
  objectTypes: ObjectType[];
}

export default function SaveButton({ objectTypes }: IProps) {
  const { saveAnnotations, annotations, originalAnnotations, saveFeedback } =
    useContext(AnnotationContext);

  const { language } = useLanguage();

  var canSaveArray = annotations?.map((region) => {
    let result = canSaveObject(region, objectTypes);
    return result;
  });

  var saveErrorMessage = canSaveArray?.find((value) => value.canSave === false)
    ? canSaveArray.find((value) => value.canSave === false).message
    : "Save";

  var canSave = canSaveArray?.every((region) => region.canSave);

  let somethingChanged = regionsAreEqual(annotations, originalAnnotations);

  function renderSaveIcon() {
    if (saveFeedback === "") {
      return <Save />;
    } else if (saveFeedback === "loading") {
      return (
        <CircularProgress
          size={20}
          sx={{ marginTop: "2px", marginLeft: "2px" }}
        />
      );
    } else if (saveFeedback === "saved") {
      return <Done htmlColor="green" />;
    } else if (saveFeedback === "error") {
      return <ErrorOutline htmlColor="red" />;
    }
  }

  return (
    <Tooltip title={saveErrorMessage} arrow placement="top" disableInteractive>
      <span>
        <IconButton
          onClick={() => saveAnnotations(annotations, originalAnnotations)}
          disabled={!canSave || somethingChanged}
          sx={{ width: "40px" }}
        >
          {renderSaveIcon()}
        </IconButton>
      </span>
    </Tooltip>
  );
}
