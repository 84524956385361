import React, { ReactElement, useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RoleWrapper from "components/RoleHOC/wrapper";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { Box, AppBar, IconButton, Stack, Tab, Tabs } from "@mui/material";
import { Close } from "@mui/icons-material";
import translations from "translations";
import StatusBar from "components/StatusBar";
import { context } from "providers/NavigationProvider";
import { useLanguage } from "hooks";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#0078EB",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
  },
};

export default function Download() {
  const location = useLocation();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const { lastURL } = useContext(context);
  let tab: boolean | number = false;

  if (location.pathname.includes("/download/powerlines")) {
    tab = 0;
  } else if (location.pathname.includes("/download/imageobjects")) {
    tab = 1;
  }

  return (
    <RoleWrapper keyName="downloadData">
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            direction: "row",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            zIndex: 100,
          }}
        >
          <Box
            sx={{
              height: "100%",
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <StatusBar
              title={translations.Menu.Tools.Download[language]}
              sx={{ zIndex: 10 }}
            />
            <AppBar
              component="div"
              position="static"
              elevation={0}
              sx={{ zIndex: 0 }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tabs value={tab} textColor="inherit">
                  <Link
                    to="powerlines"
                    replace
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Tab label={translations.Menu.Tools.Powerlines[language]} />
                  </Link>
                  <Link
                    to="imageobjects"
                    replace
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Tab
                      label={translations.Menu.Tools.ImageObjects[language]}
                    />
                  </Link>
                </Tabs>

                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="close"
                  onClick={() => {
                    if (lastURL) {
                      navigate(-1);
                    } else {
                      navigate("/");
                    }
                  }}
                >
                  <Close />
                </IconButton>
              </Stack>
            </AppBar>

            <Box
              sx={{
                flex: 1,
                py: 2,
                px: 4,
                bgcolor: "#eaeff1",
                overflowY: "auto",
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </RoleWrapper>
  );
}
