import { Stack } from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import {
  useCurrentProject,
  useDispatch,
  useSelector,
  useTranslation,
} from "hooks";
import { ImageMeta, PreviousDefectImages } from "interfaces";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import AnnotationPreview, { ReviewMode } from "views/image/AnnotationPreview";
import ViewerController from "views/image/ViewerController";
import PoleViewListener from "./PoleViewListener";
import { updateAnnotations, getImageMeta } from "state/actions";
import "./style.scss";
import { Chip } from "@mui/material";

type IProps = {
  clearImageMeta: () => void;
  resetThermalData: () => void;
  gmap: google.maps.Map;
  imageMeta: ImageMeta;
  addOpenedMarker: (marker_id: number) => void;
  initialURL: string;
  setInitialURL: (value: string) => void;
  selectedPreviousDefectImage: PreviousDefectImages | null;
  setSelectedPreviousDefectImage: (image: PreviousDefectImages | null) => void;
};

export default function ImageViewer({
  clearImageMeta,
  gmap,
  imageMeta,
  resetThermalData,
  addOpenedMarker,
  initialURL,
  setInitialURL,
  selectedPreviousDefectImage,
  setSelectedPreviousDefectImage,
}: IProps) {
  const params = useParams();
  const location = useLocation();
  const skyqraftHiddenSetting = useSelector(
    (state) => state.user.skyqraft_hidden
  );
  const ref = useRef<HTMLDivElement>();
  const image = parseInt(params.image);
  const currentProject = useCurrentProject();
  const dispatch = useDispatch();
  const previewImageTranslation = useTranslation("PreviewImagePrevDefect");

  useEffect(() => {
    image && addOpenedMarker(image);
  }, [image, addOpenedMarker]);

  useEffect(() => {
    if (currentProject?.id) {
      if (imageMeta?.id !== image) {
        clearImageMeta();
        resetThermalData();
      }
      dispatch(getImageMeta(currentProject.id, image));
      return () => {
        clearImageMeta();
        resetThermalData();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getImageMeta,
    currentProject?.id,
    image,
    clearImageMeta,
    resetThermalData,
  ]);

  // Update annotations if we switch path.
  // This happens when you enter or exit a tool.
  // At the moment this causes one redundant fetch of annotations when switching image.
  useEffect(() => {
    if (currentProject) {
      dispatch(
        updateAnnotations(currentProject.id, image, skyqraftHiddenSetting)
      );
    }
  }, [
    location.pathname,
    currentProject?.id,
    updateAnnotations,
    skyqraftHiddenSetting,
  ]);

  const [widthEditor, setWidthEditor] = useState<number>(0.8);
  const annotationToolOpen = location.pathname.includes("/annotate");

  let reviewMode = ReviewMode.None;
  if (location.pathname.includes("/supervisor-review")) {
    reviewMode = ReviewMode.SupervisorReview;
  } else if (location.pathname.includes("/machine-review")) {
    reviewMode = ReviewMode.MachineReview;
  } else if (location.pathname.includes("/falsepositive-review")) {
    reviewMode = ReviewMode.FalsePositiveReview;
  } else if (location.pathname.includes("/superfalsepositive-review")) {
    reviewMode = ReviewMode.SuperFalsePositiveReview;
  }
  const modeOpen = reviewMode !== ReviewMode.None;

  const [width, setWidth] = useState<number>(0.5);

  const [shouldZoomIn, setShouldZoomIn] = useState(false);

  useEffect(() => {
    if (initialURL === window.location.pathname) {
      setShouldZoomIn(true);
      setInitialURL("");
    } else {
      setInitialURL("");
    }
  }, [initialURL, setInitialURL]);

  useEffect(() => {
    modeOpen && setWidth(0.8);
  }, [modeOpen]);

  useEffect(() => {
    if (gmap && !!imageMeta?.lat) {
      if (shouldZoomIn) {
        gmap.panTo({ lat: imageMeta.lat, lng: imageMeta.lng });
        gmap.setZoom(18);
        const referenceWidth = annotationToolOpen ? widthEditor : width;
        const slit = 1 - referenceWidth;
        const offset = referenceWidth - 0.5;
        const x = offset + slit / 2;
        gmap.panBy(-x * window.innerWidth, 0);
        setShouldZoomIn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageMeta, gmap, shouldZoomIn]);

  function resizeViewer(e) {
    const mainView = document.getElementById("map");
    let pageX;

    if (e.type === "touchmove") {
      pageX = e.touches[0].pageX;
    } else {
      pageX = e.pageX;
    }

    const box = mainView.getBoundingClientRect();

    const MARGIN = box.width > 768 ? 150 : 50;

    if (box.width - (pageX - box.x) < MARGIN) {
      pageX = box.width + box.x - MARGIN;
    } else if (pageX - box.x < MARGIN) {
      pageX = box.x + MARGIN;
    }

    const x_percent = (pageX - box.x) / box.width;

    annotationToolOpen ? setWidthEditor(x_percent) : setWidth(x_percent);
  }

  function onResizeDown() {
    window.addEventListener("mousemove", resizeViewer);
    window.addEventListener("mouseup", onResizeUp);
    window.addEventListener("blur", onResizeUp);
  }

  function onResizeUp() {
    window.removeEventListener("mousemove", resizeViewer);
    window.removeEventListener("mouseup", onResizeUp);
    window.removeEventListener("blur", onResizeUp);
  }

  function onResizeDownTouch() {
    window.addEventListener("touchmove", resizeViewer);
    window.addEventListener("touchend", onResizeUpTouch);
    window.addEventListener("blur", onResizeUpTouch);
  }
  function onResizeUpTouch() {
    window.removeEventListener("touchmove", resizeViewer);
    window.removeEventListener("touchend", onResizeUpTouch);
    window.removeEventListener("blur", onResizeUpTouch);
  }
  const viewerWidth = (window.innerWidth - 50) * width;

  function exitPreviouseDefectImage() {
    setSelectedPreviousDefectImage(null);
    dispatch(getImageMeta(currentProject?.id, image));
  }

  return (
    <>
      <Stack
        direction="row"
        sx={{
          position: "absolute",
          width: "100%",
          height: "calc( 100% - 52px )",
          top: "52px",
          zIndex: 100,
        }}
        style={{ pointerEvents: "none" }}
      >
        <div
          id="imageViewer"
          style={{
            position: "relative",
            width: `${
              100 *
              (annotationToolOpen
                ? widthEditor < 0.5
                  ? 0.5
                  : widthEditor
                : width)
            }%`,
            pointerEvents: "all",
          }}
          ref={ref}
          onMouseUp={(e) => e.stopPropagation()}
        >
          {!annotationToolOpen ? (
            <>
              <AnnotationPreview widthScale={width} reviewMode={reviewMode} />
              {!modeOpen && selectedPreviousDefectImage === null && (
                <ViewerController width={viewerWidth} />
              )}
              {selectedPreviousDefectImage && (
                <Chip
                  sx={{ position: "absolute", bottom: 20, left: 200 }}
                  label={previewImageTranslation}
                  color="info"
                  onDelete={exitPreviouseDefectImage}
                />
              )}
            </>
          ) : (
            <Outlet />
          )}
        </div>
        <div
          className="divider noselect nodrag"
          onMouseDown={onResizeDown}
          onMouseUp={onResizeUp}
          onTouchStart={onResizeDownTouch}
          onTouchEnd={onResizeUpTouch}
        />
        <RoleWrapper keyName="updatePoleVisibility">
          <PoleViewListener />
        </RoleWrapper>
        {!annotationToolOpen && (
          <div
            style={{
              display: "flex",
              pointerEvents: "all",
              height: "fit-content",
            }}
          />
        )}
      </Stack>
    </>
  );
}
