import React, { useEffect, useState } from "react";
import {
  IconButton,
  Paper,
  Divider,
  Typography,
  Container,
  TextField,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import {
  Edit,
  Save,
  Close,
  Done,
  ErrorOutline,
  Delete,
  LinkOff,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useLanguage } from "hooks";
import { getTranslation } from "utils/translations";

import {
  getClientObjectTypes,
  addClientIssueSeverityType,
  addClientIssueSeverities,
  deleteClientIssueSeverityTypeMap,
  checkClientIssueSeverityTypeMap,
} from "./api";

type Props = {
  objectTypes: any;
  getClientObjects: Function;
  clientObjects;
  selectedGroup: number | null;
};

export default function ObjectTypes({
  objectTypes,
  getClientObjects,
  clientObjects,
  selectedGroup,
}: Props) {
  const { language } = useLanguage();

  const titles = [
    "",
    "Id",
    getTranslation("Name", language),
    getTranslation("InternalId", language),
    getTranslation("Edit", language),
  ];

  const [editId, setEditId] = useState(null);
  const [newValues, setNewValues] = useState(null);
  const [selectedClientType, setSelectedClientType] = useState(null);
  const [saveFeedback, setSaveFeedback] = useState("");
  const [deleteType, setDeleteType] = useState("");

  let filteredObjects = objectTypes.filter((e) => e.id < 5);

  // add clinetObject values to the filteredObjects
  filteredObjects = filteredObjects.map((e) => {
    const clientObject = clientObjects.filter(
      (obj) => obj.skyqraft_issue_severity_id === e.id
    )[0];
    if (!!clientObject) {
      return {
        ...e,
        client_id: clientObject.id,
        client_issue_severity_id: clientObject.client_issue_severity_id,
        client_name: clientObject.client_issue_severity_name,
        client_external_type_id: clientObject.external_type_id,
        client_group_id: clientObject.group_id,
        client_skyqraft_issue_severity_id:
          clientObject.skyqraft_issue_severity_id,
      };
    }
    return {
      ...e,
      client_id: null,
      client_object_type_id: null,
      client_category_id: null,
      client_category_se_name: null,
      client_category_en_name: null,
      client_name: null,
      client_color: null,
      client_external_type_id: null,
      client_group_id: null,
      client_skyqraft_object_type_id: null,
    };
  });

  function renderSaveIcon() {
    if (saveFeedback === "") {
      return <Save />;
    }
    if (saveFeedback === "loading") {
      return (
        <CircularProgress
          size={20}
          sx={{ marginTop: "2px", marginLeft: "2px" }}
        />
      );
    }
    if (saveFeedback === "saved") {
      return <Done htmlColor="green" />;
    }
    if (saveFeedback === "error") {
      return <ErrorOutline htmlColor="red" />;
    }
  }

  function renderSelect(obj) {
    return (
      <FormControl>
        <InputLabel>ID</InputLabel>
        <Select
          value={selectedClientType}
          onChange={(e) => {
            if (e.target.value === obj.client_issue_severity_id) {
              setNewValues({
                ...newValues,
                clientName: obj.client_name,
                clientInternalId: obj.client_external_type_id,
              });
            } else {
              setNewValues(null);
            }

            setSelectedClientType(e.target.value);
          }}
          sx={{ minWidth: "100px" }}
          renderValue={(value) => {
            const selectedOption = clientObjects.find(
              (option) => option.client_issue_severity_id === value
            );
            return selectedOption
              ? selectedOption.client_issue_severity_id
              : "";
          }}
        >
          {clientObjects
            .filter(
              (e, i, a) =>
                a.findIndex(
                  (t) =>
                    t.client_issue_severity_id === e.client_issue_severity_id
                ) === i
            )
            .map((option) => (
              <MenuItem key={option.id} value={option.client_issue_severity_id}>
                {`ID: ${
                  option.client_issue_severity_id
                } - ${`${option.client_issue_severity_name} (${option.external_type_id})`}`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }

  function exitEditMode() {
    setEditId(null);
    setNewValues(null);
    setSelectedClientType(null);
  }

  function enterEditMode(obj) {
    setEditId(obj.id);
    setSelectedClientType(obj.client_issue_severity_id);

    setNewValues({
      clientName: obj.client_name,
      clientColor: obj.client_color,
      clientInternalId: obj.client_external_type_id,
    });
  }

  function deleteAndUpdateType(obj, clientTypeId) {
    deleteClientIssueSeverityTypeMap(obj.client_id)
      .then(() => {
        addNewType(obj, clientTypeId);
      })
      .catch((e) => {
        setSaveFeedback("error");
        setTimeout(() => {
          setSaveFeedback("");
        }, 3000);
      });
  }

  function addNewType(obj, clientTypeId) {
    addClientIssueSeverities(selectedGroup, obj.id, clientTypeId)
      .then((e) => {
        getClientObjects(selectedGroup).then(() => {
          setSaveFeedback("saved");
          setTimeout(() => {
            exitEditMode();
            setSaveFeedback("");
          }, 800);
        });
      })
      .catch((e) => {
        setSaveFeedback("error");
        setTimeout(() => {
          setSaveFeedback("");
        }, 3000);
      });
  }

  function save(obj) {
    setSaveFeedback("loading");

    if (
      selectedClientType !== null &&
      selectedClientType !== obj.client_issue_severity_id
    ) {
      if (!!obj.client_id) {
        deleteAndUpdateType(obj, selectedClientType);
      } else {
        addNewType(obj, selectedClientType);
      }
    }
    if (
      selectedClientType !== null &&
      selectedClientType !== obj.client_issue_severity_id
    ) {
      const values = clientObjects.find(
        (c) => c.client_issue_severity_id === selectedClientType
      );
      addNewClientObjectType(
        obj,
        values.client_issue_severity_name,
        values.external_type_id
      );
    } else {
      addNewClientObjectType(
        obj,
        newValues.clientName,
        newValues.clientInternalId
      );
    }
  }

  function addNewClientObjectType(obj, name, internalId) {
    //Add new client object type
    addClientIssueSeverityType(selectedGroup, name, internalId)
      .then((e) => {
        //remove old object type id there was a old one
        //@ts-ignore
        if (e?.id !== null) {
          if (!!obj.client_id) {
            //@ts-ignore
            deleteAndUpdateType(obj, e?.id);
          } else {
            //@ts-ignore
            addNewType(obj, e?.id);
          }
        } else {
          setSaveFeedback("saved");
          setTimeout(() => {
            exitEditMode();
            setSaveFeedback("");
          }, 800);
        }
      })
      .catch((e) => {
        setSaveFeedback("error");
        setTimeout(() => {
          setSaveFeedback("");
        }, 3000);
      });
  }

  function renderLogic(obj) {
    if (editId === obj.id) {
      if (
        selectedClientType === obj.client_issue_severity_id ||
        selectedClientType === null
      ) {
        return "edit";
      }
      return "display";
    }
    return "normal";
  }

  useEffect(() => {
    if (editId !== null) {
      const obj = filteredObjects.find((e) => e.id === editId);
      if (obj.client_issue_severity_id) {
        getDeleteType(obj.client_issue_severity_id);
      }
    }
  }, [editId]);

  useEffect(() => {
    if (selectedGroup !== null) {
      getClientObjectTypes(selectedGroup);
    }
  }, [selectedGroup]);

  function getDeleteType(id) {
    if (id !== null) {
      const mapCount = checkClientIssueSeverityTypeMap(id);
      mapCount.then((e) => {
        //@ts-ignore
        e?.number_of_mapped_types > 1
          ? setDeleteType("Disconnect")
          : setDeleteType("Delete");
      });
    }
  }

  return (
    <Box sx={{ with: "100vw", height: "100%" }}>
      <Container>
        {/* Titles Row */}
        <div style={{ display: "flex", marginBottom: 10 }}>
          {titles.map((title) => (
            <>
              <div key={title} style={{ flex: 1, textAlign: "center" }}>
                <Typography variant="subtitle1">{title}</Typography>
              </div>
            </>
          ))}
        </div>

        {/* List Content */}
        {filteredObjects.map((obj, rowIndex) => (
          <Paper key={obj.id} style={{ marginBottom: 10 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Typography variant="body2">Arkion</Typography>
                </div>
                <Divider orientation="vertical" flexItem />
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Typography variant="body2">{obj.id}</Typography>
                </div>

                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Typography variant="body2">
                    {obj[`${language.toLowerCase()}_name`]}
                  </Typography>
                </div>

                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  ---
                </div>

                <Divider orientation="vertical" flexItem />

                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  ---
                </div>
              </div>
              <Divider orientation="horizontal" flexItem />

              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Typography variant="body2">
                    {" "}
                    {getTranslation("Client", language)}
                  </Typography>
                </div>
                <Divider orientation="vertical" flexItem />
                {/* Client ID */}
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  {editId === obj.id ? (
                    <>{renderSelect(obj)}</>
                  ) : (
                    <Typography variant="body2">
                      {obj.client_issue_severity_id}
                    </Typography>
                  )}
                </div>

                {/* Client name */}
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  {renderLogic(obj) === "edit" && (
                    <TextField
                      value={newValues?.clientName}
                      defaultValue={obj.client_name}
                      label={getTranslation("Name", language)}
                      onChange={(e) =>
                        setNewValues({
                          ...newValues,
                          clientName: e.target.value,
                        })
                      }
                    />
                  )}

                  {renderLogic(obj) === "display" && (
                    <Typography variant="body2">
                      {
                        clientObjects.find(
                          (c) =>
                            c.client_issue_severity_id === selectedClientType
                        )?.client_issue_severity_name
                      }
                    </Typography>
                  )}
                  {renderLogic(obj) === "normal" && (
                    <Typography variant="body2">{obj.client_name}</Typography>
                  )}
                </div>

                {/* Client external type ID */}
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  {renderLogic(obj) === "edit" && (
                    <TextField
                      value={newValues?.client_external_type_id || undefined}
                      defaultValue={obj.client_external_type_id}
                      label={getTranslation("InternalId", language)}
                      onChange={(e) =>
                        setNewValues({
                          ...newValues,
                          clientInternalId: e.target.value,
                        })
                      }
                    />
                  )}
                  {renderLogic(obj) === "display" && (
                    <Typography variant="body2">
                      {
                        clientObjects.find(
                          (c) =>
                            c.client_issue_severity_id === selectedClientType
                        )?.external_type_id
                      }
                    </Typography>
                  )}
                  {renderLogic(obj) === "normal" && (
                    <Typography variant="body2">
                      {obj.client_external_type_id}
                    </Typography>
                  )}
                </div>

                <Divider orientation="vertical" flexItem />

                {/* Edit and save buttons */}
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",

                    padding: 8,
                  }}
                >
                  <IconButton
                    onClick={(e) => {
                      editId === obj.id ? exitEditMode() : enterEditMode(obj);
                    }}
                    disabled={editId !== null && editId !== obj.id}
                  >
                    {editId === obj.id ? <Close /> : <Edit />}
                  </IconButton>
                  {editId === obj.id && (
                    <>
                      <IconButton
                        onClick={() => {
                          save(obj);
                        }}
                      >
                        {renderSaveIcon()}
                      </IconButton>

                      {!!obj.client_id &&
                        selectedClientType === obj.client_issue_severity_id && (
                          <IconButton
                            onClick={() => {
                              setSaveFeedback("loading");
                              deleteClientIssueSeverityTypeMap(obj.client_id)
                                .then(() => {
                                  getClientObjects(selectedGroup).then(() => {
                                    setSaveFeedback("saved");
                                    setTimeout(() => {
                                      exitEditMode();
                                      setSaveFeedback("");
                                    }, 800);
                                  });
                                })
                                .catch((e) => {
                                  setSaveFeedback("error");
                                  setTimeout(() => {
                                    setSaveFeedback("");
                                  }, 3000);
                                });
                            }}
                          >
                            {deleteType === "Delete" ? (
                              <Tooltip
                                title={getTranslation("Delete", language)}
                                placement="right"
                                arrow
                              >
                                <Delete />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={getTranslation("Disconnect", language)}
                                placement="right"
                                arrow
                              >
                                <LinkOff />
                              </Tooltip>
                            )}
                          </IconButton>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Paper>
        ))}
      </Container>
    </Box>
  );
}
