import React, { useState, useRef } from "react";
import { Pole } from "interfaces";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { deletePole, updatePoles } from "state/actions";
import store, { RootState } from "state/store";
import { BarLoader } from "react-spinners";
import { authorizedPut } from "utils/request";
import hasAccess from "utils/authTools";
import translations from "translations";
import { useLanguage } from "hooks";
import { useDispatch } from "hooks";
import { useNavigate } from "react-router-dom";
import md5 from "md5-hash";

interface IProps {
  customerID: number;
  gmap: google.maps.Map;
  pole: Pole;
  skyqraftEmployee: boolean;
  lat;
  lng;
  superuser: boolean;
  demoMode: boolean;
}

function PoleElement({
  gmap,
  pole,
  skyqraftEmployee,
  superuser,
  demoMode,
  customerID,
}: IProps) {
  const { language } = useLanguage();
  const dispatch = useDispatch();
  const poleRef = useRef(null);
  const navigate = useNavigate();
  const [newX, setNewX] = useState<number | null>(null);
  const [newY, setNewY] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [startX, setStartX] = useState<number | null>(null);
  const [startY, setStartY] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function convertXYToLatLng(x: number, y: number) {
    const topRight = gmap
      .getProjection()
      .fromLatLngToPoint(gmap.getBounds().getNorthEast());
    const bottomLeft = gmap
      .getProjection()
      .fromLatLngToPoint(gmap.getBounds().getSouthWest());
    const scale = 2 ** gmap.getZoom();
    const worldPoint = new google.maps.Point(
      (x - 50) / scale + bottomLeft.x,
      (y + 21) / scale + topRight.y
    );
    return gmap.getProjection().fromPointToLatLng(worldPoint);
  }

  function assignImagesAroundPole() {
    authorizedPut(`/poles/${pole.id}/assign_area`)
      .then(() => {
        handleClose();
      })
      .catch(() => {
        handleClose();
      });
  }

  function poleFollowMouse(e) {
    setNewX(e.clientX);
    setNewY(e.clientY);
  }

  function openPole(e) {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/${customerID}/pole/${pole.id}${window.location.search}`);
  }

  async function movePole(e, xOffset, yOffset) {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    const latlng = convertXYToLatLng(e.clientX + xOffset, e.clientY + yOffset);
    authorizedPut(`/poles/${pole.id}`, {
      lat: latlng.lat(),
      lng: latlng.lng(),
    })
      .then(() => {
        assignImagesAroundPole();
        dispatch(
          updatePoles(() => {
            setLoading(false);
            setNewX(null);
            setNewY(null);
          })
        );
      })
      .catch(() => {
        setLoading(false);
        setNewX(null);
        setNewY(null);
      });
  }

  function mouseDown(e1) {
    const UserHasAccess = hasAccess("movePole");

    if (UserHasAccess) {
      // Prevent actions down to map
      e1.preventDefault();
      e1.stopPropagation();

      // Record where mouse was pressed down
      const downX = e1.clientX;
      const downY = e1.clientY;
      setStartX(e1.clientX);
      setStartY(e1.clientY);

      // Check where on the pole we clicked.
      const poleRect = poleRef.current.getBoundingClientRect();

      const xOffset = poleRect.x - e1.clientX;
      const yOffset = poleRect.y - e1.clientY;
      const correctX = 0.0625;
      const correctY = -20.1796875;
      const fixX = xOffset - correctX;
      const fixY = yOffset - correctY;

      document.addEventListener("mousemove", poleFollowMouse);
      document.addEventListener(
        "mouseup",
        (e2) => {
          document.removeEventListener("mousemove", poleFollowMouse);

          if (
            Math.abs(e2.clientX - downX) < 10 &&
            Math.abs(e2.clientY - downY) < 10
          ) {
            openPole(e2);
          } else {
            const confirmation = window.confirm(
              "Are you sure you want to move this pole?"
            );
            if (!confirmation) {
              setNewX(startX);
              setNewY(startY);
            } else {
              movePole(e2, fixX, fixY);
            }
          }
        },
        { once: true }
      );
    } else {
      openPole(e1);
    }
  }

  return (
    <>
      <Tooltip
        title={
          <table>
            <tbody>
              {!!pole.label && (
                <tr>
                  <th>{translations.pole.label[language]}</th>
                  <td>{pole.label}</td>
                </tr>
              )}
              {skyqraftEmployee && (
                <tr>
                  <th>Arkion ID</th>
                  <td>{pole.id}</td>
                </tr>
              )}
              {!!pole.client_id && (
                <tr>
                  <th>Client asset ID</th>
                  <td>
                    {demoMode
                      ? md5(pole.client_id).substring(0, 4)
                      : pole.client_id}
                  </td>
                </tr>
              )}
              {!!pole.feed_station && (
                <tr>
                  <th>{translations.pole.feedStation[language]}</th>
                  <td>{pole.feed_station}</td>
                </tr>
              )}
              {!!pole.feed_bay && (
                <tr>
                  <th>{translations.pole.feedBay[language]}</th>
                  <td>{pole.feed_bay}</td>
                </tr>
              )}
            </tbody>
          </table>
        }
        placement="top"
        arrow
        disableInteractive
      >
        <div
          onMouseDown={mouseDown}
          className={`pole${!pole.viewed && skyqraftEmployee ? " unseen" : ""}`}
          ref={poleRef}
          style={{
            position: !!newX && !!newY ? "absolute" : undefined,
            left: !!newX && !!newY ? newX - startX : undefined,
            top: !!newX && !!newY ? newY - startY : undefined,
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            handleClick(e);
          }}
          onDoubleClick={(e) => {
            const state: RootState = store.getState();
            const inspectorMode = state.map.inspectorMode;
            if (inspectorMode) {
              e.preventDefault();
              e.stopPropagation();
              if (window.confirm("Do you want to delete this pole?")) {
                // @ts-ignore
                store.dispatch(deletePole(pole.id));
              }
            }
          }}
        >
          <BarLoader
            width={30}
            color="#FFF"
            loading={!!pole.loading || loading}
          />
          {pole?.label && (
            <div className="poleLabel">
              <p style={{ margin: 0 }}>{pole.label}</p>
            </div>
          )}
          <div className="insulatorRow">
            <div className="insulator" />
            <div className="insulator" />
            <div className="insulator" />
          </div>
          <div className="crossarm" />
          <div className="beam" />
        </div>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={assignImagesAroundPole}>
          {translations.pole.assignImagesAroundPole[language]}
        </MenuItem>
      </Menu>
    </>
  );
}
export default PoleElement;
