import { Translated } from "./utils";

export const Image = Translated({ EN: "Image", SE: "Bild" });
export const LidarImage = Translated({ EN: "Lidar Image", SE: "Lidar Bild" });
export const RGBImage = Translated({ EN: "RGB Image", SE: "RGB Bild" });
export const ThermalImage = Translated({
  EN: "Thermal Image",
  SE: "VärmeBild",
});
export const NoList = Translated({
  EN: "No lists available",
  SE: "Inga listor tillgängliga",
});
export const PreviewImagePrevDefect = Translated({
  EN: "This is only a preview from last years inspection.",
  SE: "Detta är endast en förhandsgranskning från förra årets inspektion.",
});
