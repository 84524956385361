import { Stack } from "@mui/material";
import {
  useSelector,
  useObjectTypeFilter,
  useDetectionCount,
  useLanguage,
} from "hooks";
import ExpandedSelect, { IOptionCategory } from "../ExpandedSelect";

export default function DetectionFilter() {
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const { language } = useLanguage();
  const detectionCats = useSelector(
    (state) => state.objects.detectedCategories
  );
  const { objectTypeFilter, setObjectTypes } = useObjectTypeFilter();
  const { counts, loading } = useDetectionCount();

  const options: IOptionCategory[] = [];
  for (const defectCategory of detectionCats) {
    const categoryOption = {
      id: defectCategory.id,
      name: defectCategory[`${language.toLocaleLowerCase()}_name`],
      count: 0,
      options: [],
    };

    for (const objectType of objectTypes.filter(
      (ot) => ot.category === defectCategory.id
    )) {
      const count = counts.find((c) => c.id === objectType.id);
      categoryOption.options.push({
        id: objectType.id,
        name: objectType[`${language.toLocaleLowerCase()}_name`],
        count: count?.count ?? 0,
      });
    }
    options.push(categoryOption);
  }

  const filteredOptions = options.filter(
    (f) => f.name.toLowerCase() !== "vegetation"
  );

  return (
    <Stack mx={2}>
      <ExpandedSelect
        categories={filteredOptions}
        value={objectTypeFilter}
        onChange={setObjectTypes}
        loading={loading}
      />
    </Stack>
  );
}
