import { Divider, Stack, Typography } from "@mui/material";
import MenuItem from "components/MenuItem";
import RoleWrapper from "components/RoleHOC/wrapper";
import { useLanguage, useSelector, useSidebar } from "hooks";
import { AccessKeys } from "interfaces";
import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import translations from "translations";
import ArrowBackItem from "../ArrowBackItem";

interface ToolEntry {
  translation: { SE: string; EN: string };
  tooltipTranslation: { SE: string; EN: string };
  link: string;
  role: AccessKeys;
}

export default function ToolsList(): ReactElement {
  const customerExists = useSelector((state) => state.mission?.id ?? -1 > 0);
  const customerId = useSelector((state) => state.mission?.id ?? -1);
  const { closeSidebar } = useSidebar();
  const { language } = useLanguage();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  if (urlParams.has("menu")) {
    urlParams.delete("menu");
  }

  const pointerEventsForProjectTools = customerExists ? "inherit" : "none";
  const textColorForProjectTools = customerExists ? "inherit" : "grey";

  const projectTools: ToolEntry[] = [
    {
      translation: translations.Menu.Tools.BlurImages,
      tooltipTranslation: translations.Menu.Tools.BlurImages.Tooltip,
      link: `${customerId}/blur${location.search}`,
      role: "blurAllImages",
    },
    {
      translation: translations.Menu.Tools.Powerlines,
      tooltipTranslation: translations.Menu.Tools.Powerlines.Tooltip,
      link: `${customerId}/powerline`,
      role: "powerlinePanel",
    },
    {
      translation: translations.Menu.Tools.Reports,
      tooltipTranslation: translations.Menu.Tools.Reports.Tooltip,
      link: customerId
        ? `/${customerId}/report/defect${location.search}`
        : `report/defect?${location.search}`,
      role: null,
    },
    {
      translation: translations.Menu.Tools.Upload,
      tooltipTranslation: translations.Menu.Tools.Upload.Tooltip,
      link: `${customerId}/upload/images`,
      role: "upload",
    },
    {
      translation: translations.Menu.Tools.Download,
      tooltipTranslation: translations.Menu.Tools.Download.Tooltip,
      link: `${customerId}/upload/images`,
      role: "downloadPanel",
    },
    {
      translation: translations.ImageViewer.MLReview,
      tooltipTranslation: translations.ImageViewer.MLReview,
      link: `${customerId}/0/machine-review${location.search}`,
      role: "machineReview",
    },
    {
      translation: translations.ImageViewer.FalsePositiveReview,
      tooltipTranslation: translations.ImageViewer.FalsePositiveReview,
      link: `${customerId}/0/falsepositive-review${location.search}`,
      role: "falsePositiveReview",
    },
    {
      translation: translations.ImageViewer.AnnotatorReview,
      tooltipTranslation: translations.ImageViewer.AnnotatorReview,
      link: `${customerId}/0/supervisor-review${location.search}`,
      role: "supervisorApprove",
    },
    {
      translation: translations.ImageViewer.SuperFalseReview,
      tooltipTranslation: translations.ImageViewer.SuperFalseReview,
      link: `${customerId}/0/superfalsepositive-review${location.search}`,
      role: "supervisorFalseReview",
    },
  ];

  return (
    <Stack>
      <ArrowBackItem />
      <Stack direction="column" alignItems="center" width="100%">
        <Typography variant="h5">
          {translations.Menu.Tools[language]}
        </Typography>
      </Stack>

      <Stack spacing={1} width="100%">
        <Divider>
          <Typography fontWeight="bold">
            {translations.Menu.Tools.ProjectTools[language]}
          </Typography>
        </Divider>
      </Stack>
      {projectTools.map((tool) => {
        const menuItem = (
          <MenuItem
            id="downloadPanel"
            tooltip={tool.tooltipTranslation?.[language]}
          >
            <p>{tool.translation[language]}</p>
          </MenuItem>
        );

        const link = (
          <Link
            onClick={closeSidebar}
            to={tool.link}
            style={{
              textDecoration: "none",
              color: textColorForProjectTools,
              pointerEvents: pointerEventsForProjectTools,
            }}
          >
            {menuItem}
          </Link>
        );

        const child = tool.role ? (
          <RoleWrapper
            keyName={tool.role}
            fallback={
              <div
                style={{
                  color: "grey",
                }}
              >
                {menuItem}
              </div>
            }
          >
            {link}
          </RoleWrapper>
        ) : (
          link
        );

        return tool.role ? (
          <RoleWrapper keyName={tool.role} treatAsMissionAgnostic={true}>
            {child}
          </RoleWrapper>
        ) : (
          <>{child}</>
        );
      })}
      <Stack spacing={1} width="100%">
        <Divider>
          <Typography fontWeight="bold">
            {translations.Menu.Tools.CustomerTools[language]}
          </Typography>
        </Divider>
      </Stack>

      <RoleWrapper keyName="stateMachineManager">
        <Link
          onClick={closeSidebar}
          to="/batch"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem>
            <p>{translations.Menu.BatchJobs[language]}</p>
          </MenuItem>
        </Link>
      </RoleWrapper>

      <RoleWrapper keyName="addClientIssueSeverities">
        <Link
          onClick={closeSidebar}
          to="/client/settings"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem>
            <p>{translations.Menu.Settings.CustomizeDefectTypes[language]}</p>
          </MenuItem>
        </Link>
      </RoleWrapper>
      <Link
        onClick={closeSidebar}
        to="/options/pole/status/template"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <MenuItem id="poleStatus">
          <p>{translations.Menu.Tools.PoleStatus[language]}</p>
        </MenuItem>
      </Link>

      <Stack spacing={1} width="100%">
        <Divider>
          <Typography fontWeight="bold">
            {translations.Menu.Tools.GlobalTools[language]}
          </Typography>
        </Divider>
      </Stack>
      <RoleWrapper keyName="objectTypeManager">
        <Link
          onClick={closeSidebar}
          to="/objects"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem>
            <p>{translations.Menu.Tools.Objects[language]}</p>
          </MenuItem>
        </Link>
      </RoleWrapper>
      <Link
        onClick={closeSidebar}
        to="/changelog"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <MenuItem id="changelog">
          <p>{translations.Menu.Tools.Changelog[language]}</p>
        </MenuItem>
      </Link>
    </Stack>
  );
}
