import { AccessRules } from "interfaces";
import { axiosInstance } from "utils/request";
import { ILanguages } from "utils/translations";

interface IDomain {
  id: number;
  name: string;
  url: string;
}

export const skyqraftHiddenSetting = {
  hideHidden: "hideHidden",
  showHidden: "showHidden",
  hiddenOnly: "hiddenOnly",
} as const;

export type ISkyqraftHiddenSetting =
  (typeof skyqraftHiddenSetting)[keyof typeof skyqraftHiddenSetting];

export interface IUserSettings {
  id: number;
  email: string;
  name: string;
  phone_number: string | null;
  skyqraft_employee: boolean;
  language: ILanguages;
  superuser: boolean;
  annotator_color: boolean;
  annotator: boolean;
  supervisor: boolean;
  client_naming: boolean;
  domains: IDomain[];
  accessRules: AccessRules;
  skyqraft_hidden: ISkyqraftHiddenSetting;
}

export async function getUserSettings() {
  const { data } = await axiosInstance.get<IUserSettings>("/user/settings");
  return data;
}

export async function setSkyqraftHiddenSetting(
  skyqraft_hidden: IUserSettings["skyqraft_hidden"]
) {
  await axiosInstance.post("/user/settings", { skyqraft_hidden });
}
