import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getLidarMarkerValue(
  searchParams: URLSearchParams
): FilterType["LIDAR_MARKERS"] {
  const filterValue = searchParams.get(FilterKey.LIDAR_MARKERS);
  return filterValue === "true";
}

export function useLidarMarkerFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const lidarMarkerFilter = getLidarMarkerValue(searchParams);

  const setLidarMarkerValue = (show: boolean) => {
    if (!show) {
      if (searchParams.has(FilterKey.LIDAR_MARKERS))
        searchParams.delete(FilterKey.LIDAR_MARKERS);
    } else {
      searchParams.set(FilterKey.LIDAR_MARKERS, "true");
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { lidarMarkerFilter, setLidarMarkerValue };
}
