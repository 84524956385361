import { authorizedGet } from "utils/request";
import { RootState } from "state/store";
import { toast } from "react-toastify";
import translations from "translations";

export const setFlightsData = (value: Array<any>) => {
  return {
    type: "SET_FLIGHTS_DATA",
    payload: value,
  };
};

export const setTotalCount = (value: number) => {
  return {
    type: "SET_TOTAL_COUNT",
    payload: value,
  };
};

export const setHumanOrNotLabel = (value: string) => {
  return {
    type: "SET_HUMAN_OR_NOT_LABEL",
    payload: value,
  };
};

export const setCustomerName = (value: number) => {
  return {
    type: "SET_CUSTOMER_NAME",
    payload: value,
  };
};

export const getCustomerName = () => {
  return async function (dispatch) {
    const response = await authorizedGet<{ name }>("/mission/name");
    dispatch(setCustomerName(response.name.replaceAll(" ", "_")));
  };
};

interface DownloadObjectFileResponse {
  count: number;
  data: {
    additions: any;
  };
}

export const downloadObjectFile = () => {
  return async function (dispatch, getState) {
    const state: RootState = getState();
    let human =
      state.imageObjectDownload.humanOrNotLabel === "human" ? true : false;
    let language = state.user.language;

    const response = await authorizedGet<DownloadObjectFileResponse>(
      `/object/download?human=${human}&history=false`
    );

    if (response.count > 0) {
      dispatch(setFlightsData(response.data.additions));
      dispatch(setTotalCount(response.count));
      toast.success(
        `${response.count} ${translations.Menu.Tools.ImageObjects.Download.ObjectsAvailable[language]}`
      );
    } else {
      toast.success(
        translations.Menu.Tools.ImageObjects.Download.NoObjects[language]
      );
    }
  };
};

export const downloadFlightObjects = (
  flightsData,
  flight_id,
  filename: string
) => {
  let additions = [];
  for (const flight_id in flightsData) {
    additions = additions.concat(flightsData[flight_id]);
  }
  let blob = new Blob(
    [
      JSON.stringify({
        data: {
          count: flightsData[flight_id].length,
          additions: additions,
        },
      }),
    ],
    { type: "application/json" }
  );

  let a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  a.click();
};
