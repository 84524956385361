import { Texture } from "@mui/icons-material";
import { Box, Button, Tooltip } from "@mui/material";
import { FilterKey } from "hooks/filter/search_parameters/constants";
import { useSearchParams } from "react-router-dom";
import {
  clearFilterArea,
  setFilterSelectorStatus,
  updateMarkers,
} from "state/actions";
import { useDispatch, useSidebar, useLanguage } from "hooks";
import translations from "translations";

export default function AreaFilter() {
  const dispatch = useDispatch();
  const { closeSidebar } = useSidebar();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterActive = searchParams.has(FilterKey.AREA);
  const { language } = useLanguage();
  return (
    <Tooltip
      title={translations.Filter.ComingSoon[language]}
      placement="right"
      arrow
    >
      <Box>
        <Button
          variant={filterActive ? "contained" : "outlined"}
          color="success"
          size="small"
          fullWidth
          endIcon={<Texture />}
          onClick={() => {
            if (filterActive) {
              const newSearchParams = new URLSearchParams(
                window.location.search
              );
              newSearchParams.delete("area");
              setSearchParams(newSearchParams, { replace: true });
              dispatch(clearFilterArea());
              dispatch(updateMarkers());
            } else {
              closeSidebar();
              dispatch(setFilterSelectorStatus(true, setSearchParams));
            }
          }}
        >
          {filterActive
            ? translations.Filter.UnSelectArea[language]
            : translations.Filter.SelectArea[language]}
        </Button>
      </Box>
    </Tooltip>
  );
}
