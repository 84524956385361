import { IconButton, Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useContext } from "react";
import { context } from "providers/NavigationProvider";
import { AnnotationContext } from "../provider";
import { useLocation, useNavigate } from "react-router-dom";
import { regionsAreEqual } from "./AnnotationsList/Utils";

export default function ExitButton() {
  const { lastURL } = useContext(context);
  const { annotations, originalAnnotations } = useContext(AnnotationContext);
  const navigate = useNavigate();
  const location = useLocation();
  let somethingChanged = !regionsAreEqual(annotations, originalAnnotations);

  const exit = () => {
    if (somethingChanged) {
      if (window.confirm("Do you want to exit without saving?")) {
        if (lastURL) {
          navigate(-1);
        } else {
          navigate(`..${location.search}`);
        }
      } else {
        return;
      }
    } else {
      if (lastURL) {
        navigate(-1);
      } else {
        navigate(`..${location.search}`);
      }
    }
  };

  return (
    <Tooltip title="Exit" placement="left" arrow disableInteractive>
      <IconButton
        onClick={() => {
          exit();
        }}
      >
        <Close />
      </IconButton>
    </Tooltip>
  );
}
