import { useEffect, useRef } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { axiosInstance } from "utils/request";
import Login from "authentication/Login";
import CheckingCredentials from "./CheckingCredentials";
import ChangePassword from "./ChangePassword";
import Reset from "authentication/Reset";
import FAQ from "views/FAQ";
import { RootState } from "state/store";
import MFA from "./MFA";
import { MainLayout } from "views";

function HOCAuthenticator(ChildComponent) {
  return (props) => {
    /* eslint-disable */ // Eslint doesn't understand that this is a React component...
    const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const redirectLinkRef = useRef<string>();

    useEffect(() => {
      let checkSessionInterval: ReturnType<typeof setInterval>;

      if (loggedIn) {
        checkSessionInterval = setInterval(() => {
          axiosInstance.get("/heartbeat"); // axios instance will redirect to login page if 401 (unauthenticated)
        }, 1000 * 15);
      } else {
        clearInterval(checkSessionInterval);
      }
    }, [loggedIn]);

    function setRedirectLink(redirectLink: string) {
      redirectLinkRef.current = redirectLink;
    }

    function logInUser(value: boolean) {
      dispatch({
        type: "SET_LOGGED_IN",
        payload: value,
      });

      if (value && redirectLinkRef.current) {
        navigate(redirectLinkRef.current);
      }
    }

    if (loggedIn) {
      return <ChildComponent {...props} />;
    } else {
      return (
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="faq" element={<FAQ />} />
            <Route path="faq/*" element={<FAQ />} />
            <Route path="sv/faq" element={<FAQ />} />
            <Route path="sv/faq/*" element={<FAQ />} />
            <Route
              index
              element={
                <Login
                  setLoggedIn={logInUser}
                  setRedirectLink={setRedirectLink}
                />
              }
            />
            <Route path="reset" element={<Reset />} />
            <Route path="change" element={<ChangePassword />} />
            <Route path="mfa" element={<MFA setLoggedIn={logInUser} />} />
            <Route
              path="*"
              element={<CheckingCredentials setLoggedIn={logInUser} />}
            />
          </Route>
        </Routes>
      );
    }
  };
}

export { HOCAuthenticator };
