import { Stack } from "@mui/material";
import {
  useDefectCount,
  useSelector,
  useObjectTypeFilter,
  useLanguage,
} from "hooks";
import ExpandedSelect, { IOptionCategory } from "../ExpandedSelect";

export default function DefectFilter() {
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const issueCats = useSelector((state) => state.objects.issueCategories);
  const { language } = useLanguage();
  const { objectTypeFilter, setObjectTypes } = useObjectTypeFilter();
  const { counts, loading } = useDefectCount();

  const options: IOptionCategory[] = [];
  for (const issueCategory of issueCats) {
    const categoryOption = {
      id: issueCategory.id,
      name: issueCategory[`${language.toLocaleLowerCase()}_name`],
      count: 0,
      options: [],
    };

    for (const objectType of objectTypes.filter(
      (ot) => ot.category === issueCategory.id
    )) {
      const count = counts.find((c) => c.id === objectType.id);
      categoryOption.options.push({
        id: objectType.id,
        name: objectType[`${language.toLocaleLowerCase()}_name`],
        count: count?.count ?? 0,
      });
    }
    options.push(categoryOption);
  }

  const filteredOptions = options.filter(
    (f) => f.name.toLowerCase() !== "vegetation"
  );

  return (
    <Stack mx={2} borderRadius={1}>
      <ExpandedSelect
        categories={filteredOptions}
        value={objectTypeFilter}
        onChange={setObjectTypes}
        loading={loading}
      />
    </Stack>
  );
}
