import Select, { MultiValue, ActionMeta } from "react-select";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import MenuItem from "components/MenuItem";
import { getFilter, setFilter } from "utils/filter";
import { useSearchParams } from "react-router-dom";
import { InfoOutlined } from "@mui/icons-material";
import { useLanguage, useSelector, useTranslation, useDispatch } from "hooks";
import { updateMarkers } from "state/actions";
import { useWorkflowCount } from "hooks/filter/count/useWorkflowCount";

interface Option {
  value: number;
  label: string;
}
interface Meta {
  action: string;
  name: string;
  removedValue: Option | undefined;
  option: Option;
}

const mapArray = (list, language) =>
  list.map((i) => ({
    value: i.id,
    label: i[`${language.toLowerCase()}_name`],
  }));

const addCount = (withoutCount, counts) => {
  return withoutCount.map((item) => {
    const itemCount = counts.find((c) => c.id === item.value);
    if (itemCount) {
      return {
        ...item,
        count: itemCount.count,
        loaded: true,
      };
    } else {
      return {
        ...item,
        count: 0,
        loaded: true,
      };
    }
  });
};

const labelWithCount = (item) => ({
  label: (
    <span>
      <Chip
        size="small"
        label={item.loaded ? item.count : "..."}
        variant="outlined"
      />{" "}
      {item.label}{" "}
    </span>
  ),
  text: item.label,
  value: item.value,
});

export default function WorkflowFilter() {
  const workflowStatus = useSelector((state) => state.objects.workflowStatus);
  const { counts } = useWorkflowCount();
  const dispatch = useDispatch();
  const { language } = useLanguage();
  const Workflow = useTranslation("Workflow");
  const WorkflowTooltip = useTranslation("WorkflowTooltip");
  const [searchParams, setSearchParams] = useSearchParams();
  const workflowFilter = getFilter("workflow", searchParams);

  function onChange(
    selectedOptions: MultiValue<Option>,
    actionMeta: ActionMeta<Meta>
  ) {
    setFilter(
      "workflow",
      selectedOptions.map((s) => s.value),
      searchParams,
      setSearchParams
    );
    dispatch(updateMarkers());
  }
  // Map categories
  let workflows = mapArray(workflowStatus, language);

  workflows = workflows.filter((e) => e.value !== 4 && true);

  // Order lists
  workflows = workflows.sort((a, b) => a.id - b.id);

  // Add counts
  workflows = addCount(workflows, counts);

  const selectedWorkflows = workflows.filter((s) =>
    workflowFilter.includes(s.value)
  );

  return (
    <div id="workflowFilter">
      <MenuItem className="section" passive>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>{Workflow}</Typography>
          <Tooltip title={WorkflowTooltip} placement="right" arrow>
            <InfoOutlined fontSize="small" />
          </Tooltip>
        </Stack>
      </MenuItem>
      <MenuItem passive>
        <div style={{ width: "100%" }}>
          <Select
            className="pr-10 pl-10"
            value={selectedWorkflows.map(labelWithCount)}
            options={workflows.map(labelWithCount)}
            isMulti
            name={"workflow"}
            onChange={onChange}
            maxMenuHeight={400}
            captureMenuScroll={false}
            getOptionValue={(option) => `${option.text}`}
          />
        </div>
      </MenuItem>
    </div>
  );
}
