import { Info } from "@mui/icons-material";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import { ImageMeta } from "interfaces";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isFilterActive } from "utils/filter";
import { authorizedGet } from "utils/request";
import { HighlightMarkers, unHighlightMarkers } from "utils/utils";
import { sortImages } from "utils/utils";
import MapMarker from "views/map/marker";
import LazyLoadImage from "../LazyLoadImage";
import "./style.css";

const DEFAULT_WIDTH = 133;
const ROTATION_OFFSET = 180;

export interface IThumbnail {
  id: number;
  flight_id: number;
  compass_dir: number;
  image_type?: {
    id: number;
    name: string;
  };
  weight: number;
  src: string;
}

interface IProps {
  canLoad: boolean;
  image: ImageMeta | null;
  customerId: number;
  objectTypes;
  bbox: boolean;
  skyqraft_employee: boolean;
  clusterHidden: boolean;
  clusterMethod: string;
  distance: string;
  annotatorObjectColor: boolean;
  openedMarkers: number[];
  thumbnailRegexPattern: string | null;
  setThumbnailRegexPattern: (pattern: string | null) => void;
  imageSortingMethod: "compass" | "timestamp";
  thumbnailPoleItemPlacement: number | null;
  setThumbnailPoleItemPlacement: (position: number | null) => void;
}

export default function PreviewSlider({
  image,
  canLoad,
  customerId,
  objectTypes,
  bbox,
  skyqraft_employee,
  clusterHidden,
  clusterMethod,
  distance,
  annotatorObjectColor,
  openedMarkers,
  thumbnailRegexPattern,
  setThumbnailRegexPattern,
  imageSortingMethod,
  thumbnailPoleItemPlacement,
  setThumbnailPoleItemPlacement,
}: IProps) {
  // Collect data from the URL
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const imageID = parseInt(params.image);

  // Check if we are filtering out less severe images
  const severitys = searchParams.get("severity");
  const filterActive = !!(severitys?.includes("3") || severitys?.includes("4"));

  // Find the thumbnails for the current image
  // and cluster method.
  const [thumbnails, setThumbnails] = useState<IThumbnail[]>([]);
  const [sortedThumbnails, setSortedThumbnails] = useState<IThumbnail[]>([]);

  useEffect(() => {
    if (image && !clusterHidden) {
      const searchParams = new URLSearchParams();
      searchParams.append("distance", distance);
      searchParams.append("filteractive", filterActive ? "true" : "false");
      if (thumbnailRegexPattern) {
        searchParams.append("thumbnailRegexPattern", thumbnailRegexPattern);
      }
      if (thumbnailPoleItemPlacement) {
        searchParams.append(
          "thumbnailPoleItemPlacement",
          thumbnailPoleItemPlacement.toString()
        );
      }

      const searchString = searchParams.toString();
      authorizedGet<{ images: IThumbnail[] }>(
        `/image/${image?.id}/${clusterMethod}?${searchString}`
      )
        .then(({ images }) => {
          setThumbnails(images);
        })
        .catch(() => {
          console.log("No Cluster found");
        });
    }
  }, [
    image,
    setThumbnails,
    customerId,
    skyqraft_employee,
    clusterMethod,
    distance,
    filterActive,
    thumbnailRegexPattern,
    thumbnailPoleItemPlacement,
    clusterHidden,
  ]);

  useEffect(() => {
    if (thumbnails.length > 0) {
      const sorted = sortImages(thumbnails, ROTATION_OFFSET);
      setSortedThumbnails(sorted);
    }
    if (thumbnails.length === 0 && thumbnailRegexPattern) {
      setSortedThumbnails([]);
    }
  }, [imageSortingMethod, thumbnails]);

  const sliderRef = useRef(null);
  const trackRef = useRef(null);
  const [mouseIsDown, setMouseDown] = useState(false);
  const [oldX, setOldX] = useState(0);
  const [oldY, setOldY] = useState(0);
  const [pressTime, setPressTime] = useState(0);

  function moveTrack(x) {
    const slider = sliderRef.current;
    slider.scrollBy(-x, 0);
  }

  useEffect(() => {
    function updateScroll(e) {
      if (trackRef?.current && (mouseIsDown || e.type === "wheel")) {
        if (sliderRef?.current && trackRef && trackRef.current) {
          moveTrack(e.type === "wheel" ? 0.3 * e.wheelDelta : e.movementX);
        }
      }
    }
    function setMouse(e) {
      if (e.button === 0 && e.type === "mousedown") {
        setMouseDown(true);
        setOldX(e.pageX);
        setOldY(e.pageY);
        setPressTime(new Date().getTime());
      } else {
        setMouseDown(false);
      }
    }

    function handleKeyDown(e) {
      const activeElement = document.activeElement;

      if (
        activeElement instanceof HTMLInputElement ||
        activeElement instanceof HTMLTextAreaElement
      ) {
        return;
      }

      if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
        const currentIndex = sortedThumbnails.findIndex(
          (thumbnail) => thumbnail.id === imageID
        );
        if (currentIndex !== -1) {
          const increment = e.key === "ArrowLeft" ? -1 : 1;
          const newIndex = currentIndex + increment;
          if (newIndex >= 0 && newIndex < sortedThumbnails.length) {
            const targetImageId = sortedThumbnails[newIndex].id;
            showImage(targetImageId);
          }
        }
      }
    }

    if (sliderRef?.current) {
      const slider = sliderRef.current;
      slider.addEventListener("mousemove", updateScroll);
      slider.addEventListener("wheel", updateScroll);
      slider.addEventListener("mousedown", setMouse);
      slider.addEventListener("mouseup", setMouse);
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        slider.removeEventListener("mousemove", updateScroll);
        slider.removeEventListener("wheel", updateScroll);
        slider.removeEventListener("mousedown", setMouse);
        slider.removeEventListener("mouseup", setMouse);
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  });

  const showImage = (id) => {
    navigate(`../${id}${window.location.search}`);
  };

  const moveToImage = (node) => {
    node.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  useEffect(() => {
    const marker = document.querySelector(
      `.clusterPreviewImage .marker.image-${imageID}`
    );
    if (marker?.parentNode) {
      moveToImage(marker.parentNode);
    } else {
      setTimeout(() => {
        const marker = document.querySelector(
          `.clusterPreviewImage .marker.image-${imageID}`
        );
        marker?.parentNode && moveToImage(marker.parentNode);
      }, 1000);
    }
  }, [image]);

  const handleClick = (e, id) => {
    moveToImage(e.target);
    showImage(id);
  };

  return (
    <div
      id="sliderContainer"
      className="sliderContainer"
      ref={sliderRef}
      onClick={(e) => e.preventDefault}
      onMouseLeave={() => setMouseDown(false)}
    >
      <div
        className="sliderTrack"
        ref={trackRef}
        onDragStart={(e) => e.preventDefault()}
        style={{
          transition: mouseIsDown
            ? "height 500ms"
            : "height 500ms, margin-left 500ms",
          height: clusterHidden ? 0 : 125,
        }}
      >
        {!clusterHidden &&
          sortedThumbnails.map((clusterImage, i) => {
            return (
              <div
                style={{ position: "relative" }}
                key={i}
                className={`clusterPreviewImage ${imageID} ${
                  clusterImage?.id === imageID ? "imageOverlay" : ""
                }`}
                onMouseEnter={() => {
                  if (!!image && !!image.pole_id) {
                    HighlightMarkers(clusterImage?.id, null, image.pole_id);
                  }
                }}
                onMouseLeave={() => {
                  if (!!image && !!image.id) {
                    unHighlightMarkers(clusterImage?.id, null, image.pole_id);
                  }
                }}
              >
                <LazyLoadImage
                  onLoad={() => {}}
                  onClick={(e) => {
                    const distance = Math.sqrt(
                      (oldX - e.pageX) * (oldX - e.pageX) +
                        (oldY - e.pageY) * (oldY - e.pageY)
                    );
                    distance < 10 &&
                      new Date().getTime() - pressTime < 1000 &&
                      handleClick(e, clusterImage?.id);
                  }}
                  defaultWidth={DEFAULT_WIDTH}
                  alt="A preview"
                  image={clusterImage?.id}
                  skyqraft_employee={skyqraft_employee}
                  src={clusterImage.src}
                  canLoad={canLoad}
                  objectTypes={objectTypes}
                  bbox={bbox}
                  annotatorObjectColor={annotatorObjectColor}
                />
                <Box position="absolute" top={2} right={18}>
                  <MapMarker
                    id={clusterImage?.id}
                    weight={clusterImage.weight}
                    viewed={0}
                    is_filtered={true}
                    pole_id={null}
                    cluster_id={null}
                    compass_dir={clusterImage.compass_dir}
                    missionId={params.mission}
                    image_type={clusterImage.image_type?.id ?? 0}
                    lat={0}
                    lng={0}
                    isPreview={true}
                    filterActive={isFilterActive(searchParams)}
                    openedMarkers={openedMarkers}
                  />
                </Box>
              </div>
            );
          })}
      </div>
      {(thumbnailRegexPattern || thumbnailPoleItemPlacement) && (
        <Tooltip
          title={
            <Stack spacing={1}>
              <Typography fontSize="small">
                The regex filter for pattern "{thumbnailRegexPattern}" is active
              </Typography>
              <Typography fontSize="small">
                Some images may be missing from the slider
              </Typography>
              <Button
                sx={{ fontSize: "small", color: "white" }}
                variant="outlined"
                onClick={() => {
                  setThumbnailRegexPattern(null);
                  setThumbnailPoleItemPlacement(null);
                }}
              >
                Remove regex filter
              </Button>
            </Stack>
          }
          arrow
        >
          <Info
            style={{
              position: "absolute",
              bottom: 5,
              left: 10,
              color: "#1876D1",
              background: "#FFF",
              borderRadius: "50%",
            }}
          />
        </Tooltip>
      )}
    </div>
  );
}
